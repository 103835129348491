import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
           <div>
  <footer className="main-footer">
    <strong>Copyright © 2020 <a href="#">Admin Panel</a>.</strong>
    All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.0.4
    </div>
  </footer>
</div>

        );
    }
}
