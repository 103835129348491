import React from 'react'
import { API_URL } from '../../config/api';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row, Button, Spinner, Modal, ModalBody,ModalHeader, ModalFooter } from 'reactstrap';
import { useState } from 'react';
import { useEffect } from 'react';


import { toast, ToastContainer } from 'react-toastify';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { FaEdit } from 'react-icons/fa';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const input = {
    fontSize: '13px',
    color: '#333',
    width: 300
}
const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }
function AdminWithdrawalList() {
    const [withdrawalList, setWithdrawalList] = useState()
    const [coinName, setCoinName] = useState('ECNA');
    const [txHash, setTxHash] = useState('')
    const [status, setStatus] = useState(0)
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false);
    const [tokenList, setTokenList] = useState([])
  const [selectedRow, setSelectedRow] = useState()

    useEffect(() => {
        // getData();
        getTokenList();
    }, [])
        
    useEffect(() => {
      if(coinName)
        getData()
    }, [coinName])
    
    const getData = () => {
        axios.get(`${API_URL.GET_ADMIN_WITHDRAWAL}?coinName=${coinName}`).then((res) => {
            if(res.data.message === 'success'){
                setWithdrawalList(res.data.data || [])
            setLoading(false)
           }
        }).catch(e =>{setLoading(false)});
    }
const getTokenList = ()=>{
    axios.get(API_URL.ERC20_TOKEN_LIST)
    .then(res => {setTokenList(res.data.data) })
    .catch(error => {
        console.log("Error: ", error);
    })
}

        const withdrawProfitPopup=()=>{
            setModal(!modal);      
        }
        const updateStatus =()=>{
            setLoading(true)
            const payload={
                id: selectedRow._id,
                status:status,
                txHash:txHash 
            }
            axios.post(`${API_URL.ADMIN_WITHDRAWAL_STATUS_UPDATE}`,payload).then((res) => {
                if(res.data.message === 'success'){
                    toast.success('Status updated')
                setLoading(false)
                setModal(false)
                getData()
               }else{
                setLoading(false)
                toast.error(res.data.error)
               }
            }).catch(e =>{setLoading(false)})
        }
        const onEditClick = (row)=>{
            if(row.status == 'Pending'){
                setSelectedRow(row);
                 withdrawProfitPopup()
            }
        }

        const dateFormatter = (cell, row, enumObject, rowIndex)=> {
            console.log("row value: ", row);
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                    <div class="row">
                        <div class="col-md-12">
                            {new Date(row.timeStamp).toLocaleDateString()}
                            {}
                        </div>
                    </div>
                </div>
            )
        }
       const numberRoundOff = (cell, row, enumObject, rowIndex)=> {
            console.log("row value: ", row);
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                    <div class="row">
                        <div class="col-md-12">
                            {(+(cell || 0)).toFixed(2)}
                            {}
                        </div>
                    </div>
                </div>
            )
        }
        const updateButton = (cell, row, enumObject, rowIndex)=> {
            console.log("row value: ", row);
            return (
                <button disabled={row.status !== "Pending"} className="btn btn-info" onClick={()=> onEditClick(row)}><FaEdit /> </button>
            )
        }
        return (
            <div>
    <Header />
    <Menu />
    <ToastContainer />
    <div className="wrapper">
        <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Admin Profit Withdrawal</h1>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>
            </div>
            <section className="content relative">
                      {loading && <div className='spinner-div'>
                          <Spinner
                              color="warning"
                              type="grow"
                          >
                              Loading...
                          </Spinner>
                      </div>}
                <div className="container-fluid">
                    <div className="col-md-12">
                        <Row>
                            <Col >
                                <Card>
                                    <CardHeader>
                                        <div className="row">
                                           
                                            <div className="col-md-12">
                                                <div className='d-flex justify-content-end'>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            style={input}
                                                            onChange={e => {
                                                               setCoinName(e.target.value)
                                                            }}>
                                                            {tokenList.map((d) => (
                                                                d.active == true && <>
                                                                    <option selected={d.tokenSymbol === coinName} value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                        </div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                  
                                    <BootstrapTable data={withdrawalList} pagination multiColumnSearch
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                          
                                                        <TableHeaderColumn  dataField='coinName' thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' dataFormat={numberRoundOff.bind(this)} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='walletAddress'  thStyle={headerStyle}>Wallet Address</TableHeaderColumn>
                                                        <TableHeaderColumn isKey dataField='dateTime'dataFormat={dateFormatter.bind(this)} thStyle={headerStyle}>DATE </TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status' thStyle={headerStyle}>Status</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='Action'dataFormat={updateButton.bind(this)} thStyle={headerStyle}>Action </TableHeaderColumn>
                                                    </BootstrapTable>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                </div>
                    <Modal isOpen={modal} toggle={withdrawProfitPopup} >
                        <ModalHeader toggle={withdrawProfitPopup}>Modal title</ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="wAdd">Tx Hash</label>
                                    <input type="test" value={txHash} onChange={(e)=> setTxHash(e.target.value)} required className="form-control" id="wAdd" placeholder="Transaction Hash" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="amountId">Status</label>
                                    <select name="status" id="" onChange={(e)=> setStatus(e.target.value)}>
                                        <option value="Pending">Pending</option>
                                        <option value="Complete">Complete</option>
                                        {/* <option value="Canceled">Canceled</option> */}
                                    </select>
                                    {/* <input type="number" required value={amount} onChange={(e)=> setAmount(e.target.value)} className="form-control" id="amountId" placeholder="Enter Amount" /> */}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={updateStatus}>
                                Update
                            </Button>{' '}
                            <Button color="secondary" onClick={withdrawProfitPopup}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
            </section>
        </div>
    </div>
    {/* <Footer /> */}
</div>
  )
}

export default AdminWithdrawalList