import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import Routes from './routes';
import SnackbarProvider from 'react-simple-snackbar';

function App() {
  return (
    <SnackbarProvider>
    <BrowserRouter>
     
      <Routes />
     
    </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
