import React, { Component, useEffect, useState } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    Label,
    Button,
    Form,
} from "reactstrap";
import { BASE_URL } from "../../config/api";
import swal from 'sweetalert';

import { useHistory } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

// const input = {
//     fontSize: '13px',
//     color: '#333'
// }

const UpdateEcannaPrice = () => {
    let history = useHistory();
    const [price, setPrice] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
  const [id, setId] = useState('');


useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    setId(id)
    getPriceById (id)
}, [])



    const resetStates = () => {
        setPrice('')
        setEndDate('')
        setStartDate('')
    }

    const getPriceById = (id) => {
        axios.post(`${BASE_URL}ecannarate/getid`, {
            id
        }).then((res) => {
            console.log(res.data.data)
            setPrice(res?.data?.data?.price)
            setStartDate(res?.data?.data?.startDate)
            setEndDate(res?.data?.data?.endDate)
        })
    }
    const updateRole = () => {
        axios.post(`${BASE_URL}ecannarate/update`, { price,id,startDate,endDate}).then((res) => {
            if (res.data.statusCode === 200) {
                swal("Updated Successfully","", "success");
                history.push('/priceHistory')
            }else{
                swal(res.data.message, {icon: "error",
            });
            }
        })
    }

    return (
        <div>
            <Header />
            <Menu />
            <div className="wrapper">
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 text-dark">Edit Ecanna Price</h1>
                                </div>{/* /.col */}
                                <div className="col-sm-6">
                                    <Button color="primary" style={{ float: 'right' }}
                                        onClick={() => {
                                            history.goBack()
                                        }}>Back</Button>
                                </div>{/* /.col */}
                            </div>{/* /.row */}
                        </div>{/* /.container-fluid */}
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <Form>
                                <Card>
                                    <CardBody style={{ marginBottom: '20px' }}>
                                        <div class="row">
                                            <div className="col-md-4">
                                                <FormGroup>
                                                    <Label for="exampleFile" style={label}>Role Name</Label>
                                                    <Input type="text" value={price} name="price" id="exampleFile"
                                                        onChange={(e) => {
                                                            setPrice(e.target.value )
                                                        }} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup>
                                                    <Label for="startDate" style={label}>Start Date</Label>
                                                    <Input type="date" value={startDate} name="startDate" id="startDate"
                                                        onChange={(e) => {
                                                            setStartDate(e.target.value )
                                                        }} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-4">
                                                <FormGroup>
                                                    <Label for="endDate" style={label}>End Date</Label>
                                                    <Input type="date" value={endDate} name="endDate" id="endDate"
                                                        onChange={(e) => {
                                                            setEndDate(e.target.value )
                                                        }} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <Button
                                                    onClick={()=>updateRole()}
                                                    style={{
                                                        marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                        marginLeft: '15px'
                                                    }}
                                                >Save</Button>
                                                <Button color="secondary"
                                                    onClick={()=>resetStates()}>Reset</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </div>
                    </section>
                </div>
            </div>
            {/* <Footer /> */}
        </div >
    );
}

export default UpdateEcannaPrice;