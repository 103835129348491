import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row, Button} from 'reactstrap';
import { API_URL } from "../../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class FAQs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allNews: []
        }
    }

    componentDidMount() {
        this.getUserList();
    }

    getUserList = async () => {
        console.log(this.state.Token);

        const headers = {
            'Token': this.state.Token,
        }
        await axios.post(API_URL.ANNOUNCEMENT_GET_ALL)
            .then(res => { 
                let userData = res.data.data
                let data = userData.filter((d) => {
                    return d.type === "faqs"
                })
                this.setState({
                    allNews: data
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    deleteUser(row) {
        let user = this.state.allNews.filter(user => user._id === row._id)
        console.log("User::", user[0]._id);
        let userId = user[0]._id
        let status = user[0].activationStatus
        console.log("Status: ", status);
        
        axios.post(API_URL.ANNOUNCEMENT_DELETE, {
            "id": userId,
            "status" : !status
        })
            .then(res => {
                // userData = res.data.data
                // let data = userData.filter(user => user.isDeleted === 0)
                // console.log("User deleted: ", data);
                this.setState({
                    allNews: res.data.data
                })
                swal("Deleted Successfully", "", "success");
            })
            .catch(error => {
                console.log(error)
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-3">
                        <a href="#" className="btn btn-block btn-danger btn-xs"
                            onClick={() => {
                                console.log("Cell value: ", row)
                                this.deleteUser(row)
                            }}>
                            <i className="fa fa-times" />
                        </a>
                    </div>
                    <div class="col-md-3">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            onClick={() => {
                                let data = JSON.stringify(row)
                                console.log("To worriers:", row);
                                localStorage.setItem('faqData', data)
                                this.props.history.push("/editfaqs")
                            }}>
                            <i className="fa fa-edit " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    render() {
         console.log("all users: ", this.state.allNews);
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">All FAQs</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  All FAQs List
                                                    </div>
                                                    <div className="col-md-6">
                                                      <Button color="primary" style={{float: 'right'}}
                                                        onClick={(e) => {
                                                          this.props.history.push('/addfaqs')
                                                        }}>Add FAQ</Button>
                                                    </div>
                                                  </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.allNews} pagination
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn isKey dataField='title' thStyle={headerStyle}>TITLE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status'  thStyle={headerStyle}>STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
