import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import moment from 'moment/moment';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class TradingFeeTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            transferdone: false
        }
    }

    UNSAFE_componentWillMount() {
        this.getTnxHistory();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
    }
    getTnxHistory = async () => {
        await axios.get(API_URL.TRADING_FEE_TRANSACTIONS)
            .then(res => {
                let tnx = res.data.data
              this.setState({transactions:tnx})
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    dateFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        {new Date(row.dateTime).toLocaleDateString()}
                        {}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                   <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i> Trading Fee Transactions
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.transactions} i pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                          {/* <TableHeaderColumn isKey>{Math.random()}</TableHeaderColumn> */}
                                                        <TableHeaderColumn isKey dataField='coinPair' filter={ { type: 'TextFilter', delay: 1000 } } thStyle={headerStyle}>COINPAIR</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='orderMode' thStyle={headerStyle}>LIMIT</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='orderType' thStyle={headerStyle}>ORDER TYPE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tradingFee' thStyle={headerStyle}>TRADING FEE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tradingFeeCurrency' filter={ { type: 'TextFilter', delay: 1000 } } thStyle={headerStyle}>TRADING FEE CURRENCY</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime'dataFormat={this.dateFormatter.bind(this)} thStyle={headerStyle}>DATE </TableHeaderColumn>
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
            </div>
        );
    }
}
