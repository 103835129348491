import React, { Component, useEffect, useState } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    Label,
    Button,
    Form,
} from "reactstrap";
import { BASE_URL } from "../../config/api";
import swal from 'sweetalert';

import { useHistory } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

// const input = {
//     fontSize: '13px',
//     color: '#333'
// }

const UpdateRole = () => {
    let history = useHistory();
    const [roleName, setRoleName] = useState('');
    const [status, setStatus] = useState('');
    const [roleId, setRoleId] = useState('');
    const [role, setRole] = useState();


useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    setRoleId(id)
    getRoleById (id)
}, [])
// useEffect(() => {
//  if (roleId) {
//     getRoleById ()
//  }
// }, [roleId])


    const resetStates = () => {
        setRoleName('')
    }

    // const updateRoleValues = (e,key) => {
    //     setRoleName(e.target.value )
    // }
    const getRoleById = (id) => {
        axios.post(`${BASE_URL}admin/role/get`, {
            id
        }).then((res) => {
            console.log(res.data.data)
            setRole(res.data.data);
            setRoleName(res?.data?.data?.roleName)
            setStatus(res?.data?.data?.isDeleted)
        })
    }
    const updateRole = () => {
        axios.post(`${BASE_URL}admin/role/update`, {isDeleted:status, roleName,id:role._id}).then((res) => {
            if (res.data.statusCode === 200) {
                swal("Updated Successfully","", "success");
            }else{
                swal(res.data.message, {icon: "error",
            });
            }
        })
    }
useEffect(() => {
   console.log(role)
}, [role]);
    return (
        <div>
            <Header />
            <Menu />
            <div className="wrapper">
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 text-dark">Edit Role</h1>
                                </div>{/* /.col */}
                                <div className="col-sm-6">
                                    <Button color="primary" style={{ float: 'right' }}
                                        onClick={() => {
                                            history.goBack()
                                        }}>Back</Button>
                                </div>{/* /.col */}
                            </div>{/* /.row */}
                        </div>{/* /.container-fluid */}
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <Form>
                                <Card>
                                    <CardBody style={{ marginBottom: '20px' }}>
                                        <div class="row">
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="exampleFile" style={label}>Role Name</Label>
                                                    <Input type="text" value={roleName} name="roleName" id="exampleFile"
                                                        onChange={(e) => {
                                                            setRoleName(e.target.value )
                                                        }} />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup fullWidth>
                                                    <FormControl fullWidth variant='outlined' size='small'>
                                                        <Label id="demo-simple-select-label">Status</Label>
                                                        <Select type="text" value={status} name="status" id="status"
                                                            onChange={(e) => {
                                                                setStatus(e.target.value)
                                                            }} >
                                                            <MenuItem value={false}>Active</MenuItem>
                                                            <MenuItem value={true}>Inactive</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <Button
                                                    onClick={()=>updateRole()}
                                                    style={{
                                                        marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                        marginLeft: '15px'
                                                    }}
                                                >Save</Button>
                                                <Button color="secondary"
                                                    onClick={()=>resetStates()}>Reset</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </div>
                    </section>
                </div>
            </div>
            {/* <Footer /> */}
        </div >
    );
}

export default UpdateRole;