import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class DirectOrdersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: []
        }
    }

    UNSAFE_componentWillMount() {
        this.getDirectOrders();
    }

    getDirectOrders = async () => {
        console.log(this.state.Token);

        const headers = {
            'Token': this.state.Token,
        }
        const body={
            status:0,
            userId:''
        }
        await axios.post(API_URL.DIRECT_ORDERS)
            .then(res => {
                let userData = res.data.data
                let userWithdrawData = res.data.data
                
                this.setState({
                    orderList: userWithdrawData
                })
                console.log(userWithdrawData)
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }
    changeStatus = async (data, status) => {
        let body = {};
        if (status === 'reject') {
            body = {
                id: data._id,
                orderId: data._id,
            }
            await axios.post(API_URL.CANCLE_DIRECT_ORDERS,body)
                .then(res => {
                    if (res.data.statusCode === 200) {
                        this.getDirectOrders();
                    }
                })
        }else{
            body = {
                id: data._id,
                orderId:  data._id,
            }
            await axios.post(API_URL.APPROVE_DIRECT_ORDERS,body)
                .then(res => {
                    if (res.data.statusCode === 200) {
                        this.getDirectOrders();
                    }
                })
        }
    }
    groupButtonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        <button className="btn btn-block btn-primary btn-xs" disabled={row.status === 4 || row.status === 5}
                            onClick={() => {
                                this.changeStatus(row,'approve')
                            }}
                            >
                            Approve 
                        </button>
                        <button className="btn btn-block btn-danger btn-xs" disabled={row.status === 4 || row.status === 5}
                            onClick={() => {
                                this.changeStatus(row,'reject')
                            }}
                            >
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        <button className={row.status === 1 ? 'btn btn-block btn-secondary btn-xs':row.status === 4 ? 'btn btn-block btn-success btn-xs': 'btn btn-block btn-danger btn-xs'}
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            {row.status=== 1 ? 'Pending': row.status=== 4 ? 'Approved':'Rejected'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                <a href="javascript:void"
                            onClick={() => {
                                // let data = row.userId
                                // console.log("Withdraw", row.userId);
                                // localStorage.setItem('emailData', data)
                                // this.props.history.push("/edituser")
                            }}>
                                <p style={{color: '#0069D9'}}>{row.userId}</p>
                        </a>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Direct Order List</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i> Direct Order List
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.orderList} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn
                                                            isKey
                                                            dataField='button'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        {/* <TableHeaderColumn isKey dataField='email' width={'20%'} thStyle={headerStyle} tdStyle={{color: '#3459ed'}}>USER EMAIL</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN NAME</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='orderMode' width={'10%'} thStyle={headerStyle}>ORDER TYPE</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='orderType'
                                                            thStyle={headerStyle}
                                                            width={'10%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >SIDE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width={'8%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'13%'}
                                                        >STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime' width={'14%'} thStyle={headerStyle}>DATE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='' dataFormat={this.groupButtonFormatter.bind(this)} width={'14%'} thStyle={headerStyle}>Action</TableHeaderColumn>
                                                       
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
