import React, { useState, useEffect } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useLocation } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { API_URL } from "../../config/api";
import { axiosInstance } from "../../utils/interceptor";

const headerStyle = {
  backgroundColor: "#ECF0F2",
  color: "#6F7B8A",
};

function TDSDeductionTransactions() {
  const [transactionList, setTransactionList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      getOrderList();
  }, []);

  const getOrderList = () => {
    setLoading(true);
    axiosInstance
      .get(API_URL.GET_MARGIN_TRADE_TDS_TX)
      .then((res) => {
        setTransactionList(res.data.data || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);
      });
  };
  return (
    <div>
      <Header />
      <Menu />
      <ToastContainer />
      <div className="wrapper">
        <div
          className="content-wrapper"
          style={{ marginTop: "0px", backgroundColor: "#E5E9EC" }}
        >
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">TDS Transactions</h1>
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
          </div>
          <section className="content relative">
            <div className="container-fluid">
              <div className="col-md-12">
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <div className="row"></div>
                      </CardHeader>
                      <CardBody>
                        <div>
                          {loading && (
                            <div className="spinner-div">
                              <Spinner color="warning" type="grow">
                                Loading...
                              </Spinner>
                            </div>
                          )}
                          <div style={{ overflow: "auto" }}>
                            <BootstrapTable
                              data={transactionList}
                              pagination
                              search
                              tableStyle={{
                                fontSize: 12,
                                textAlign: "center",
                                minWidth: 1200,
                              }}
                            >
                                 <TableHeaderColumn
                                dataField="orderId"
                                thStyle={headerStyle}
                                width={"10%"}
                              >
                                Order Id
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField="coinName"
                                thStyle={headerStyle}
                                width={"10%"}
                              >
                                Coin
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField="entryPrice"
                                width={"8%"}
                                thStyle={headerStyle}
                              >
                                Price($)
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField="orderAmount"
                                width={"8%"}
                                thStyle={headerStyle}
                              >
                               Order Amount
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField="type"
                                thStyle={headerStyle}
                                width={"8%"}
                              >
                                Type
                              </TableHeaderColumn>

                              <TableHeaderColumn
                                dataField="button"
                                width={"15%"}
                                dataFormat={(cell, row) => (
                                  <>
                                   ${row.tdsAmount}{" "}
                                    
                                  </>
                                )}
                                thStyle={headerStyle}
                              >
                                TDS Amount
                              </TableHeaderColumn>
                              <TableHeaderColumn
                                dataField="button"
                                dataFormat={(cell, row) => (
                                  <>{new Date(row.createdAt).toDateString()}</>
                                )}
                                width={"15%"}
                                thStyle={headerStyle}
                                isKey
                              >
                                Date
                              </TableHeaderColumn>

                              <TableHeaderColumn
                                dataField="button"
                                width={"15%"}
                                dataFormat={(cell, row) => (
                                  <>{row.orderStatus.toUpperCase()}</>
                                )}
                                thStyle={headerStyle}
                              >
                               Order Status
                              </TableHeaderColumn>
                            </BootstrapTable>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default TDSDeductionTransactions;
