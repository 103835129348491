import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import { API_URL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';


import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class WithdrawTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withdrawFund: [],
            withdrawFundFull:[],
            loading: false
        }
    }

    UNSAFE_componentWillMount() {
        this.getWithdrawalHistory();
    }

    componentDidMount() {
        console.log('this.props.userId', this.props.userId)
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
    }
    getWithdrawalHistory = async () => {
        console.log(this.state.Token);
        this.setState({loading: true})
        await axios.post(API_URL.WITHDRAW_HISTORY, {userId: this.props.userId})
            .then(res => {
                let userWithdrawData = res.data.data
                this.setState({
                    withdrawFund: userWithdrawData,
                    withdrawFundFull: userWithdrawData,
                    loading: false
                })
            })
            .catch(error => {
                console.log("Error: ", error);
                this.setState({loading: false})
            })
    }
    searchInTable = (text)=>{
        this.setState({withdrawFund: this.state.withdrawFundFull.filter(obj => JSON.stringify(obj).match(new RegExp(text, "i")))})
    }
    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        if (row.status == "Pending") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-warning btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Pending
                        </a>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Completed") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Completed
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Rejected
                            </a>
                        </div>
                    </div>
                </div>
            )
        }
    }

    cellEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                <div class="col-md-12">
                    <a href="#" className="btn btn-block btn-primary btn-xs"
                        onClick={() => {
                            let data = JSON.stringify(row)
                            console.log(row);
                            localStorage.setItem('withdrawEdit', data)
                            this.props.history.push("/editwithdraw")
                        }}>
                        <i className="fa fa-edit " />
                    </a>
                </div>
            </div>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                <div class="col-md-12">
                    {/* <a href=""
                        onClick={() => {
                            let data = row.userId
                            console.log("Withdraw", row.userId);
                            localStorage.setItem('emailData', data)
                            this.props.history.push("/edituser")
                        }}> */}
                    <a>
                        <p style={{ color: '#0069D9' }}>{row.userName}<br></br>({row.emailId})</p>
                    </a>
                </div>
            </div>
        )
    }


    details(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR" || row.coinName === "ECNA"){
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p><strong>A/C Holder Name:</strong> {row.acHolderName}</p>
                            <p><strong>Bank Name:</strong> {row.bankName}</p>
                            <p><strong>Account Type:</strong> {row.accountType}</p>
                            <p><strong>Account Number:</strong> {row.accountNumber}</p>
                            <p><strong>IFSC:</strong> {row.ifsc}</p>
                            <p><strong>Remark:</strong> {row.remark}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p>{row.walletAddress}</p>
                    </div>
                </div>
            )
        }
    }
    csvdetails(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR" || row.coinName === "ECNA"){
            return (
                
                            `A/C Holder Name: ${row.acHolderName || 'N/A'}
                            Bank Name:${row.bankName || 'N/A'}
                            Account Type:${row.accountType || 'N/A'}
                            Account Number:${row.accountNumber || 'N/A'}
                            IFSC:${row.ifsc || 'N/A'}
                            Remark:${row.remark || 'N/A'}`
                  
            )
        } else {
            return (
               
                            `${row.walletAddress}`
                    
            )
        }
    }
    
    amountFormatter(cell, row, enumObject, rowIndex) {
        return (
            <div>
    {Number(row.amount) - row.fees}
            </div>
        )
    }

    render() {
        return (
            <div>
                       {this.state.loading && (
              <div className="spinner-div">
                <Spinner color="warning" type="grow">
                  Loading...
                </Spinner>
              </div>
            )}
               <div className='d-flex justify-content-end pb-2'>
                <input style={{width: 300}} type="text" className="form-control" onChange={(eve)=>{this.searchInTable(eve.target.value)}} placeholder="Search" />
                </div>
                <div style={{overflow: 'auto'}}>
                <BootstrapTable data={this.state.withdrawFund} pagination  csvFileName='withdrawal_list.csv'
                    tableStyle={{ fontSize: 12, textAlign: 'center', minWidth:1200 }}>
                    <TableHeaderColumn
                        isKey
                        dataField='userId'
                        dataFormat={this.emailEdit.bind(this)}
                        thStyle={headerStyle}
                        width={'20%'}
                    >USER ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN NAME</TableHeaderColumn>

                    <TableHeaderColumn
                        dataField='button'
                        dataFormat={this.details.bind(this)}
                        csvFormat={(cell, row)=> this.csvdetails(cell, row) }
                        thStyle={headerStyle}
                        width={'27%'}
                            csvHeader={'WALLET ADDRESS / DETAILS'}
                        // tdStyle={ { whiteSpace: 'normal' } }
                    >WALLET ADDRESS / DETAILS</TableHeaderColumn>
                    <TableHeaderColumn dataField='text' dataFormat={this.amountFormatter.bind(this)} csvFormat={(cell, row)=> Number(row.amount) - row.fees } csvHeader={'Amount'} width={'8%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                    <TableHeaderColumn dataField='fees' width={'8%'} thStyle={headerStyle}>FEE</TableHeaderColumn>
                    <TableHeaderColumn dataField='inrValue' csvFormat={(cell)=> cell ? cell :''} width={'10%'} thStyle={headerStyle}>INR VALUE</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='button'
                        dataFormat={this.buttonFormatter.bind(this)}
                        csvFormat={(cell,row)=> row.status}
                        csvHeader={'STATUS'}
                        thStyle={headerStyle}
                        width={'13%'}
                    >STATUS</TableHeaderColumn>
                    <TableHeaderColumn dataField='date' width={'14%'} thStyle={headerStyle}>DATE</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='button'
                        dataFormat={this.cellEdit.bind(this)}
                        csvFormat={(cell, row)=> ""}
                        csvHeader={'NA'}
                        thStyle={headerStyle}
                        width={'8%'}
                    >ACTION</TableHeaderColumn>
                </BootstrapTable>
                </div>
            </div>
        );
    }
}
