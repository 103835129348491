import React, { Component } from 'react';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';

import {axiosInstance} from './../utils/interceptor';
const axios = axiosInstance;
// var userName = '';
// var email = '';

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class ActivityLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            load: 0
        }
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
    }

    async UNSAFE_componentWillMount() {
        await axios.get(API_URL.ACTIVITY_LOG)
            .then(res => {
                let userData = res.data.data
                let binddata = []
                Promise.all( 
                    userData.map(async (d,xid) => {
                    let internalbinddata = []
                    let user = {
                        "id": d.userId
                    }
                    return await axios.post(API_URL.USER_BY_ID, user)
                        .then(res1 => {
                            // console.log("User Infor: ", res1.data);
                            // let values = res1.data.data
                            //  var userName = values.userName
                            //  var  email = values.emailId
                            var time = moment((d.timestamp)).format("DD-MM-YYYY h:mm:ss a");
                            // d.email = email
                            // internalbinddata["email"] = email
                            // internalbinddata["userName"] = userName
                            internalbinddata["id"] = d.userId
                            internalbinddata["date"] = time
                            internalbinddata["ipaddress"] = d.ipaddress
                            internalbinddata["browser"] = d.browser
                            binddata[xid] = internalbinddata
                        })
                }))
                .then(data => {
                    console.log("inside promise",userData)
                    console.log("binddata",data)
                this.setState({
                    activity: binddata,
                    load: 1,
                })
            })
                // this.props.history.push('/activitylog')
            })
            .catch(error => {
                console.log("Error: ", error);
            })
        // this.getActivityLog();
    }

    // getActivityLog = async () => {

    //     await axios.get(API_URL.ACTIVITY_LOG)
    //         .then(res => {
    //             let userData = res.data.data
    //             userData.map(async (d) => {
    //                 let user = {
    //                     "id": d.userId
    //                 }
    //                 await axios.post(API_URL.USER_BY_ID, user)
    //                     .then(res => {
    //                         console.log("User Infor: ", res.data.data);
    //                         let values = res.data.data
    //                         var userName = values.userName
    //                         var  email = values.emailId
    //                         d.email = email
    //                         d["email"] = email
    //                         d["userName"] = userName
    //                     })
    //             })
    //             console.log("Activity log data:", userData)
    //             this.setState({
    //                 activity: userData
    //             })
    //         })
    //         .catch(error => {
    //             console.log("Error: ", error);
    //         })
    // }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                        <a href=""
                            onClick={() => {
                                let data = row.id
                                console.log("Withdraw", row.id);
                                localStorage.setItem('emailData', data)
                                this.props.history.push("/listuseredit")
                            }}>
                                <p style={{color: '#0069D9'}}>{row.id}</p>
                        </a>
                </div>
            </div>
        )
    }

    render() {
        console.log("Activity log data:", this.state.activity)
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Activity Log</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  Activity Log
                                                </CardHeader>
                                                <CardBody>
                                                    {this.state.load === 1 &&
                                                    <BootstrapTable data={this.state.activity} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        {/* <TableHeaderColumn dataField='userName' width={'15%'} thStyle={headerStyle} isKey>USER NAME</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='email' width={'20%'} thStyle={headerStyle}>EMAIL</TableHeaderColumn> */}
                                                         <TableHeaderColumn
                                                            isKey
                                                            dataField='button'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='ipaddress'  thStyle={headerStyle}>IP ADDRESS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='browser' thStyle={headerStyle}>BROWSER</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date'  thStyle={headerStyle}>DATE</TableHeaderColumn>
                                                    </BootstrapTable>
    }
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
