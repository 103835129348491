import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardTitle,
    FormGroup,
    Label,
    Button,
    Form
} from "reactstrap";
import { API_URL } from "../../config/api";
import swal from 'sweetalert';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

var checkedBTC, checkedETH, checkedUSDT, checkedINR;
var tokenData = {};

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class EditToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: []
        }
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }

        let editData = localStorage.getItem('tokenEditData', '')
    
            editData = JSON.parse(editData)
            console.log("On edit page: ", editData);
           
                if (editData.BTC == 1) {
                    checkedBTC = true
                } else { checkedBTC = false }
                if (editData.ETH == 1) {
                    checkedETH = true
                } else { checkedETH = false }
                if (editData.USDT == 1) {
                    checkedUSDT = true
                } else { checkedUSDT = false }
                if (editData.INR == 1) {
                    checkedINR = true
                } else { checkedINR = false }
 
      

        this.setState({
            tokenData: editData,
            id: editData._id,
            tokenName: editData.tokenName,
            tokenSymbol: editData.tokenSymbol,
            contractAddress: editData.contractAddress,
            dfees: editData.dfees,
            wfees: editData.wfees,
            wLimit: editData.wLimit || 0,
            minwid: editData.minwid,
            tfees: editData.tfees,
            active: editData.active,
            dActive: editData.dActive,
            wActive: editData.wActive,
            tActive: editData.tActive,
            minAmtBTC: editData.minAmtBTC,
            minAmtETH: editData.minAmtETH,
            minAmtUSDT: editData.minAmtUSDT,
            minAmtINR: editData.minTotalINR,
            minTotalBTC:editData.minTotalBTC,
            minTotalETH: editData.minTotalETH,
            minTotalINR: editData.minTotalINR ,
            minTotalUSDT: editData.minTotalUSDT,
            amtDecimalBTC : editData.amtDecimalBTC,
            amtDecimalETH: editData.amtDecimalETH,
            amtDecimalINR : editData.amtDecimalINR,
            amtDecimalUSDT : editData.amtDecimalUSDT,
            priceDecimalBTC : editData.priceDecimalBTC,
            priceDecimalETH: editData.priceDecimalETH,
            priceDecimalINR: editData.priceDecimalINR,
            priceDecimalUSDT :editData.priceDecimalUSDT,
            signupBonus: editData.signupBonus,
            kycBonus :  editData.kycBonus,
            refBonus: editData.refBonus,
            exchange: editData.exchange,
            marginLimit : editData.marginLimit,
            borrowLimit : editData.borrowLimit,
            interestPerDay : editData.interestPerDay,
            BTC: editData.BTC,
            ETH: editData.ETH,
            USDT: editData.USDT,
            INR: editData.INR,
        })
    }

    updateStatus = async () => {
        if(this.state.tokenSymbol === 'BALLX'){
            tokenData = {
                id: this.state.id,
                tokenName: this.state.tokenName,
                tokenSymbol: this.state.tokenSymbol,
                tokenLogo: this.state.tokenLogo,
                dfees: this.state.dfees,
                wfees: this.state.wfees,
                wLimit: this.state.wLimit,
                minwid: this.state.minwid,
                tfees: this.state.tfees,
                BTC: this.state.BTC?1:0,
                ETH: this.state.ETH?1:0,
                USDT: this.state.USDT?1:0,
                INR: this.state.INR?1:0,
                exchange: this.state.exchange,
                active: this.state.active,
                contractAddress: this.state.contractAddress,
                dActive: this.state.dActive,
                wActive: this.state.wActive,
                tActive: this.state.tActive,
                minAmtBTC: this.state.minAmtBTC,
                minTotalBTC : this.state.minTotalBTC,
                minTotalETH : this.state.minTotalETH,
                minAmtETH: this.state.minAmtETH,
                minAmtUSDT: this.state.minAmtUSDT,
                minTotalINR: this.state.minAmtINR,
                minTotalUSDT: this.state.minTotalUSDT,
                amtDecimalBTC : this.state.amtDecimalBTC,
                amtDecimalETH: this.state.amtDecimalETH,
                amtDecimalINR : this.state.amtDecimalINR,
                amtDecimalUSDT : this.state.amtDecimalUSDT,
                priceDecimalBTC : this.state.priceDecimalBTC,
                priceDecimalETH: this.state.priceDecimalETH,
                priceDecimalINR: this.state.priceDecimalINR,
                priceDecimalUSDT :this.state.priceDecimalUSDT,
                signupBonus: Number(this.state.signupBonus),
                kycBonus :  Number(this.state.kycBonus),
                refBonus: Number(this.state.refBonus),
                marginLimit : Number(this.state.marginLimit),
                borrowLimit : Number(this.state.borrowLimit),
                interestPerDay : Number(this.state.interestPerDay)
            }
        } else{
            tokenData = {
            id: this.state.id,
            tokenName: this.state.tokenName,
            tokenSymbol: this.state.tokenSymbol,
            tokenLogo: this.state.tokenLogo,
            dfees: this.state.dfees,
            wfees: this.state.wfees,
            wLimit: this.state.wLimit,
            minwid: this.state.minwid,
            tfees: this.state.tfees,
            BTC: this.state.BTC? 1 : 0,
            ETH: this.state.ETH? 1 : 0,
            USDT: this.state.USDT? 1 : 0,
            INR: this.state.INR? 1 : 0,
            exchange: this.state.exchange,
            active: this.state.active,
            contractAddress: this.state.contractAddress,
            dActive: this.state.dActive,
            wActive: this.state.wActive,
            tActive: this.state.tActive,
            minTotalBTC : this.state.minTotalBTC,
            minTotalETH : this.state.minTotalETH,
            minAmtBTC: this.state.minAmtBTC,
            minAmtETH: this.state.minAmtETH,
            minAmtUSDT: this.state.minAmtUSDT,
            minTotalINR: this.state.minAmtINR,
            minTotalUSDT: this.state.minTotalUSDT,
            amtDecimalBTC : this.state.amtDecimalBTC,
            amtDecimalETH: this.state.amtDecimalETH,
            amtDecimalINR : this.state.amtDecimalINR,
            amtDecimalUSDT : this.state.amtDecimalUSDT,
            priceDecimalBTC : this.state.priceDecimalBTC,
            priceDecimalETH: this.state.priceDecimalETH,
            priceDecimalINR: this.state.priceDecimalINR,
            priceDecimalUSDT :this.state.priceDecimalUSDT,
            marginLimit : Number(this.state.marginLimit),
            borrowLimit : Number(this.state.borrowLimit),
            interestPerDay : Number(this.state.interestPerDay)
            }
        }
        console.log("update data: ", tokenData);
            await axios.post(API_URL.TOKEN_UPDATE, tokenData)
                .then(res => {
                    console.log(res);
                    if (res.data.message == "updatedSuccessuly") {
                        swal("Added Successfully", "", "success")
                    } else {
                        swal("Error!", "", "error")
                    }
                })
                .catch(error => {
                    console.log(error);

                })
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Edit Coin </h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right' }}>
                                            <Button
                                                color="primary"
                                                onClick={() => this.props.history.push('/erctokenlist')}
                                                Style="float:right; margin: 20"> View All Coins
                                            </Button>
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        {/* <div class="row">
                                            <div class="col-md-6">
                                                <CardTitle style={{ margin: 20 }}> Edit ERC20 Token</CardTitle>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div> */}
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Token Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.tokenName}
                                                            onChange={e => {
                                                                this.setState({
                                                                    tokenName: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Contract Address</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.contractAddress}
                                                            onChange={e => {
                                                                this.setState({
                                                                    contractAddress: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <div class="row">
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Deposite Fees in %</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.dfees}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            dfees: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="marginLimit" style={label}>Margin Limit</Label>
                                                        <Input
                                                            type="text"
                                                            name="marginLimit"
                                                            id="marginLimit"
                                                            style={input}
                                                            value={this.state.marginLimit}
                                                            onChange={e => {
                                                                this.setState({
                                                                    marginLimit: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Withdraw Fees in %</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.wfees}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            wfees: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="borrowLimit" style={label}>Borrow Limit</Label>
                                                        <Input
                                                            type="text"
                                                            name="borrowLimit"
                                                            id="borrowLimit"
                                                            style={input}
                                                            value={this.state.borrowLimit}
                                                            onChange={e => {
                                                                this.setState({
                                                                    borrowLimit: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                        </div>
                                                    </div>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>STATUS</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.active}
                                                            style={input}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    active: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                    {this.state.tokenSymbol === 'BALLX' && 
                                                       <FormGroup>
                                                       <Label for="examplePassword" style={label}>SIGN UP BONUS</Label>
                                                       <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.signupBonus}
                                                            onChange={e => {
                                                                this.setState({
                                                                    signupBonus: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                   </FormGroup>}
                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>TOKEN SYMBOL</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.tokenSymbol}
                                                            onChange={e => {
                                                                this.setState({
                                                                    tokenSymbol: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>TOKEN LOGO</Label>
                                                        <div class="form-control">
                                                            <img src={"/coinicon/" + this.state.tokenData.tokenSymbol + ".png"} width="20px" height="20px" /> -
                                <a href="erc20_token_add.php?edit&amp;id=5eb66f1aca35ec3e707fe066&amp;removeimage" style={{ color: 'red' }}>[x] Remove Image</a>
                                                        </div>
                                                        {/* <Input
                              type="number"
                              name="amount"
                              id="examplePassword"
                              style={input}
                              value={`<img src={"/coinicon/"+this.state.tokenData.tokenSymbol+".png" } width="20px" height="20px"/>`}
                              ><img src={"/coinicon/"+this.state.tokenData.tokenSymbol+".png" } width="20px" height="20px"/></Input> */}
                                                    </FormGroup>
                                                    <div class="row">
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Minimum Withdraw in Token</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.minwid}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            minwid: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="interestPerDay" style={label}>Interest Per Day</Label>
                                                        <Input
                                                            type="text"
                                                            name="interestPerDay"
                                                            id="interestPerDay"
                                                            style={input}
                                                            value={this.state.interestPerDay}
                                                            onChange={e => {
                                                                this.setState({
                                                                    interestPerDay: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                  
                                                        </div>
                                                      
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Treding Fees in %</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.tfees}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            tfees: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="interestPerDay" style={label}>Withdrawal Limit</Label>
                                                        <Input
                                                            type="text"
                                                            name="wLimit"
                                                            id="wLimit"
                                                            style={input}
                                                            value={this.state.wLimit}
                                                            onChange={e => {
                                                                this.setState({
                                                                    wLimit: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                        </div>
                                                          {/* <div className="col-md-4">
                                                     
                                                        </div> */}
                                                    </div>
                                                    <Label for="examplePassword" style={label}>Select Pairs</Label>
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <FormGroup >
                                                                <input type="checkbox" defaultChecked={checkedBTC}
                                                                    onChange={e => {
                                                                            this.setState({
                                                                                BTC: e.target.checked,
                                                                            })
                                                                    }} />{' '}
                                                                BTC
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <FormGroup>
                                                                <input type="checkbox" defaultChecked={checkedETH}
                                                                    onChange={ev => {
                                                                        this.setState({
                                                                            ETH: ev.target.checked,
                                                                        })
                                                                    }} />{' '}
                                                                ETH
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <FormGroup>
                                                                <input type="checkbox" defaultChecked={checkedUSDT}
                                                                    onChange={eve => {
                                                                        this.setState({
                                                                            USDT: eve.target.checked,
                                                                        })
                                                                    }} />{' '}
                                                                USDT
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <FormGroup>
                                                                <input type="checkbox" defaultChecked={checkedINR}
                                                                    onChange={event => {
                                                                        this.setState({
                                                                            INR: event.target.checked,
                                                                        })
                                                                    }} />{' '}
                                                                INR
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    {this.state.tokenSymbol === 'BALLX' && 
                                                       <FormGroup>
                                                       <Label for="examplePassword" style={label}>KYC BONUS</Label>
                                                       <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.kycBonus}
                                                            onChange={e => {
                                                                this.setState({
                                                                    kycBonus: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                   </FormGroup>}

                                                    {/* <Label for="examplePassword" style={label}>Status</Label>
                                                    <FormGroup>
                                                        <input type="checkbox"
                                                            defaultChecked={this.state.active}
                                                            onChange={e => {
                                                                this.setState({
                                                                    active: e.target.value,
                                                                })
                                                              }}/>{' '}
                                                                Active
                                                        </FormGroup> */}
                                                </div>
                                                <div className='col-md-4'>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Deposite</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.dActive}
                                                            style={input}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    dActive: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                    <FormGroup >
                                                                <Label for="examplePassword" style={label}>Exchange</Label>
                                                                <select
                                                                    className="form-control editor edit-text"
                                                                    value={this.state.exchange}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        var string = e.target.value
                                                                        console.log(string)
                                                                        // var val = (string === "true");
                                                                        this.setState({
                                                                            exchange: string
                                                                        })
                                                                    }}>
                                                                    <option value="">Select</option>
                                                                    <option value="Binance">Binance</option>
                                                                    <option value="E-Canna">E-Canna</option>
                                                                    
                                                                </select>
                                                            </FormGroup>
                                                   
                                                </div>
                                                <div className='col-md-4'>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Withdraw</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.wActive}
                                                            style={input}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    wActive: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                    {this.state.tokenSymbol === 'BALLX' && 
                                                       <FormGroup>
                                                       <Label for="examplePassword" style={label}>REF BONUS</Label>
                                                       <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.refBonus}
                                                            onChange={e => {
                                                                this.setState({
                                                                    refBonus: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                   </FormGroup>}
                                                </div>
                                                <div className='col-md-4'>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Trade</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.tActive}
                                                            style={input}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    tActive: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                </div>
                                                
                                                <Button
                                                    onClick={this.updateStatus}
                                                    style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>UPDATE</Button>
                                                <Button color="secondary"
                                                    onClick={this.resetAll}>RESET</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
