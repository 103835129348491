import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL,BASE_URL } from "../../../config/api";
import swal from 'sweetalert';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}
var filepath= ''

export default class EditNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editData: '',
            stakeAmt: '',
            apiMode: 'new',
            seotitle: '',
            URL: '',
            seoDescription: '',
            status: '',
            body: '',
            filePath: '',
            title: '',
            feePerText: '',
            feePercent: '',
            usdtPrice: '',
            validDays: '',
            validText: '',
            validTimestap: ''
        }
    }

    componentDidMount() {
        let editData = localStorage.getItem('feeseditdata', '')
        
        console.log("On edit admin: ", editData);

        if(editData == '') {
            this.setState({
                title: '',
                feePerText: '',
                feePercent: '',
                usdtPrice: '',
                validDays: '',
                validText: '',
                validTimestap: '',
                stakeAmt: '',
                apiMode: 'new'
            })
        } else {
            editData = JSON.parse(editData)
            this.setState({editData: editData})
            this.setState({
                title: editData.title,
                feePerText: editData.feePerText,
                feePercent: editData.feePercent,
                usdtPrice: editData.usdtPrice,
                validDays: editData.validDays,
                validText: editData.validText,
                validTimestap: editData.validTimestap,
                stakeAmt: editData.stakeAmt,
                apiMode: 'update'
            })
        }
        // this.setState({
        //     title: editData.title,
        //     seotitle: editData.seoTitle,
        //     URL: editData.url,
        //     seoDescription: editData.seoDescription,
        //     status: editData.status,
        //     body: editData.story,
        //     filePath: editData.filePath,
        //     imageurl: editData.imageurl,
        //     id: editData._id
        // })

        /* //     feePerText: "Discounted Trading Fees of 0.20%"
// feePercent: "0.1"
// stakeAmt: "4000"
// title: "General FESS"
// usdtPrice: "0"
// validDays: "30"
// validText: "Valid till available FESS Token balance is"
// validTimestap: "20" */

        
        

    }



    saveNews = async () => {
        let newsData
        if(this.state.apiMode == "update") {
             newsData = {
                "id": this.state.editData._id,
                "title": this.state.title,
                "feePerText": this.state.feePerText,
                "feePercent": this.state.feePercent,
                "usdtPrice": this.state.usdtPrice,
                "validDays": this.state.validDays,
                "validText": this.state.validText,
                "stakeAmt": this.state.stakeAmt,
                "validTimestap": Number(this.state.validDays) * 86400
            }
        } else { 
             newsData = {
                "title": this.state.title,
                "feePerText": this.state.feePerText,
                "feePercent": this.state.feePercent,
                "usdtPrice": this.state.usdtPrice,
                "validDays": this.state.validDays,
                "validText": this.state.validText,
                "stakeAmt": this.state.stakeAmt,
                "validTimestap": Number(this.state.validDays) * 86400
            }
        } 
        

        if(this.state.apiMode == "update") {
            if(this.state.title == "General FESS") {
                let newsDataERC = {
                    "minstake": this.state.stakeAmt,
                }
                axios.post(BASE_URL+"updatefeespackStakeERC", newsDataERC)
                .then(res => {
                    console.log("response:", res);
                    if (res.data.message === "success") {
                        swal("Updated Successfully", "", "success");
                    } else {
                        swal("Unable to add", "", "error");
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
            

            axios.post(BASE_URL+"updatefeespack", newsData)
            .then(res => {
                console.log("response:", res);
                if (res.data.message === "success") {
                    swal("Updated Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log(error)
            })
        } else {
            axios.post(BASE_URL+"addfeespack", newsData)
            .then(res => {
                console.log("response:", res);
                if (res.data.message === "success") {
                    swal("Added Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log(error)
            })
        }
        
    }

    resetStates = () => {
        this.setState({
            title: '',
            SEOtitle: '',
            URL: '',
            SEOdescription: '',
            status: '',
            story: ''
        })
    }




    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                    {this.state.editData == '' ?
                                        <h1 className="m-0 text-dark">Add New Fees Subscription</h1>
                                    :
                                        <h1 className="m-0 text-dark">Update Fees Subscription</h1>
                                    }
                                    </div>{/* /.col */}
                                    <div className="col-sm-6">
                                        <Button color="primary" style={{float: 'right'}}
                                            onClick={() => {
                                                this.props.history.push('/feessubscription')
                                            }}>Back</Button>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody style={{ marginBottom: '20px' }}>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Title</Label>
                                                        {this.state.title == "General FESS" ?
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.title}
                                                            placeholder=""
                                                           disabled="true"
                                                           readOnly="true"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    title: e.target.value
                                                                })
                                                            }} />
                                                        :
                                                        <>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.title}
                                                            placeholder=""
                                                           
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    title: e.target.value
                                                                })
                                                            }} />
                                                        </> 
                                                        }
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Fee Percent Text</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.feePerText}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    feePerText: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Validity Text</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.validText}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    validText: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Fees Percent (ex. 0.2)</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.feePercent}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    feePercent: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price in USDT</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.usdtPrice}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    usdtPrice: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Validity in Days</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.validDays}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    validDays: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>
                                                {this.state.title == "General FESS" &&
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Stake Amount</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.stakeAmt}
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    stakeAmt: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>
                                                }


                                                <div className="col-md-12">
                                                    <Button
                                                        onClick={this.saveNews}
                                                        style={{
                                                            marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                            marginLeft: '15px'
                                                        }}>Save</Button>
                                                    {/* <Button color="secondary"
                                                        onClick={this.resetStates}>Reset</Button> */}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
