import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row, Button, Label } from 'reactstrap';
import { API_URL,BASE_URL } from "../../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { CSVLink, CSVDownload } from "react-csv";
import Pagination from "react-js-pagination";

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

var usersEmails = [];
var page = 1;

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUsers: [],
            allEmails: [],
            tabledata: [],
            tabledatausers: [],
            checkloader: 0,
            tableload: false,
            emailsDownload: '',
            count: 0,
            btnDisable: true
        }
    }

    async componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }

        await axios.post(BASE_URL+'userInformation/get/allnew', {
           
        }) 
        .then(async resusers => {

                    await axios.post(BASE_URL+'userInformation/get/allrbc', {
                    
                    }) 
                    .then(res => {
                        this.setState({
                            tabledata: res.data.data,
                            tabledatausers: resusers.data.data,
                            tableload: true
                        })
                        // console.log("resuer",res.data.data)
                        // console.log("resuer",resusers.data.data)
                    })
        })
    }


    render() {
        //  console.log("all users: ", this.state.allUsers);
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">RBC Users List</h1>
                                        <input id="checkdatatableblank" type="hidden" value={this.state.checkloader}></input>
                                    </div>{/* /.col */}
                                    <div className="col-sm-6">
                                        {/* <CSVDownload data={this.state.allUsers} target="_blank" />; */}
                                        {/* <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol> */}
                                    </div>
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">

                                

                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  All Users
                                                </CardHeader>
                                                {/* <div  className="col-md-6"></div> */}
                                                <div className="col-md-12 ">
                                                    
                                                </div>
                                                {this.state.tableload == false &&
              <div class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>
              }
                                                <CardBody>

                                                {this.state.tableload == true &&
                                <>
                                <table id="example1" class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>Wallet Address</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody id="maintableid">
                    {this.state.tabledata.map((row, i)=> (


<>
<tr> 
{/* activationStatus: 1
country: "India"
createdOn: "2021-02-15"
emailId: "akshayrambhade12@gmail.com"
forgotPassKey: "xzgn4fv1pts7usbnk8fi"
ipAddress: null
isDeleted: 0
kyc: 0
password: "$2a$08$tChx.KFIc1TXMHyAD8GlGeUVSgcKLbEid11xAqmbFPjihf.Cq/nIq"
phoneNumber: "918055822699"
refBy: null
refCode: "41CGW"
secret: "HQYTGN2PIMRT4PSG"
two_factor: 1
uniqueId: "3047318"
userID: 2
userName: "akshay rambhade"
verid: "uAZpujWsWkgTYBpwPwLQ"
_id: "602a5de91d66fe17b429f4dc" */}

                    <td>{i+1}</td>
                    {this.state.tabledatausers.map((rowuser, i)=> (
                        <> {rowuser._id === row.userId && <td>{rowuser.userName}</td>} 
                        {rowuser._id === row.userId && <td>{rowuser.emailId}</td>} </>
                    ))}
                    <td>{row.walletAddress}</td>
                    <td><a href={"/rbclistedit?id="+row._id} className="btn btn-block btn-primary btn-xs" target="_blank">
                            Add RBC Deposit
                    </a></td>
                  </tr>
</>
                    
                    ))}
                  
                  
                  </tbody>
                  <tfoot>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>Wallet Address</th>
                    <th>Action</th>
                  </tr>
                  </tfoot>
                </table>
                </>
    }

                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
