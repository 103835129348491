import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Input, Button } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import TradingFeeTransaction from './tradingFeeTransactions';
import AdminBuyTransaction from './adminBuyTransactions';
import WithdrawFeeTransaction from './withdrawFeeTransactions';
import Menu from '../../Component/Menu';
import Header from '../../Component/Header';

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class AdminTransactions extends Component {
    constructor(props){
        super(props);
        this.state = {
         
        }
    }
  

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Transactions List</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                            
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader style={{ fontSize: '22px' }}>
                                         
                                                </CardHeader>
                                                <CardBody >
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Trading Fee </Tab>
                                                        <Tab>Withdrawal Fee</Tab>
                                                        <Tab>Admin Buy</Tab>
                                                    </TabList>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                      <TradingFeeTransaction />
                                                    </TabPanel>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                    <WithdrawFeeTransaction />
                                                    </TabPanel>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                    <AdminBuyTransaction />
                                                    </TabPanel>
                                                </Tabs>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
