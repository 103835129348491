import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
// import Footer from '../../Component/Footer';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL, BASE_URL } from "../../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class ActiveDeposietFund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depositFunds: []
        }
    }

    UNSAFE_componentWillMount() {
        this.getDepositeHistory();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
    }
    getDepositeHistory = async () => {
        console.log(this.state.Token);

        const headers = {
            'Token': this.state.Token,
        }
        await axios.get(BASE_URL+"getfeeshistory")
            .then(res => {
                this.setState({
                    depositFunds: ""
                })
                let userData = res.data.data
                // userData.map((d) => {
                //     let userid = {
                //         "id" : d.userId
                //     }
                //      axios.post(API_URL.USER_BY_ID,
                //         userid,
                //         {
                //           headers: {
                //             'Content-Type': 'application/json',
                //           }
                //         })
                //         .then(res => {
                //           let emailOfUser = res.data.data.emailId
                //           d["emailId"] = emailOfUser
                //         })
                //         .catch(error => {
                //           console.log("Error: ", error);
                //         })
                // })
                console.log("all users: ", res.data.data);
                let userDepositeData = res.data.data
                userDepositeData.map((d) => {
                    data.push(d)

                })
                console.log(data)
                this.setState({
                    depositFunds: data
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    // buttonFormatter(cell, row, enumObject, rowIndex) {
    //     console.log("row value: ", row.status);
    //     return (
    //         <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
    //             <div class="row">
    //                 <div class="col-md-12">
    //                     {row.status == "Completed" &&
    //                     <a href="#" className="btn btn-block btn-success btn-xs">{row.status}</a>
    //                     }
    //                     {row.status == "Pending" &&
    //                     <a href="#" className="btn btn-block btn-primary btn-xs">{row.status}</a>
    //                     }
    //                     {row.status == "Rejected" &&
    //                     <a href="#" className="btn btn-block btn-danger btn-xs">{row.status}</a>
    //                     }
    //                     {/* <a href="#" className="btn btn-block btn-primary btn-xs"
    //                         // onClick={() => {
    //                         //     console.log("Cell value: ", row)
    //                         //     this.deleteUser(row)
    //                         // }}
    //                         >
    //                         {row.status}
    //                     </a> */}
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    cellEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '20%' }}>
                <div class="col-md-12">
                    <a href="#" className="btn btn-primary btn-xs"
                        onClick={() => {
                            let data = JSON.stringify(row)
                            console.log("cellEdit",row);
                            localStorage.setItem('inrDepositEdit', data)
                            this.props.history.push("/feessubscriptionedit")
                        }}>
                        <i className="fa fa-edit " />
                    </a>

                    <a href="#" style={{marginLeft:"10px"}} className="btn btn-danger btn-xs"
                        onClick={() => {
                            let data = JSON.stringify(row)
                            console.log("cellEdit",row);
                            localStorage.setItem('inrDepositEdit', data)
                            this.props.history.push("/inreditdeposit")
                        }}>
                        <i className="fa fa-times " />
                    </a>
                </div>
            </div>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                <a href=""
                            onClick={() => {
                                let data = row.userId
                                console.log("Withdraw", row.userId);
                                localStorage.setItem('emailData', data)
                                this.props.history.push("/edituser")
                            }}>
                                <p style={{color: '#0069D9'}}>{row.userId}</p>
                        </a>
                </div>
            </div>
        )
    }

    // details(cell, row, enumObject, rowIndex) {
    //     if(row.coinName === "INR"){
    //         return (
    //             <div style={{ flex: 1, justifyContent: 'left', textAlign: 'left', alignItems: 'left', width: '100%' }}>
    //                 <div class="col-md-12">
    //                         <p><strong>Bank Name:</strong> {row.bankname}</p>
    //                         <p><strong>Account Number:</strong> {row.acno}</p>
    //                         <p><strong>IFSC:</strong> {row.ifsc}</p>
    //                         <p><strong>Reference ID:</strong> {row.refid}</p>
    //                         <p><strong>Remark:</strong> {row.remark}</p>
    //                 </div>
    //             </div>
    //         )
    //     } else {
    //         return (
    //             <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
    //                 <div class="col-md-12">
    //                         <p>{row.walletAddress}</p>
    //                 </div>
    //             </div>
    //         )
    //     }
    // }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Fees Subscription History</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  Fees Subscription History
                                                    </div>
                                                    

                                                  
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.depositFunds} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn
                                                            isKey
                                                            dataField='button'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='title' width={'20%'} thStyle={headerStyle}>TITLE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='feePerText' width={'20%'} thStyle={headerStyle}>FEE PERCENT TEXT</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='feePercent' width={'15%'} thStyle={headerStyle}>FEE PERCENTAGE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>PRICE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='validDays' width={'15%'} thStyle={headerStyle}>VALIDITY DAYS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date' width={'20%'} thStyle={headerStyle}>DATE SUBSCRIBED</TableHeaderColumn>
                                                      


                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
