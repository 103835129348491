//  export const BASE_URL = "http://localhost:5000/";


export const BASE_URL = "https://adminapi.ecannacoin.com/";      // Production

// export const BASE_URL = "https://testadminapi.ecannacoin.com/"  //  Testing

export const imageURL ="https://kyc.ecannacoin.com/";

       

export const API_URL = {
   USER_INFORMATION : BASE_URL+'userInformation/get/all',
   UPDATE_USER : BASE_URL+'userInformation/update',
   BLOCK_USER : BASE_URL+'userInformation/blockAccount',
   USER_BY_ID : BASE_URL+'userInformation/get',
   USER_BY_ID_ADMIN : BASE_URL+'userInformationForAdmin/get',
   USER_COUNT : BASE_URL+'userInformation/count',
   DEPOSITE_GET : BASE_URL+'depositehistory/get/all',
   WITHDRAW_GET : BASE_URL+'withdrawal/get/all',
   USER_EMAIL : BASE_URL+'userInformation/get/email',
   WITHDRAW_STATUS_UPDATE :  BASE_URL+'withdrawal/update/status',

   ERC20_TOKEN_LIST : BASE_URL+'ercToken/get/all1',
   ERC20_TOKEN_LIST_ALL : BASE_URL+'ercToken/get/all',
   ADD_TOKEN : BASE_URL+'ercToken/save',
   TOKEN_UPDATE : BASE_URL+'ercToken/update',
   DELETE_TOKEN : BASE_URL+'ercToken/delete',
   TOKEN_COUNT :BASE_URL+'ercToken/count',
   USER_BALANCE : BASE_URL+'userBalance/get',
   GET_ALL_BALANCE: BASE_URL+ 'userBalance/get/all',
   GET_ADMIN_WALLET_BALANCE : BASE_URL+ 'userBalance/get',
   DEPOSITE_HISTORY : BASE_URL+'depositehistory/get/user',
   WITHDRAW_HISTORY : BASE_URL+'withdrawal/get/user',
   TRADE_HISTORY : BASE_URL + 'tradehistory/get/user',
   GET_ALL_USER_TRADE: BASE_URL + 'get/user-tradehistory',
   
   ADMIN_ADD: BASE_URL+'admin/add',
   ADMIN_LOGIN: BASE_URL+'admin/login',
   ADMIN_LIST : BASE_URL+'admin/get/all',
   ADMIN_GET_ID: BASE_URL+'admin/get',
   UPDATE_ADMIN: BASE_URL+'admin/update',
   DELETE_ADMIN: BASE_URL+'admin/delete',
   PASSWORD_CHANGE: BASE_URL+'changePassword/admin',
   INRBANK: BASE_URL+'addbankac',
   GETINRBANK: BASE_URL+'getbankac',

   ACTIVITY_LOG: BASE_URL+'loginhistory/get',

   ANNOUNCEMENT_ADD: BASE_URL+'announcement/add',
   ANNOUNCEMENT_GET_ALL: BASE_URL+'announcement/getall',
   ANNOUNCEMENT_DELETE: BASE_URL+'announcement/delete',
   UPDATE_ANNOUNCE: BASE_URL+'announcement/update',

   FILE_UPLOAD: BASE_URL+ 'kyc/upload',

   KYC_LIST : BASE_URL+'kyc/get/allbystatus',
   KYC_UPDATE: BASE_URL+'kyc/update',
   KYC_COUNT: BASE_URL+'kyc/count',
   
   ORDER_GET: BASE_URL+'order/get/all',

   TRNSFER_DEPOSIT_FUNDS : BASE_URL + 'depositehistory/updatesendstatus',
   // TRADE_ORDERS : BASE_URL + 'order/getTradeOrders',    
   TRADE_ORDERS : BASE_URL + 'order/get/all',
   DIRECT_ORDERS : BASE_URL + 'order/get/alldirectOrder',
   CANCLE_DIRECT_ORDERS : BASE_URL + 'order/update/canceldirectOrder',
   APPROVE_DIRECT_ORDERS : BASE_URL + 'order/update/settledirectOrder',
   CANCLE_TRADE_ORDERS : BASE_URL + 'order/update/status',
   CANCLE_ALL_TRADE_ORDERS : BASE_URL + 'order/update/cancelAllOrder',
   GET_PENDING_INR_ORDERS : BASE_URL + 'order/get/pendingOrders',
   COMPLETE_PENDING_INR_ORDERS : BASE_URL + 'order/update/inrOrder',

   ADD_COIN_PAIR : BASE_URL + "coinPair/add",
   UPDATE_COIN_PAIR : BASE_URL + "coinPair/update",
   GETALL_COIN_PAIRS : BASE_URL + "coinPair/get/all",

   ADD_MARGIN_COIN_PAIR : BASE_URL + "marginCoinPair/add",
   UPDATE_MARGIN_COIN_PAIR : BASE_URL + "marginCoinPair/update",
   GETALL_MARGIN_COIN_PAIRS : BASE_URL + "marginCoinPair/get/all",

  ECNA_TRANSACTIONS : BASE_URL + "ecanna/getAllEcannaTransaction",
  TRADING_FEE_TRANSACTIONS : BASE_URL + "tradingFeeTx/get/all",
  WITHDRAW_FEE_TRANSACTIONS : BASE_URL + "withdrawFeeTx/get/all",
  ADMIN_BUY_TRANSACTIONS : BASE_URL + "adminBuyTx/get/all",

 GET_BINANCE_SETTING : BASE_URL + "binance/setting/get",
 UPDATE_BINANCE_SETTING : BASE_URL + "binance/setting/update",
 UPDATE_BINANCE_COINPAIR_STATUS : BASE_URL + "ercToken/binance-status/update",

 ADD_BLOGS_NEWS : BASE_URL + "add/blog_news",
 GETALL_BLOGS_NEWS : BASE_URL + "getAll/blog_news",
 UPDATE_BLOGS_NEWS : BASE_URL  +  "update/blog_news",
 DELETE_BLOGS_NEWS : BASE_URL  +  "delete/blog_news",

 ADMIN_PROFIT_TRACKER: BASE_URL+'adminWallet/details',
 WITHDRAW_PROFIT: BASE_URL+'profit/withdraw',
 GET_ADMIN_WITHDRAWAL: BASE_URL+'get/adminWithdrawal',
 ADMIN_WITHDRAWAL_STATUS_UPDATE: BASE_URL+'profit/withdraw/updateStatus',

 ADMIN_LOGIN_HISTORY:BASE_URL+'admin/loginhistory',

 P2P_ORDERS:BASE_URL+'p2p/get/allOrders',
 P2P_COMPLETED_ORDERS:BASE_URL+'p2p/get/completedOrder',
 P2P_DISPUTED_ORDERS:BASE_URL+'p2p/get/disputedOrder',
 P2P_ORDERS_STATUS_ENUM:BASE_URL+'get/status-enum',
 UPDATE_DISPUTED_ORDER:BASE_URL+'update/disputed-order-status',

 GET_MARGIN_ORDERS:BASE_URL+'marginOrder/get/all',
 GET_MARGIN_TRADE_FEE_TX:BASE_URL+'marginOrder/getfeeTx',
 GET_MARGIN_TRADE_TDS_TX:BASE_URL+'marginOrder/getTdsTx',

};

