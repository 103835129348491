import React, { Component, useState } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    Label,
    Button,
    Form,
} from "reactstrap";
import { BASE_URL } from "../../config/api";
import swal from 'sweetalert';

import { useHistory } from 'react-router-dom';
import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

// const input = {
//     fontSize: '13px',
//     color: '#333'
// }

const AddRole = () => {
    let history = useHistory();
    const [roleName, setRoleName] = useState('')
    const resetStates = () => {
        setRoleName('')
    }

    const setNewRoleName = (e) => {
        setRoleName(e.target.value )
    }
    const AddNewRole = () => {

        axios.post(`${BASE_URL}admin/role/add`, {
            roleName: roleName
        }).then(() => {
            swal("Added Successfully", "", "success");
        })
    }

    return (
        <div>
            <Header />
            <Menu />
            <div className="wrapper">
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 text-dark">Add Role</h1>
                                </div>{/* /.col */}
                                <div className="col-sm-6">
                                    <Button color="primary" style={{ float: 'right' }}
                                        onClick={() => {
                                            history.goBack()
                                        }}>Back</Button>
                                </div>{/* /.col */}
                            </div>{/* /.row */}
                        </div>{/* /.container-fluid */}
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <Form>
                                <Card>
                                    <CardBody style={{ marginBottom: '20px' }}>
                                        <div class="row">
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="exampleFile" style={label}>Role Name</Label>
                                                    <Input type="text" name="roleName" id="exampleFile"
                                                        onChange={(e) => {
                                                            setNewRoleName(e)
                                                        }} />
                                                </FormGroup>
                                            </div>

                                            <div className="col-md-12">
                                                <Button
                                                    onClick={()=>AddNewRole()}
                                                    style={{
                                                        marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                        marginLeft: '15px'
                                                    }}
                                                >Save</Button>
                                                <Button color="secondary"
                                                    onClick={()=>resetStates()}>Reset</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </div>
                    </section>
                </div>
            </div>
            {/* <Footer /> */}
        </div >
    );
}

export default AddRole;