import React, { Component } from 'react';
import { API_URL } from "../config/api";
import swal from 'sweetalert';
import { detect } from "detect-browser";
import {withRouter} from 'react-router-dom'
// import {publicIpv4} from 'public-ip'

import {axiosInstance} from './../utils/interceptor';
const axios = axiosInstance;
const browser = detect();
// const publicIp = require('public-ip');
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            msgData: 0,
            error : '',
            submitted: false
        }
    }
    componentDidMount(){
        const user = localStorage.getItem('loginData');
        const token = user ? JSON.parse(user)?.token : ''
        if (token) {
            this.props.history.push('/dashboard')
        }
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        if (this.state.submitted) {
            return;
        }
       try {
        this.setState({submitted: true})
        const ipData = await axios.get('https://api.db-ip.com/v2/free/self');
        let loginData = {
            "email" : this.state.email,
            "password" : this.state.password,
            "browser":browser.os+" ("+browser.name+" "+browser.version+")",
            "ipAddress": ipData?.data?.ipAddress || ""
        }
        await axios.post(API_URL.ADMIN_LOGIN,
            loginData,
            {
              headers: {
                'Content-Type': 'application/json',
              }
            })
            .then(res => {
              console.log("Login Admin : ", res.data)
              if(res.data.message === "loginSuccessful"){
                localStorage.setItem("adminLogin", 1)
                  this.setState({
                      msgData : 1,
                      error : "Login Successfully!"
                  })
                  let data = JSON.stringify(res.data.userData)
                  localStorage.setItem("loginData", data)
                //   this.updateLoginHistory(res.data.userData._id).then(()=>{
                    //   })
                          this.props.history.push('/dashboard')
              }else if(res.data.message === "emailidAndPasswordDoesNotMatch"){
                this.setState({
                    msgData : 2,
                    error : "Email Does Not Exists!"
                })
                this.setState({submitted: false})
                
            }else{
                this.setState({
                    msgData : 2,
                    error : "Email Does Not Exists!"
                })
                this.setState({submitted: false})
            }
            })
            .catch(error => {
              console.log("Error: ", error);
              this.setState({submitted: false})
            })
       } catch (error) {
        console.log(error)
       }
    }
  async updateLoginHistory(userid){
        // const ipAddress = await publicIp.v4()
        const ipData = await axios.get("https://api.ipify.org/?format=json");
        await axios.post(API_URL.ADMIN_LOGIN_HISTORY,{
            browser:browser.os+" ("+browser.name+" "+browser.version+")",
            userId:userid,
            ipAddress: ipData.data.ip
  })
    }
    render() {
        return (
            <div style={{flex:1, justifyContent: 'center', alignContent: 'center', verticalAlign:'center'}}>
               
                <div className="login-box" style={{margin: 'auto', width: '470px',marginTop: '50px'}}>
                    <div className="login-logo">
                        <a href="">Admin Panel</a>
                    </div>
                    {/* /.login-logo */}
                    <div className="card" style={{width: '460px'}}>
                        <div className="card-body login-card-body">
                            {/* <p className="login-box-msg">Sign in to start your session</p> */}
                            <form action="../../index3.html" method="post">
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Email" 
                                        onChange={(e) => {
                                            this.setState({
                                                email : e.target.value
                                            })
                                        }}/>
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="Password" 
                                     onChange={(e) => {
                                        this.setState({
                                            password : e.target.value
                                        })
                                    }}/>
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>
                                    {/* /.col */}
                                    
                                    {/* /.col */}
                                </div>
                                <div className="col-12">
                                        <button disabled={this.state.submitted} type="submit" className="btn btn-primary btn-block" 
                                        onClick={(e) => this.handleSubmit(e)}>Sign In</button>
                                </div>
                                {this.state.msgData === 1 ?
                            <div class="col-xs-12">
                              <div id="ResponseMessage" class="response-message alert alert-success"
                                style={{ marginTop: 25, height: '30px', paddingTop: 2, paddingBottom: 0 }}>
                                Login Successfully!</div>
                            </div> : (this.state.msgData === 2 ?
                              <div class="col-xs-12">
                                <div id="ResponseMessage" class="response-message alert alert-danger"
                                  style={{ marginTop: 25, height: '30px', paddingTop: 2, paddingBottom: 0 }}>Could not login please check your password</div>
                              </div> :
                              <div className="col-xs-12">
                                <div id="ResponseMessage" className="col-xs-12 alert alert-warning response-message" style={{ display: 'none' }} />
                              </div>)
                          }
                            </form>
                            {/* <div className="social-auth-links text-center mb-3">
                                <p>- OR -</p>
                                <a href="#" className="btn btn-block btn-primary">
                                    <i className="fab fa-facebook mr-2" /> Sign in using Facebook
          </a>
                                <a href="#" className="btn btn-block btn-danger">
                                    <i className="fab fa-google-plus mr-2" /> Sign in using Google+
          </a>
                            </div>
                            <p className="mb-1">
                                <a href="forgot-password.html">I forgot my password</a>
                            </p>
                            <p className="mb-0">
                                <a href="register.html" className="text-center">Register a new membership</a>
                            </p> */}
                        </div>
                        {/* /.login-card-body */}
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(Login);