import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class BinanceOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withdrawFund: [],
            allChecked:false,
            rangeValue: [moment(new Date).subtract(1,'month').toDate(), new Date()]

        }
    }

    UNSAFE_componentWillMount() {
        this.getTradeOrders();
    }

    getTradeOrders = async () => {
        console.log(this.state.Token);

        const headers = {
            'Token': this.state.Token,
        }
        await axios.post(API_URL.TRADE_ORDERS)
            .then(res => {
                let userData = res.data.data
                let userWithdrawData = res.data.data
                
                this.setState({
                    withdrawFund: userWithdrawData
                })
                console.log(userWithdrawData)
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }
    handleAllCheck = (isChecked)=>{

    }
    cancelSingleOrder = (data) => {
        console.log(data)
        const postData = {
            id: data._id,
            coinName: data.coinName,
            pair: data.pair,
            status: 0,

        }
    }

     onPick =(e)=>{
        this.setState({rangeValue: e})
    }
    cancelButtonFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12 d-flex align-items-center">
                        <a href="javascript:void" className="btn btn-block btn-warning btn-xs"
                            onClick={() => {
                                // console.log("Cell value: ", row)
                                this.cancelSingleOrder(row)
                            }}
                            >
                            Cancel
                        </a>
                        {/* <input type='checkbox' onChange={(e)=>{}}/> */}
                    </div>
                </div>
            </div>
        )
    }

    cellEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div >
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            onClick={() => {
                                // let data = row._id
                                // console.log(row);
                                // localStorage.setItem('admineditId', data)
                                // this.props.history.push("/editUsers")
                            }}>
                            <i className="fa fa-edit " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                <a href="javascript:void"
                            onClick={() => {
                                // let data = row.userId
                                // console.log("Withdraw", row.userId);
                                // localStorage.setItem('emailData', data)
                                // this.props.history.push("/edituser")
                            }}>
                                <p style={{color: '#0069D9'}}>{row.userId}</p>
                        </a>
                </div>
            </div>
        )
    }

    details(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR"){
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p><strong>Bank Name:</strong> {row.bankName}</p>
                            <p><strong>Account Type:</strong> {row.accountType}</p>
                            <p><strong>Account Number:</strong> {row.accountNumber}</p>
                            <p><strong>IFSC:</strong> {row.ifsc}</p>
                            <p><strong>Remark:</strong> {row.remark}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p>{row.walletAddress}</p>
                    </div>
                </div>
            )
        }
    }
    selectRow = {
        mode: 'checkbox' // or checkbox
      };
    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Binance Order List</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i> Binance Order List
                                                </CardHeader>
                                                <CardBody>
                                                    <div className='text-right mb-4 w-50 filter-div'>
                                                        <div>
                                                            <select className='form-control coin-select'>
                                                                <option>BTC</option>
                                                                <option>USDT</option>
                                                                <option>ETH</option>
                                                            </select>
                                                        </div>
                                                        <DateRangePicker
                                                            onChange={this.onPick}
                                                            value={this.state.rangeValue}
                                                            className="range form-control ml-3"
                                                            maxDate={new Date()}
                                                        />
                                                    </div>
                                                    <BootstrapTable data={this.state.withdrawFund} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }} >
                                                        <TableHeaderColumn
                                                            isKey
                                                            dataField='button'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        {/* <TableHeaderColumn isKey dataField='email' width={'20%'} thStyle={headerStyle} tdStyle={{color: '#3459ed'}}>USER EMAIL</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='date' width={'10%'} thStyle={headerStyle}>Date Time</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='account' width={'10%'} thStyle={headerStyle}>Account No</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='orderType'
                                                            thStyle={headerStyle}
                                                            width={'10%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >SIDE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width={'8%'} thStyle={headerStyle}>Email-Id</TableHeaderColumn>
                                                        {/* <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'13%'}
                                                        >STATUS</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='exchangeStatus' width={'14%'} thStyle={headerStyle}>Exchange Status</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime' width={'14%'} thStyle={headerStyle}>Price</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime' width={'14%'} thStyle={headerStyle}>Quantity</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime' width={'14%'} thStyle={headerStyle}>Side</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime' width={'14%'} thStyle={headerStyle}>Status</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime' width={'14%'} thStyle={headerStyle}>Symbol</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='dateTime' width={'14%'} thStyle={headerStyle}>User Name</TableHeaderColumn>
                                                                                                               
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
