import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import bcrypt from 'bcryptjs';
import { imageURL } from '../../../config/api'
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL, BASE_URL } from "../../../config/api";
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import './userTable.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { axiosInstance } from './../../../utils/interceptor';
import { Switch } from '@material-ui/core';
const axios = axiosInstance;

var id;
var editData = [];
var ercCoinData = [];

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}
const STATUS_ENUM = {
    0: "Pending",
    1: 'Completed',
    2: 'Cancelled',
    5: 'Cancelled',

}
export default class EditToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: [],
            userBalace: [],
            kycData: [],
            tokenValue: [],
            depositeHistory: [],
            withdrawalHistory: [],
            tradeHistory: [],
            activationStatus: '',
            country: "",
            btnName: 'Lock',
            coinName: '',
            amountToUse: 0,
            lockedDate: '',
            two_factor: 0,
            coinName: '',
            pair: '',
            ercAllTokenData: [],
            userBankDetails:{
                bankname:  "",
                panno:  "",
                acno:  "",
                ifsc:  "",
                passbook:  "",
            }
        }
    }

    async componentDidMount() {
        let userData = localStorage.getItem('userData');
        userData = JSON.parse(userData)
        console.log("User Data: ", userData)
        let emailData = localStorage.getItem('emailData', '')
        console.log("Email: ", emailData);
        id = userData._id
       
        await axios.post(API_URL.USER_BY_ID, {
            "id": id
        })
            .then(res => {
                console.log("user by id:", res.data.data);
                this.setState({
                    tokenData: res.data.data,
                    activationStatus: res.data.data.activationStatus,
                    country: res.data.data.country,
                    two_factor: res.data.data.two_factor
                })
                var password = bcrypt.decodeBase64("$2a$08$JNy8IrnisCtgphxxV1TVB.SbyIi7ASuSgGhL56fEBm4Kp073N6GT2");

                console.log(password);

            })
            .catch(error => {
                console.log("Error: ", error);
            })

        await axios.post(BASE_URL + "kyc/getid", {
            "id": id
        })
            .then(res => {
                console.log("kycdata", res.data.data);
                this.setState({
                    kycData: res.data.data
                })
            })
            .catch(error => {
                console.log("kycdata", error);
            })


        await axios.get(API_URL.ERC20_TOKEN_LIST)
            .then(res => {
                console.log("ERC20 token:", res.data.data);
                let data = res.data.data
                this.setState({
                    ercAllTokenData: res.data.data,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

        // this.tableData()
        this.getUserBalance()
        this.getDepositeHistory()
        this.getWithdrawalHistory()
        this.getBankDetails(id)
    }

    getBankDetails = async(userId)=>{
    axios
    .get(BASE_URL + "get/bankdetails/"+ userId)
    .then((res) => {
        this.setState({userBankDetails:{
            bankname: res.data.data?.bankname || "",
            panno: res.data.data?.panno|| "",
            acno: res.data.data?.acno|| "",
            ifsc: res.data.data?.ifsc|| "",
            passbook: res.data.data?.passbook|| "",
        }})
    })
    .catch((err) => {
      console.log(err);
    //   toast.error(err?.message || "faild to get bank details.");
    });
    }
    updateUserBalance = async () => {
        var obj = {}
        // obj['userId'] = id;
        await Promise.all(this.state.tokenValue.map((d) => {
            obj[d.tokenSymbol] = Number(Number(this.state[d.tokenSymbol + "_balance"]).toFixed(8))
        }))
        await axios.post(BASE_URL + "userbalance/updatebalance", {
            "userId": id,
            "data": obj
        })
            .then(res => {
                if (res.data.message === "success") {
                    swal("Updated Successfully", "", "success");
                    this.getUserBalance();
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    lockUserBalance = async () => {
        var lockBalance;
        var remainbalance;
        var obj = {}
        console.log("sachin here");
        console.log(this.state.lockedDate, this.state.coinName);

        if (this.state.coinBalance[this.state.coinName] >= this.state.amountToUse) {
            console.log("sachin here");
            //wether lock amount is less than or equal to balance
            if (this.state.coinBalance.hasOwnProperty([this.state.coinName + "_lock"])) {    //wether the FESS_lock field already exists
                if (this.state.coinBalance[this.state.coinName + "_lock"] >= 0) {             // FESS_lock balance is greater than zero 
                    remainbalance = this.state.coinBalance[this.state.coinName] - this.state.amountToUse
                    lockBalance = this.state.coinBalance[this.state.coinName + "_lock"] + Number(Number(this.state.amountToUse).toFixed(6))
                    // console.log("Remain Balance: ", remainbalance)
                    // console.log("Lock Balance: ", lockBalance)
                } else {
                    remainbalance = this.state.coinBalance[this.state.coinName] - this.state.amountToUse
                    lockBalance = this.state.amountToUse
                    // console.log("Remain Balance1: ", remainbalance)
                    // console.log("Lock Balance1: ", lockBalance)
                }
                let balanceUpdate = {
                    "id": id,
                    "amountLocked": Number(Number(lockBalance).toFixed(6)),
                    "remaining": Number(Number(remainbalance).toFixed(6)),
                    "type": "locked",
                    "coinName": this.state.coinName,
                    "lockedDate": this.state.lockedDate
                }
                console.log("Balance Data: ", balanceUpdate)
                await axios.post(BASE_URL + "balance/lock", balanceUpdate)
                    .then(res => {
                        if (res.data.message === "updatedSuccessully") {
                            swal("Updated Successfully", "", "success");
                            this.getUserBalance();
                        } else {
                            swal("Unable to add", "", "error");
                        }
                    })
                    .catch(error => {
                        console.log("Error: ", error);
                    })
            } else {
                remainbalance = this.state.coinBalance[this.state.coinName] - this.state.amountToUse
                lockBalance = this.state.amountToUse

                let balanceUpdate = {
                    "id": id,
                    "amountLocked": Number(Number(lockBalance).toFixed(6)),
                    "remaining": Number(Number(remainbalance).toFixed(6)),
                    "type": "locked",
                    "coinName": this.state.coinName
                }
                console.log("Balance Data: ", balanceUpdate)
                await axios.post(BASE_URL + "balance/lock", balanceUpdate)
                    .then(res => {
                        if (res.data.message === "updatedSuccessully") {
                            swal("Updated Successfully", "", "success");
                            this.getUserBalance();
                        } else {
                            swal("Unable to add", "", "error");
                        }
                    })
                    .catch(error => {
                        console.log("Error: ", error);
                    })
            }
        } else {
            swal("Not enough balance!", "", "error");
        }
    }

    unlockUserBalance = async () => {
        var unlockBalance;
        var remainbalance;

        if (this.state.coinBalance.hasOwnProperty([this.state.coinName + "_lock"])) {
            if (this.state.coinBalance[this.state.coinName + "_lock"] >= 0 && this.state.coinBalance[this.state.coinName + "_lock"] >= this.state.amountToUse) {
                remainbalance = Number(Number(this.state.coinBalance[this.state.coinName]).toFixed(6)) + Number(Number(this.state.amountToUse).toFixed(6))
                unlockBalance = this.state.coinBalance[this.state.coinName + "_lock"] - this.state.amountToUse

                console.log("Remain Balance1: ", remainbalance)
                console.log("Unlock Balance1: ", unlockBalance)

                let balanceUpdate = {
                    "id": id,
                    "amountLocked": Number(Number(unlockBalance).toFixed(6)),
                    "remaining": Number(Number(remainbalance).toFixed(6)),
                    "type": "unlocked",
                    "coinName": this.state.coinName,
                    "lockedDate": this.state.lockedDate

                }
                console.log("Balance Data: ", balanceUpdate)
                await axios.post(BASE_URL + "balance/lock", balanceUpdate)
                    .then(res => {
                        if (res.data.message === "updatedSuccessully") {
                            swal("Updated Successfully", "", "success");
                            this.getUserBalance();
                        } else {
                            swal("Unable to add", "", "error");
                        }
                    })
                    .catch(error => {
                        console.log("Error: ", error);
                    })

            } else {
                swal("Not sufficient locked balance!", "", "error");
            }
        } else {
            swal("No locked balance!", "", "error");
        }
    }


    getUserBalance = async () => {
        await axios.post(API_URL.USER_BALANCE, {
            "userId": id
        })
            .then(response => {
                // console.log("Edit user Balance:", res.data.data);
                ercCoinData = response.data.data

                this.setState({ coinBalance: response.data.data })
                axios.get(API_URL.ERC20_TOKEN_LIST)
                    .then(res => {
                        // console.log("usertoken:", res.data.data);
                        this.setState({ tokenValue: res.data.data })
                        this.state.tokenValue.map((d) => {
                            if (this.state.coinBalance && this.state.coinBalance[d.tokenSymbol + "_lock"]) {
                                // if(this.state.coinBalance.hasOwnProperty(this.state.coinBalance[d.tokenSymbol]+"_lock")){
                                this.setState({ [d.tokenSymbol + "_lock"]: Number(this.state.coinBalance[d.tokenSymbol + "_lock"]) })
                            }
                            else {
                                let val = d.tokenSymbol + "_lock"
                                this.setState({
                                    [d.tokenSymbol + "_lock"]: 0
                                })
                            }

                            if (this.state.coinBalance && this.state.coinBalance[d.tokenSymbol + "_lockDate"]) {
                                console.log(this.state.coinBalance[d.tokenSymbol + "_lockDate"]);
                                this.setState({ [d.tokenSymbol + "_lockDate"]: this.state.coinBalance[d.tokenSymbol + "_lockDate"] })
                                console.log(this.state[d.tokenSymbol + "_lockDate"]);

                            }
                            this.setState({
                                [d.tokenSymbol + "_balance"]: this.state.coinBalance ? Number(this.state.coinBalance[d.tokenSymbol]) : 0,
                                // [d.tokenSymbol + "_lock"]: Number(this.state.coinBalance[d.tokenSymbol+"_lock"])
                            })
                        })
                    })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }

    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }

    indexW(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }
    getDepositeHistory = async () => {
        await axios.post(API_URL.DEPOSITE_HISTORY, {
            "userId": id
        })
            .then(res => {
                // console.log("Deposite History:", res.data.data);
                this.setState({ depositeHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    getWithdrawalHistory = async () => {
        await axios.post(API_URL.WITHDRAW_HISTORY, {
            "userId": id
        })
            .then(res => {
                // console.log("Withdrawal History:", res.data.data);
                this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    getTradeHistory = async () => {
        await axios.post(API_URL.TRADE_HISTORY, {
            "userId": id,
            "coinName": this.state.coinName,
            "pair": this.state.pair
        })
            .then(res => {
                // console.log("Withdrawal History:", res.data.data);
                this.setState({ tradeHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }


    updateStatus = async () => {
        if (this.state.activationStatus === "Inactive") {
            editData["activationStatus"] = 0
        } else {
            editData["activationStatus"] = 1
        }
        let data = {
            "id": id,
            "activationStatus": Number(this.state.activationStatus),
            country: this.state.country
        }
        console.log(data);

        await axios.post(API_URL.UPDATE_USER, data)
            .then(res => {
                // console.log("User update:", res.data);

                if (res.data.message === "updatedSuccessuly") {
                    swal("Updated Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
                // this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }
    updateAccountBlockStatus = (status) => {
        // if (status === undefined) {
        //     return
        // }
        axios.post(API_URL.BLOCK_USER, { isAccountBlocked: status, userId: id })
            .then(res => {
                if (res.data.message === "success") {
                    const stateData = this.state.tokenData;
                    stateData["isAccountBlocked"] = status
                    this.setState({ tokenData: stateData })
                    swal(`Account ${status ? "blocked" : "unblocked"}  Successfully`, "", "success");
                } else {
                    swal("Unable to block", "", "error");
                }
            })
            .catch(error => {
                swal("Unable to block", "", "error");
                console.log("Error: ", error);
            })
    }
    buttonFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        if (row.status == "Pending") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-primary btn-xs"
                            >
                                Pending
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Completed") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-success btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Completed
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Rejected
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Edit User</h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right' }}>

                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    localStorage.setItem('userData', '')
                                                    this.props.history.push('/users')
                                                }}
                                                Style="float:right; margin: 20"> Back
                                            </Button>
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        {/* <div class="row">
                                            <div class="col-md-6">
                                                <CardTitle style={{ margin: 20 }}> Edit ERC20 Token</CardTitle>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div> */}
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="userName" style={label}>User Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="userName"
                                                            style={input}
                                                            value={this.state.tokenData.userName}
                                                            disabled />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="email" style={label}>Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="email"
                                                            style={input}
                                                            value={this.state.tokenData.emailId}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="refCode" style={label}>Ref. Code</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="refCode"
                                                            style={input}
                                                            value={this.state.tokenData.refCode}
                                                            disabled />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="status" style={label}>STATUS</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.activationStatus}
                                                            style={input}
                                                            onChange={e => {
                                                                this.setState({
                                                                    activationStatus: e.target.value,
                                                                })
                                                            }}>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="date" style={label}>Phone Number</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="date"
                                                            style={input}
                                                            value={this.state.tokenData.phoneNumber}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    <Button
                                                        onClick={this.updateStatus}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button>
                                                    <Button color="secondary"
                                                        onClick={this.cancleUpdate}>Cancel</Button>

                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="country" style={label}>Country</Label>
                                                        {/* <Input
                                                            type="email"
                                                            name="country"
                                                            id="country"
                                                            style={input}
                                                            value={this.state.tokenData.country}
                                                            disabled /> */}
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.country}
                                                            style={input}
                                                            onChange={e => {
                                                                this.setState({
                                                                    country: e.target.value,
                                                                })
                                                            }}>
                                                            <option value="">Select Country</option>
                                                            <option value="Afganistan">Afghanistan</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Antigua &amp; Barbuda">Antigua &amp; Barbuda</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bonaire">Bonaire</option>
                                                            <option value="Bosnia &amp; Herzegovina">Bosnia &amp; Herzegovina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                            <option value="Brunei">Brunei</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Canary Islands">Canary Islands</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African Republic">Central African Republic</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Channel Islands">Channel Islands</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos Island">Cocos Island</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Congo">Congo</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Cote DIvoire">Cote D'Ivoire</option>
                                                            <option value="Croatia">Croatia</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Curaco">Curacao</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="East Timor">East Timor</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Falkland Islands">Falkland Islands</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="France">France</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Ter">French Southern Ter</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Great Britain">Great Britain</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Hawaii">Hawaii</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Iran">Iran</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Isle of Man">Isle of Man</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Korea North">Korea North</option>
                                                            <option value="Korea Sout">Korea South</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Laos">Laos</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Lebanon">Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libya">Libya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macau">Macau</option>
                                                            <option value="Macedonia">Macedonia</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Midway Islands">Midway Islands</option>
                                                            <option value="Moldova">Moldova</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Nambia">Nambia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherland Antilles">Netherland Antilles</option>
                                                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                            <option value="Nevis">Nevis</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Palau Island">Palau Island</option>
                                                            <option value="Palestine">Palestine</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Phillipines">Philippines</option>
                                                            <option value="Pitcairn Island">Pitcairn Island</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                            <option value="Republic of Serbia">Republic of Serbia</option>
                                                            <option value="Reunion">Reunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russia">Russia</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="St Barthelemy">St Barthelemy</option>
                                                            <option value="St Eustatius">St Eustatius</option>
                                                            <option value="St Helena">St Helena</option>
                                                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                            <option value="St Lucia">St Lucia</option>
                                                            <option value="St Maarten">St Maarten</option>
                                                            <option value="St Pierre &amp; Miquelon">St Pierre &amp; Miquelon</option>
                                                            <option value="St Vincent &amp; Grenadines">St Vincent &amp; Grenadines</option>
                                                            <option value="Saipan">Saipan</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="Samoa American">Samoa American</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome &amp; Principe">Sao Tome &amp; Principe</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Serbia">Serbia</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="Spain">Spain</option>
                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syria">Syria</option>
                                                            <option value="Tahiti">Tahiti</option>
                                                            <option value="Taiwan">Taiwan</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Tanzania">Tanzania</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad &amp; Tobago">Trinidad &amp; Tobago</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks &amp; Caicos Is">Turks &amp; Caicos Is</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Erimates">United Arab Emirates</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="United States of America">United States of America</option>
                                                            <option value="Uraguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="Vatican City State">Vatican City State</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Vietnam">Vietnam</option>
                                                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                            <option value="Wake Island">Wake Island</option>
                                                            <option value="Wallis &amp; Futana Is">Wallis &amp; Futana Is</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Zaire">Zaire</option>
                                                            <option value="Zambia">Zambia</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="password" style={label}>Password</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="password"
                                                            style={input}
                                                            value={this.state.tokenData.password}
                                                            disabled />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="refBy" style={label}>Ref. By</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="refBy"
                                                            style={input}
                                                            value={this.state.tokenData.refBy}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="date" style={label}>Date</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="date"
                                                            style={input}
                                                            value={this.state.tokenData.createdOn}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    <FormGroup style={{ ...label, display: 'flex', whiteWrap: 'pre', marginTop: '55px', alignItems: "center", justifyContent: 'space-between' }}>
                                                        <div>
                                                            Disable 2FA
                                                            {/* {this.state.tokenData.two_factor === 0 ? */}
                                                            <input type='checkbox' style={{ marginTop: '3px', width: '30px' }}
                                                                checked={this.state.two_factor === 1}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        two_factor: e.target.checked ? 1 : 0
                                                                    }, async function () {
                                                                        let data = { "two_factor": this.state.two_factor, "id": id }
                                                                        await axios.post(API_URL.UPDATE_USER, data)
                                                                            .then(res => {
                                                                                console.log("User update:", res.data);

                                                                                if (res.data.message === "updatedSuccessuly") {
                                                                                    swal("Updated Successfully", "", "success");
                                                                                } else {
                                                                                    this.setState({
                                                                                        two_factor: this.state.two_factor == 1 ? 0 : 1
                                                                                    })
                                                                                    swal("Unable to update", "", "error");
                                                                                }
                                                                                // this.setState({ withdrawalHistory: res.data.data })
                                                                            })
                                                                            .catch(error => {
                                                                                console.log("Error: ", error);
                                                                            })
                                                                    })
                                                                }}
                                                                name="user"
                                                                color="primary"
                                                            />
                                                        </div>
                                                        <div>
                                                            Block Account <input type='checkbox' style={{ marginTop: '3px', width: '30px' }} checked={this.state.tokenData.isAccountBlocked} onChange={(e) => this.updateAccountBlockStatus(e.target.checked)} />
                                                        </div>

                                                    </FormGroup>





                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    KYC & Bank Details
                                                </CardHeader>
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Hyperverge Kyc</Tab>
                                                        <Tab>Manual Kyc</Tab>
                                                        <Tab>Bank Details</Tab>
                                                    </TabList>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <div className="table-responsive" style={{ padding: '20px' }}>
                                                            <table className="table table-hover" style={{
                                                                border: '1px solid #ecf0f2',
                                                                borderCollapse: 'collapse',

                                                            }}>
                                                                <thead style={{
                                                                    border: '1px solid #ecf0f2',
                                                                    borderCollapse: 'collapse',
                                                                    backgroundColor: '#ECF0F2'
                                                                }}>
                                                                    <tr>
                                                                        <th>HyperVerge Transaction id</th>
                                                                        <th>Full Name</th>
                                                                        <th>Father Name</th>
                                                                        <th>Date of Birth</th>
                                                                        <th>Address</th>
                                                                        <th>KYC Doc</th>
                                                                        <th></th>
                                                                        <th>Status</th>
                                                                        {/* <th>Available</th>
                                                    <th>In Order</th>
                                                    <th>Total</th>
                                                    <th>Action</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {(this.state.kycData || []).filter(kyc => kyc.kycType === "HyperVerge").map((d) => (
                                                                        <tr>
                                                                            <td>{d.userId}</td>
                                                                            <td>{d.fullName}</td>
                                                                            <td>{d.fatherName}</td>
                                                                            <td>{d.dateOfBirth}</td>
                                                                            <td>
                                                                                <b>Address</b>: {d.address}
                                                                                <br></br>
                                                                                <b>Zip Code</b>: {d.address?.zipCode}
                                                                                <br></br>
                                                                                <b>Country</b>: {"India"}
                                                                            </td>
                                                                            <td>
                                                                                <b>Doc Type</b>: {d.doctype}
                                                                                <br></br>
                                                                                <b>Doc Id No</b>: {d.docidno}
                                                                                <br></br>
                                                                                <b>Front Image</b>: <a href={d["POA-card-OCR-frontimageUrl"]} target="_blank"><img src={`${d["POA-card-OCR-frontimageUrl"]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                                <br></br>
                                                                                <b>Back Image</b>: <a href={d["POA-card-OCR-backimageUrl"]} target="_blank"><img src={`${d["POA-card-OCR-backimageUrl"]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                                <br></br>
                                                                                <b>Selfie Image</b>: <a href={d["Selfie-Capture-and-LivenessimageUrl"]} target="_blank"><img src={`${d["Selfie-Capture-and-LivenessimageUrl"]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                            </td>
                                                                            <td>
                                                                                {/* <b>Bank Name</b>: {d.bankname}
                                                                        <br></br>
                                                                        <b>A/C No</b>: {d.acno}
                                                                        <br></br>
                                                                        <b>IFSC</b>: {d.ifsc} */}
                                                                                <br></br>
                                                                                <b>Pan</b>:{d.panno} <a href={d["PAN-(POI)-Doc-Capture-frontimageUrl"]} target="_blank"><img src={`${d["PAN-(POI)-Doc-Capture-frontimageUrl"]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                            </td>
                                                                            <td>{d.status}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <div className="table-responsive" style={{ padding: '20px' }}>
                                                            <table className="table table-hover" style={{
                                                                border: '1px solid #ecf0f2',
                                                                borderCollapse: 'collapse',

                                                            }}>
                                                                <thead style={{
                                                                    border: '1px solid #ecf0f2',
                                                                    borderCollapse: 'collapse',
                                                                    backgroundColor: '#ECF0F2'
                                                                }}>
                                                                    <tr>
                                                                        <th>Full Name</th>
                                                                        <th>Date of Birth</th>
                                                                        <th>Address</th>
                                                                        <th>KYC Doc</th>
                                                                        <th>Bank Details</th>
                                                                        <th>Status</th>
                                                                        {/* <th>Available</th>
                                                    <th>In Order</th>
                                                    <th>Total</th>
                                                    <th>Action</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {(this.state.kycData || []).filter(kyc => kyc.kycType === "Manual").map((d) => (
                                                                        <tr>
                                                                            <td>{d.fullname}</td>
                                                                            <td>{d.dateofbirth}</td>
                                                                            <td>
                                                                                <b>Address</b>: {d.address}
                                                                                <br></br>
                                                                                <b>Zip Code</b>: {d.zipcode}
                                                                                <br></br>
                                                                                <b>Country</b>: {d.country}
                                                                            </td>
                                                                            <td>
                                                                                <b>Doc Type</b>: {d.doctype}
                                                                                <br></br>
                                                                                <b>Doc Id No</b>: {d.docidno}
                                                                                <br></br>
                                                                                <b>Front Image</b>: <a href={d.frontImage} target="_blank"><img src={`${imageURL}${d.frontImage.split('../kycImages/')[1]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                                <br></br>
                                                                                <b>Back Image</b>: <a href={d.backImage} target="_blank"><img src={`${imageURL}${d.backImage.split('../kycImages/')[1]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                                <br></br>
                                                                                <b>Selfie Image</b>: <a href={d.selfieImage} target="_blank"><img src={`${imageURL}${d.selfieImage.split('../kycImages/')[1]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                            </td>
                                                                            <td>
                                                                                <b>Bank Name</b>: {d.bankname}
                                                                                <br></br>
                                                                                <b>A/C No</b>: {d.acno}
                                                                                <br></br>
                                                                                <b>IFSC</b>: {d.ifsc}
                                                                                <br></br>
                                                                                <b>Passbook Image</b>: <a href={d.passbook} target="_blank"><img src={`${imageURL}${d.passbook.split('../kycImages/')[1]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                            </td>
                                                                            <td>{d.status}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                    <div className="table-responsive" style={{ padding: '20px' }}>
                                                            <table className="table table-hover" style={{
                                                                border: '1px solid #ecf0f2',
                                                                borderCollapse: 'collapse',

                                                            }}>
                                                                <thead style={{
                                                                    border: '1px solid #ecf0f2',
                                                                    borderCollapse: 'collapse',
                                                                    backgroundColor: '#ECF0F2'
                                                                }}>
                                                                    <tr>
                                                                        <th>Bank Name</th>
                                                                        <th>Account No</th>
                                                                        <th>IFSC</th>
                                                                        <th>PAN </th>
                                                                        <th>Passbook</th>
                                                                       
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    
                                                                        <tr>
                                                                            <td>{this.state.userBankDetails.bankname}</td>
                                                                            <td>{this.state.userBankDetails.acno}</td>
                                                                            <td>{this.state.userBankDetails.ifsc}</td>
                                                                            <td>{this.state.userBankDetails.panno}</td>
                                                                            <td>
                                                                               
                                                                               <a href={`${imageURL}IMAGE-${this.state.userBankDetails.passbook.split('IMAGE-')[1]}`} target="_blank">
                                                                               <img src={`${imageURL}IMAGE-${this.state.userBankDetails.passbook.split('IMAGE-')[1]}`} width="50px" height="50px" target="_blank" style={{ border: "1px #000 solid" }} /></a>
                                                                            </td>
                                                                        </tr>
                                                                    
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </TabPanel>
                                                </Tabs>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>


                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    User Balance
                                                </CardHeader>
                                                <div className="table-responsive" style={{ padding: '20px' }}>
                                                    <table className="table table-hover" style={{
                                                        border: '1px solid #ecf0f2',
                                                        borderCollapse: 'collapse',

                                                    }}>
                                                        <thead style={{
                                                            border: '1px solid #ecf0f2',
                                                            borderCollapse: 'collapse',
                                                            backgroundColor: '#ECF0F2'
                                                        }}>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Name</th>
                                                                <th>Balance</th>
                                                                <th>Lock Balance</th>
                                                                <th>Lock Date </th>
                                                                <th>Lock/Unlock Balance</th>
                                                                {/* <th>Available</th>
                                                    <th>In Order</th>
                                                    <th>Total</th>
                                                    <th>Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.tokenValue.map((d) => (
                                                                d.active == true &&
                                                                <tr>
                                                                    <td><img src={"https://ECanna.com/coinicon/" + d.tokenSymbol + ".png"} width="20px" height="20px" /></td>
                                                                    <td><span className="name">{d.tokenName} ({d.tokenSymbol})</span></td>

                                                                    <td> <input
                                                                        value={
                                                                            this.state[d.tokenSymbol + "_balance"]
                                                                        }
                                                                        onChange={(e) => {
                                                                            // ercCoinData[d.tokenSymbol] = e.target.value
                                                                            // let symbol = d.tokenSymbol
                                                                            // this.state.coinBalance[symbol].prop = e.target.value;
                                                                            // this.setState({ coinBalance: this.state.coinBalance });
                                                                            // console.log("Token change: ", d.tokenSymbol+ e.target.value)
                                                                            this.setState({
                                                                                [d.tokenSymbol + "_balance"]: e.target.value
                                                                            })
                                                                        }} />
                                                                    </td>
                                                                    <td> <input
                                                                        type="number"
                                                                        value={
                                                                            Number(this.state[d.tokenSymbol + "_lock"])
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                coinName: d.tokenSymbol, //coin name
                                                                                amountToUse: e.target.value, //amount whch we want to use for updation
                                                                                [d.tokenSymbol + "_lock"]: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                    </td>

                                                                    <td> <input
                                                                        type="date"
                                                                        value={
                                                                            this.state[d.tokenSymbol + "_lockDate"]
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                coinName: d.tokenSymbol, //coin name
                                                                                lockedDate: e.target.value, //amount whch we want to use for updation
                                                                                [d.tokenSymbol + "_lockDate"]: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            color="primary"
                                                                            style={{ marginRight: '20px' }}
                                                                            onClick={this.lockUserBalance}
                                                                        >Lock</Button>
                                                                        <Button color="secondary"
                                                                            onClick={this.unlockUserBalance}
                                                                        >Unlock</Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    <Button
                                                        onClick={this.updateUserBalance}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button>
                                                    <Button color="secondary"
                                                        onClick={this.cancleERC}>Cancel</Button>

                                                </div>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    User History
                                                </CardHeader>
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Deposit</Tab>
                                                        <Tab>Withdrawal</Tab>
                                                        <Tab>Trade History</Tab>
                                                    </TabList>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.depositeHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexN} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>



                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.withdrawalHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexW} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <div className="col-md-12" style={{ marginLeft: '8px' }}>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    {/* <Input placeholder="coin" /> */}
                                                                    <select
                                                                        className="form-control editor edit-text"
                                                                        style={input}
                                                                        // value={this.state.role}
                                                                        onChange={e => {
                                                                            console.log("Coin: ", e.target.value);
                                                                            this.setState({
                                                                                coinName: e.target.value,
                                                                            })
                                                                        }}>
                                                                        <option >Select coin</option>
                                                                        {this.state.ercAllTokenData.map((d) => (
                                                                            d.active == true && <>
                                                                                <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                                            </>
                                                                        ))}
                                                                        {/* <option style={{ color: '#999999' }}>--Select--</option> */}

                                                                        {/* <option value="Sub Admin">Sub Admin</option> */}
                                                                    </select>
                                                                </div>

                                                                <h1>/</h1>
                                                                <div className="col-md-3">
                                                                    <select
                                                                        className="form-control editor edit-text"
                                                                        style={input}
                                                                        // value={this.state.role}
                                                                        onChange={e => {
                                                                            console.log("Pair: ", e.target.value);
                                                                            this.setState({
                                                                                pair: e.target.value,
                                                                            })
                                                                        }}>
                                                                        <option >Select pair</option>
                                                                        <option value="BTC">BTC</option>
                                                                        <option value="ETH">ETH</option>
                                                                        <option value="USDT">USDT</option>
                                                                        <option value="INR">INR</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Button color="primary" onClick={this.getTradeHistory}>View Trade History</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <BootstrapTable data={this.state.tradeHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexW} thStyle={headerStyle} width={'10%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='pair' width={'10%'} thStyle={headerStyle}>PAIR</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='price' width={'10%'} thStyle={headerStyle}>PRICE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='tredingFee' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='total' width={'20%'} thStyle={headerStyle}>TOTAL</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='orderType' width={'20%'} thStyle={headerStyle}>LIMIT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='status' dataFormat={(cell, row) => <>{STATUS_ENUM[row.status]}</>} width={'18%'} thStyle={headerStyle}>STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>



                                                </Tabs>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
