import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class EcannaTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            transferdone: false
        }
    }

    UNSAFE_componentWillMount() {
        this.getTnxHistory();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
    }
    getTnxHistory = async () => {
        await axios.get(API_URL.ECNA_TRANSACTIONS)
            .then(res => {
                let tnx = res.data.data
              this.setState({transactions:tnx})
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    dateFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        {new Date(row.timestamp).toLocaleDateString()}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Ecanna Buy Transactions</h1>
                                    </div>
                                </div>{/* /.row */}
                                
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
            
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i> Ecanna Buy Transactions
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.transactions} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                          
                                                        <TableHeaderColumn isKey dataField='userId' thStyle={headerStyle}>USER ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='fromTokenSymbol' thStyle={headerStyle}>TOKEN SYMBOL</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tokenAmount' thStyle={headerStyle}>TOKEN AMOUNT</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tokenECNArate' thStyle={headerStyle}>ECNA PRICE ($)</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='ECNAReceived' thStyle={headerStyle}>RECEIVED AMOUNT (ECNA)</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='timestamp'dataFormat={this.dateFormatter.bind(this)} thStyle={headerStyle}>DATE </TableHeaderColumn>
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
