import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL, BASE_URL, imageURL } from "../../../config/api";
import swal from 'sweetalert';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class AddBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: '',
            imagePath: '',
            disable : true
        }
    }
componentDidMount(){
    axios.get(`${BASE_URL}banner/get`)
            .then((response) => {
                if(response.data.data && response.data.data.length){
                    this.setState({imagePath: imageURL+response.data.data[0].fileName})
                }
            })
}
    resetStates = () => {
        this.setState({
            status: '',
            imagePath: ''
        })
    }

    addImage = async (e) => {
        console.log("image: ", e.target.files[0].name)
        var uploadfile = e.target.files[0]
        const data = new FormData()
        data.append('file', uploadfile)
        axios.post(`${BASE_URL}banner/upload`,data,{})
            .then((response) => {
                if(response.data.message === "success"){
                    swal("Added Successfully", "", "success");
                    }
                let imagePath = imageURL + response.data.data.filename 
                console.log("image",imagePath)
                this.setState({ imagePath: imagePath, disable : false })
            }).catch((error) => { });
    }

    // saveImages = async () => {
    //     let sliderData = {
    //         "imagePath": this.state.imagePath,
    //     }
    //     await axios.post(`http://3.7.112.1:2087/slider/add`, sliderData)
    //      .then(res => {
    //         swal("Added Successfully", "", "success");
    //          if(res.data.data === "success"){
    //             swal("Added Successfully", "", "success");
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         })
    //         // .then((response) => {
    //         //     console.log("image", response)
    //         // }).catch((error) => { });
    // }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Add Slider</h1>
                                    </div>{/* /.col */}
                                    <div className="col-sm-6">
                                        <Button color="primary" style={{ float: 'right' }}
                                            onClick={() => {
                                                this.props.history.push('/sliderlist')
                                            }}>Back</Button>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody style={{ marginBottom: '20px' }}>
                                            <div class="row">
                                                    <div className="col-md-6">
                                                        <FormGroup>
                                                            <Label for="exampleFile" style={label}>File</Label>
                                                            <Input type="file" name="file" id="exampleFile" 
                                                                 onChange={(e) => {
                                                                    this.addImage(e)
                                                                }}/>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-6">
                                                   {this.state.imagePath && <img src={this.state.imagePath} alt="" style={{height: 125, borderRadius: 10}} />}
                                                    </div>
                                               

                                                {/* <div className="col-md-12">
                                                    <Button
                                                        onClick={this.saveImages}
                                                        style={{
                                                            marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                            marginLeft: '15px'
                                                        }} 
                                                        disabled={true}>Save</Button>
                                                    <Button color="secondary"
                                                        onClick={this.resetStates}>Reset</Button>
                                                </div> */}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
