import React, { Component, useEffect, useState } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    Label,
    Button,
    Form,
} from "reactstrap";
import { BASE_URL } from "../../config/api";
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';


import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

// const input = {
//     fontSize: '13px',
//     color: '#333'
// }

const InrCommission = () => {
    let history = useHistory();
    const [price, setPrice] = useState('');
  const [id, setId] = useState('');
  const [editMode, setEditMode] = useState(false)
const [loading, setLoading] = useState(true)

useEffect(() => {

    getPriceById ()
}, [])



    const resetStates = () => {
        setPrice('')
    }

    const getPriceById = () => {
        axios.get(`${BASE_URL}inrCommission/get`).then((res) => {
            // console.log(res.data.data)
            setLoading(false)
            setPrice(res?.data?.data?.commissionRate)
            // if(res?.data?.data?._id){
            //     setId(res?.data?.data?._id)
            // }
        }).catch(e =>{
            setLoading(false)
        })
    }
    const updateCommision = () => {
     try {
        axios.post(`${BASE_URL}inrCommission/update`, { commissionRate:price}).then((res) => {
            if (res.data.statusCode === 200) {
                swal("Updated Successfully","", "success");
            }else{
                swal(res.data.message, {icon: "error",
            });
            }
        })
    } catch (error) {
        
     }
    }

    return (
      <div>
        
        <Header />
        <Menu />
        <div className="wrapper">
          {/* Content Wrapper. Contains page content */}
          <div
            className="content-wrapper"
            style={{ marginTop: "0px", backgroundColor: "#E5E9EC" }}
          >
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Inr Deduction Rate</h1>
                  </div>
                  {/* /.col */}
                  <div className="col-sm-6">
                    <Button
                      color="primary"
                      style={{ float: "right" }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>
            <section className="content">
              <div className="container-fluid">
                <Form>
                  <Card>
                    <CardBody style={{ marginBottom: "20px" }}>
                      <div class="row">
                        <div className="col-md-8 ">
                          <FormGroup>
                            <Label for="exampleFile" style={label}>
                              Deduction Rate(%)
                            </Label>
                            <Input
                              disabled={(!editMode && price) || loading}
                              type="text"
                              value={price}
                              name="price"
                              id="exampleFile"
                              required
                              onChange={(e) => {
                                setPrice(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-4 d-flex align-items-center mt-3">
                          {!editMode && (
                            <Button
                              color="info"
                              onClick={() => setEditMode(true)}
                            >
                              Edit
                            </Button>
                          )}
                        </div>

                        {editMode ? (
                          <div className="col-md-12">
                            <Button
                              onClick={() => updateCommision()}
                              style={{
                                marginRight: "20px",
                                backgroundColor: "#F14D4D",
                                borderColor: "#F14D4D",
                                marginLeft: "15px",
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              color="secondary"
                              onClick={() => resetStates()}
                            >
                              Reset
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Form>
              </div>
            </section>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
}

export default InrCommission;