import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL, BASE_URL } from "../../../config/api";
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

var id;

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class EditAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: [],
            userManagemanet : false,
            list: false,
            manage : false,
            activityLog : false,
            changePwd : false,
            transactions : false,
            memberBalance : false,
            erc20 : false,
            kycVerification: false,
            deposit: false,
            withdraw: false,
            INRlist: false,
            roleList:[]
        }
    }

    componentDidMount() {
        this.getRoleList()
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
        let editData = localStorage.getItem('adminUserData', '')
        editData = JSON.parse(editData)
        console.log("On edit admin: ", editData);
        id = editData._id
        this.setState({
            tokenData: editData,
            id: editData._id,
            userName: editData.userName,
            password: editData.password,
            email: editData.email,
            role: editData.role,
            userManagemanet : editData.userManagemanet,
            list: editData.list,
            manage : editData.manage,
            activityLog : editData.activityLog,
            changePwd : editData.changePwd,
            transactions : editData.transactions,
            memberBalance : editData.memberBalance,
            erc20 : editData.erc20,
            kycVerification: editData.kycVerification,
            deposit: editData.deposit,
            withdraw: editData.withdraw,
            INRlist: editData.INRlist
        })
    }

    addAdminUser = async() => {
        let adminData = {
            id: this.state.id,
            userName: this.state.userName,
            email: this.state.email,
            password: this.state.password,
            role: this.state.role,
            userManagemanet : this.state.userManagemanet,
            list: this.state.list,
            manage : this.state.manage,
            activityLog : this.state.activityLog,
            changePwd : this.state.changePwd,
            transactions : this.state.transactions,
            memberBalance : this.state.memberBalance,
            erc20 : this.state.erc20,
            kycVerification: this.state.kycVerification,
            deposit: this.state.deposit,
            withdraw: this.state.withdraw,
            INRlist: this.state.INRlist
        }

        axios.post(API_URL.UPDATE_ADMIN, adminData)
            .then(res => {
                console.log("response:", res);
                if(res.data.message === "updatedSuccessuly"){
                    swal("Updated Successfully", "", "success");
                    this.getUserList();
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    resetStates = async() => {
        this.setState({
            userName: '',
            password: '',
            email: '',
            confirmPwd: '',
            role: '',
            userManagemanet : false,
            list: false,
            manage : false,
            activityLog : false,
            changePwd : false,
            transactions : false,
            memberBalance : false,
            erc20 : false,
            kycVerification: false,
            deposit: false,
            withdraw: false,
            INRlist: false
        })
    }
    getRoleList = async () => {
        await axios.post(`${BASE_URL}admin/role/get`)
            .then(res => {
                let roles = res.data.data
                this.setState({
                    roleList: roles
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }
    render() {
        // console.log(this.state.tokenValue);

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Update Admin User</h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                  <div className="row" style={{ float: 'right' }}>
                          <Button
                            color="primary"
                            onClick={() => this.props.history.push('/adminlist')}
                            Style="float:right; margin: 20"> Back
                            </Button>
                        </div>
                  </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody style={{marginBottom: '20px'}}>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>User Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.userName}
                                                            placeholder="Enter User Name" 
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    userName: e.target.value
                                                                })
                                                            }}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.password}
                                                            placeholder="Enter Password" 
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    password: e.target.value
                                                                })
                                                            }}/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Role</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            style={input}
                                                            value={this.state.role}
                                                            onChange={e => {
                                                                this.setState({
                                                                    role: e.target.value,
                                                                })
                                                            }}>
                                                            <option style={{ color: '#999999' }}>--Select--</option>
                                                            {/* <option value="Admin">Super Admin</option>
                                                            <option value="Sub Admin">Sub Admin</option> */}
                                                            {this.state.roleList.filter(e=> !e.isDeleted).map((rol)=>(
                                                                <option value={rol.roleName}>{rol.roleName}</option>
                                                            )) }
                                                        </select>
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.email}
                                                            placeholder="Enter Email"
                                                            onChange={e => {
                                                                this.setState({
                                                                    email: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Confirm Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.confirmPwd}
                                                            placeholder="Enter Confirm Password"
                                                            onChange={e => {
                                                                this.setState({
                                                                    confirmPwd: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                </div>

                                                {/* <div className="column">
                                                        <Label for="exampleEmail" style={label}>ACCESS PRIVILEGE</Label>
                                                        <div className="column">
                                                        <FormGroup className="col-lg-2">
                                                            <Input type="checkbox" />{' '}
                                                                    Check me out
                                                        </FormGroup>
                                                        </div>
                                                       
                                                    <Button
                                                        onClick={this.updateStatus}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button>
                                                    <Button color="secondary"
                                                        onClick={this.updateStatus}>Cancle</Button>
                                                </div> */}
                                                {/* 
                                                <FormGroup >
                                                <Input type="checkbox" />{' '}
                                                <Label >User Management </Label>
                                                </FormGroup>

                                                <FormGroup >
                                                <Input type="checkbox" />{' '}
                                                <Label >List</Label>
                                                </FormGroup>

                                                <FormGroup >
                                                <Input type="checkbox" />{' '}
                                                <Label >Settings</Label>
                                                </FormGroup>
                                                 */}
                                                 <div style={{marginLeft: '15px'}}>
                                                 <div className="grid-title no-border" style={{marginBottom: '20px'}}> 
                                                        <h4 style={{
                                                            fontSize: '15px', color:'#646089', marginTop: '10px'
                                                        }}><strong>Add Priveleges</strong></h4>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-2.5">
                                                    <FormGroup>
                                                    <Checkbox
                                                        checked= {this.state.userManagemanet}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                userManagemanet: !this.state.userManagemanet
                                                            })
                                                        }}
                                                        name="user"
                                                        color="primary"
                                                    /> User Management
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2">
                                                 <FormGroup>
                                                    <Checkbox
                                                        checked= {this.state.list}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                list: !this.state.list
                                                            })
                                                        }}
                                                        name="wsdw"
                                                        color="primary"
                                                    /> List
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2">
                                                 <FormGroup>
                                                    <Checkbox
                                                       checked= {this.state.manage}
                                                       onClick={(e) => {
                                                           this.setState({
                                                               manage: !this.state.manage
                                                           })
                                                       }}
                                                        name="wsdw"
                                                        color="primary"
                                                    /> Manage
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2">
                                                 <FormGroup>
                                                    <Checkbox
                                                        checked= {this.state.activityLog}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                activityLog: !this.state.activityLog
                                                            })
                                                        }}
                                                        name="wsdw"
                                                        color="primary"
                                                    /> Activity Log
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2.5">
                                                 <FormGroup>
                                                    <Checkbox
                                                       checked= {this.state.changePwd}
                                                       onClick={(e) => {
                                                           this.setState({
                                                               changePwd: !this.state.changePwd
                                                           })
                                                       }}
                                                        name="wsdw"
                                                        color="primary"
                                                    /> Change Password
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2">
                                                 <FormGroup>
                                                    <Checkbox
                                                       checked= {this.state.transactions}
                                                       onClick={(e) => {
                                                           this.setState({
                                                               transactions: !this.state.transactions
                                                           })
                                                       }}
                                                        name="wsdw"
                                                        color="primary"
                                                    /> Transaction
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2.5">
                                                 <FormGroup>
                                                    <Checkbox
                                                        checked= {this.state.memberBalance}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                memberBalance: !this.state.memberBalance
                                                            })
                                                        }}
                                                        name="wsdw"
                                                        color="primary"
                                                    /> Member Balance
                                                 </FormGroup>
                                                 </div>
                                                 <div style={{marginLeft: '17px'}}>
                                                 <FormGroup>
                                                    <Checkbox
                                                       checked= {this.state.erc20}
                                                       onClick={(e) => {
                                                           this.setState({
                                                               erc20: !this.state.erc20
                                                           })
                                                       }}
                                                        name="wsdw"
                                                        color="primary"
                                                    /> Coins
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2.5" style={{  marginRight: '15px', marginLeft: '20px' }}>
                                                            <FormGroup>
                                                                <Checkbox
                                                                    checked={this.state.kycVerification}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            kycVerification: !this.state.kycVerification
                                                                        })
                                                                    }}
                                                                    name="wsdw"
                                                                    color="primary"
                                                                /> KYC Verification
                                                 </FormGroup>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <FormGroup>
                                                                <Checkbox
                                                                    checked={this.state.deposit}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            deposit: !this.state.deposit
                                                                        })
                                                                    }}
                                                                    name="wsdw"
                                                                    color="primary"
                                                                /> Deposit
                                                 </FormGroup>
                                                        </div>
                                                        <div className="col-md-2.5">
                                                            <FormGroup>
                                                                <Checkbox
                                                                    checked={this.state.withdraw}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            withdraw: !this.state.withdraw
                                                                        })
                                                                    }}
                                                                    name="wsdw"
                                                                    color="primary"
                                                                /> Withdraw
                                                 </FormGroup>
                                                 </div>
                                                 <div className="col-md-2.5" style={{marginLeft: '50px'}}>
                                                            <FormGroup>
                                                                <Checkbox
                                                                    checked={this.state.INRlist}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            INRlist : !this.state.INRlist
                                                                        })
                                                                    }}
                                                                    name="wsdw"
                                                                    color="primary"
                                                                /> INR List
                                                 </FormGroup>
                                                        </div>
                                                 </div>
                                                 </div>

                                                 <Button
                                                        onClick={this.addAdminUser}
                                                        style={{ marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                            marginLeft: '15px' }}>Submit</Button>
                                                    <Button color="secondary"
                                                        onClick={this.resetStates}>Reset</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
