import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL } from "../../config/api";
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import '../UserManagement/UserList/userTable.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

var id;
var editData=[];

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class EditToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: [],
            userBalace: [],
            tokenValue: [],
            depositeHistory: [],
            withdrawalHistory: [],
           
        }
    }

    async componentDidMount() {
        let emailData = localStorage.getItem('emailData', '')
        console.log("Email: ", emailData);
        id = emailData
        await axios.post(API_URL.USER_BY_ID, {
            "id": id
        })
            .then(res => {
                console.log("user by id:", res.data.data);
                this.setState({
                    tokenData: res.data.data
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

        // this.tableData()
        this.getUserBalance()
        this.getDepositeHistory()
        this.getWithdrawalHistory()
    }


    getUserBalance = async () => {
        console.log("ID withdraw: ", id)
        await axios.post(API_URL.USER_BALANCE, {
            "userId": id
        })
            .then(res => {
                console.log("Edit user Balance:", res.data.data);
                this.setState({ coinBalance: res.data.data })
                axios.get(API_URL.ERC20_TOKEN_LIST)
                    .then(res => {
                        console.log("usertoken:", res.data.data);
                        this.setState({ tokenValue: res.data.data })
                    })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }

    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }

    indexW(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }
    getDepositeHistory = async () => {
        await axios.post(API_URL.DEPOSITE_HISTORY, {
            "userId": id
        })
            .then(res => {
                console.log("Deposite History:", res.data.data);
                this.setState({ depositeHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    getWithdrawalHistory = async () => {
        await axios.post(API_URL.WITHDRAW_HISTORY, {
            "userId": id
        })
            .then(res => {
                console.log("Withdrawal History:", res.data.data);
                this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    updateStatus = async() => {
        if (this.state.activationStatus === "Inactive") {
            editData["activationStatus"] = 0 
        } else {
            editData["activationStatus"] = 1
        }
        let data = editData
        console.log(data);
        
        await axios.post(API_URL.UPDATE_USER, data)
            .then(res => {
                console.log("User update:", res.data);
                
                if(res.data.message === "updatedSuccessuly"){
                    swal("Updated Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
                // this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }
    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        if (row.status == "Pending") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-primary btn-xs"
                            >
                                Pending
                        </p>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Completed") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-success btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Completed
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Rejected
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        // console.log(this.state.tokenValue);

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Edit User</h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right' }}>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    localStorage.setItem('userData', '')
                                                    this.props.history.push('/users')}}
                                                Style="float:right; margin: 20"> Back
                                            </Button>
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        {/* <div class="row">
                                            <div class="col-md-6">
                                                <CardTitle style={{ margin: 20 }}> Edit ERC20 Token</CardTitle>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div> */}
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>User Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.tokenData.userName}
                                                            disabled />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Country</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.tokenData.country}
                                                            disabled />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Ref. Code</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.tokenData.refCode}
                                                            disabled />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>STATUS</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.activationStatus}
                                                            style={input}
                                                            onChange={e => {
                                                                this.setState({
                                                                    activationStatus: e.target.value,
                                                                })
                                                            }}>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </FormGroup>

                                                    <FormGroup style={label}>
                                                        Disable 2FA
                                                         {this.state.tokenData.two_factor === 0 ?
                                                            <Checkbox
                                                                checked={true}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        two_factor: 1
                                                                    })
                                                                }}
                                                                name="user"
                                                                color="primary"
                                                            /> :
                                                            <Checkbox
                                                                checked={false}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        two_factor: 0
                                                                    })
                                                                }}
                                                                name="user"
                                                                color="primary"
                                                            />
                                                        }

                                                    </FormGroup>

                                                    <Button
                                                        onClick={this.updateStatus}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button>
                                                    <Button color="secondary"
                                                        onClick={this.cancleUpdate}>Cancle</Button>

                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.tokenData.emailId}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Ref. By</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.tokenData.refBy}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Date</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.tokenData.createdOn}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    User Balance
                                                </CardHeader>
                                                <div className="table-responsive" style={{ padding: '20px' }}>
                                                    <table className="table table-hover" style={{
                                                        border: '1px solid #ecf0f2',
                                                        borderCollapse: 'collapse',

                                                    }}>
                                                        <thead style={{
                                                            border: '1px solid #ecf0f2',
                                                            borderCollapse: 'collapse',
                                                            backgroundColor: '#ECF0F2'
                                                        }}>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Name</th>
                                                                <th>Balance</th>
                                                                {/* <th>Available</th>
                                                    <th>In Order</th>
                                                    <th>Total</th>
                                                    <th>Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.tokenValue.map((d) => (
                                                                <tr>
                                                                    <td><img src={"/coinicon/" + d.tokenSymbol + ".png"} width="20px" height="20px" /></td>
                                                                    <td><span className="name"> ({d.tokenSymbol})</span></td>
                                                                    <td> <input
                                                                        value={Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6) >= 0 ?
                                                                            Number(this.state.coinBalance[d.tokenSymbol]).toFixed(6) : 0.000000} 
                                                                            onChange={(e) => {
                                                                                console.log(e.target.value);
                                                                                this.setState({coinBalance: e.target.value})
                                                                            }}/>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    <Button
                                                        onClick={this.updateERC20}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button>
                                                    <Button color="secondary"
                                                        onClick={this.cancleERC}>Cancle</Button>

                                                </div>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    User History
                                                </CardHeader>
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Deposite</Tab>
                                                        <Tab>Withdrawal</Tab>
                                                    </TabList>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.depositeHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexN} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.withdrawalHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexW} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>
                                                </Tabs>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
