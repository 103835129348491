import React, { Component } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Menu from '../Component/Menu';
import { API_URL, BASE_URL } from "../config/api";
import swal from 'sweetalert';

import ReactSwitch from 'react-switch';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";

import {axiosInstance} from './../utils/interceptor';
const axios = axiosInstance;




const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked : false,
            _id : ""
        }
    }

    componentDidMount() {
        let editData = localStorage.getItem('loginData', '')
        editData = JSON.parse(editData)
        console.log("On edit page: ", editData._id);
        this.setState({
            id: editData._id
        })
        this.getBinanceSetting()
    }


    getBinanceSetting = async ()=>{
        axios.get(API_URL.GET_BINANCE_SETTING).then((result)=>{
                console.log(result)
                if(result.data.statusCode == 200){
                    this.setState({
                        checked : result.data.data.isBinanceActive,
                        _id : result.data.data._id
                    })
                }
        }).catch((err)=>{
            console.log(err);
        })
    }



    updateStatus = async (data) => {
        console.log(data)
        this.setState({
            checked : data
        })

        let sendKey = {
            "isBinanceActive": data,
            "id" : this.state._id
        }
        console.log(sendKey);

        await axios.post(API_URL.UPDATE_BINANCE_SETTING, sendKey)
            .then(res => {
                if (res.data.statusCode === 200) {
                    swal("Setting Changed Successfully", "", "success");
                } else {
                    swal("Error", "", "error");
                }
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    cancleChange = () => {
        this.setState({
            newPassword: '',
            oldPassword: '',
            confirmPassword: ''
        })
    }







    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Binance Setting</h1>
                                    </div>{/* /.col */}
                                    <div className="col-sm-6 text-right">
                                        
                                        <ReactSwitch
                                            checked={this.state.checked}
                                            onChange={this.updateStatus}
                                        />




                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        {/* <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Old Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.oldPassword}
                                                            placeholder="Enter Old Password"
                                                            onChange={e => {
                                                                this.setState({
                                                                    oldPassword: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>New Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="newPwd"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.newPassword}
                                                            placeholder="Enter New Password"
                                                            onChange={e => {
                                                                this.setState({
                                                                    newPassword: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Confirm Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="address"
                                                            id="exampleEmail"
                                                            value={this.state.confirmPassword}
                                                            style={input}
                                                            placeholder="Enter Confirm Password"
                                                            onChange={e => {
                                                                this.setState({
                                                                    confirmPassword: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <Button
                                                    onClick={this.updateStatus}
                                                    style={{
                                                        marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                        marginLeft: '10px'
                                                    }}>Update</Button>
                                                <Button color="secondary"
                                                    onClick={this.cancleChange}>Cancel</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section> */}
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
