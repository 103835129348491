import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }
var data = [];

export default class AdminList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUsers: []
        }
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
        this.getUserList();
    }

    getUserList = async () => {
        await axios.get(API_URL.ADMIN_LIST)
            .then(res => {
                console.log("all users: ", res.data.data);
                let userData = res.data.data
                userData.map((d) => {
                    if (d.activationStatus === false) {
                        d["activationStatus"] = "Inactive"
                    } else {
                        d["activationStatus"] = "Active"
                        // data.push(d)
                        this.setState({
                            allUsers: userData
                        })
                    }
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    deleteUser(row) {
        let userId = row._id
        let status = row.activationStatus
        console.log("Status: ", status);
        
        if(status === "Active"){
            status = false
        } else {
            status = true
        }

        let userData = {
            "id": userId,
            "activationStatus" : status
        }
        axios.post(API_URL.DELETE_ADMIN, userData)
            .then(res => {
                console.log("response:", res);
                if(res.data.message === "updatedSuccessuly"){
                    swal("Deleted Successfully", "", "success");
                    this.getUserList();
                } else {
                    swal("Unable to Delete", "", "error");
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-6">
                        <a href="#" className="btn btn-block btn-danger btn-xs"
                            onClick={() => {
                                console.log("Cell value: ", row)
                                this.deleteUser(row)
                            }}>
                            <i className="fa fa-times" />
                        </a>
                    </div>
                    <div class="col-md-6">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            onClick={() => {
                                let data = JSON.stringify(row)
                                console.log("Row:", row);
                                localStorage.setItem('adminUserData', data)
                                this.setState({ allUsers: []})
                                this.props.history.push("/editadmin")
                            }}>
                            <i className="fa fa-edit " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Users</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  All Users
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.allUsers} pagination
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}
                                                        search>
                                                        <TableHeaderColumn isKey dataField='userName'  thStyle={headerStyle}>NAME</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='role' thStyle={headerStyle}>ROLE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='createdOn' width={'22%'} thStyle={headerStyle}>DATE OF JOINING</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='role'  thStyle={headerStyle}>ROLE</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            width={'15%'}
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
