import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import Pagination from "react-js-pagination";
import { Label } from "reactstrap";
import './styleTable.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { axiosInstance } from './../../../utils/interceptor';
import ManualKYC from './ManualKyc';
import HypervergeKYC from './HypervergeKycList';
import BankDetails from './BankDetails';
const axios = axiosInstance;

var phoneNumber = '';
var email = '';
var kyc = ''
var pageAll = 1;
var pageApproved = 1;
var pagePending = 1;
var pageRejected = 1;
const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class KycList extends Component {
    constructor(...args) {
        super(...args);
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">KYC & Bank Details</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  KYC & Bank Details
                                                </CardHeader>
                                                <Tabs >
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Hyperverge Kyc</Tab>
                                                        <Tab>Non-Indian Kyc</Tab>
                                                        <Tab>Bank Details</Tab>
                                                    </TabList>

                                                    {/* For ALL KYC  */}
                                                    <TabPanel style={{ padding: '4px' }}>
                                                        <HypervergeKYC  {...this.props}/>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '4px' }}>
                                                        <ManualKYC {...this.props}/>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '4px' }}>
                                                       <BankDetails />
                                                    </TabPanel>
                                                </Tabs>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}
