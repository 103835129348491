import axios  from "axios";
// import {createBrowserHistory} from 'history';

export const axiosInstance = axios.create();

// const history = useHistory()
// let history = createBrowserHistory();
axiosInstance.interceptors.request.use(
    async (config) => {
      const user = localStorage.getItem('loginData');
        const token = user ? JSON.parse(user)?.token : ''
      if (token) {
        if(config.data && config.data['isExternal']){

        }else{
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
  if(error?.response?.status === 401){
    // history.replace('/');
    localStorage.removeItem('adminLogin')
    localStorage.removeItem('loginData')
    alert('Session has been expired!')
    window.location.href ='/'
  }
    // return Promise.reject(error);
  }
);