import React, { Component } from 'react';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import { API_URL } from "../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import '../styles/dashboard.css';
import { FaLess } from 'react-icons/fa';

import {axiosInstance} from './../utils/interceptor';
const axios = axiosInstance;

var data = [];
var data2 = [];
var userWithdrawData = [];
var userDepositeData = [];
var btcw = 0; var ethw = 0; var xrpw = 0; var ltcw = 0; var dashw = 0; var xlmw = 0; var trxw = 0; var bchw = 0; var usdtw = 0; var feesw = 0; var etcw = 0; var ballxw = 0; var dogew = 0;
var btcd = 0; var ethd = 0; var xrpd = 0; var ltcd = 0; var dashd = 0; var xlmd = 0; var trxd = 0; var bchd = 0; var usdtd = 0; var feesd = 0; var etcd = 0; var ballxd = 0; var doged = 0;
var count  = 0;

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthrawList: [],
      depositeList: [],
      firstFiveWIthdraw: [],
      firstFiveDeposite: [],
      tokenValue: [],
      btcw : 0, ethw : 0, xrpw : 0, ltcw : 0,dashw :0,xlmw : 0, trxw : 0, bchw : 0,usdtw :0,feesw : 0, etcw : 0, ballxw : 0, dogew : 0,
      btcd : 0, ethd : 0,xrpd : 0, ltcd  : 0,dashd :0,xlmd : 0, trxd : 0, bchd : 0,usdtd :0,feesd : 0, etcd : 0, ballxd : 0, doged : 0
    }
  }

  componentWillMount() {
    this.getWithdrawsCompleted();
    this.getDepositCompleted();
    // window.location.reload()
  }

  componentDidMount(){
    let login = localStorage.getItem('adminLogin', '')
    console.log(login);
    if (login !== '1') {
      this.props.history.push('/');
    }
  }

  getERC20Token = async () => {
    await axios.get(API_URL.ERC20_TOKEN_LIST)
      .then(res => {
        console.log("ERC20 token:", res.data.data);
        let data = res.data.data

        this.setState({
          tokenValue: res.data.data,
        })
      })
      .catch(error => {
        console.log("Error: ", error);
      })
  }

  getWithdrawsCompleted = async () => {
    
    await axios.get(API_URL.WITHDRAW_GET)
      .then(res => {
        userWithdrawData = res.data.data
        // userWithdrawData.map((d) => {
        //   if (d.status === "Completed") {
        //     data.push(d)
        //   }
        // })
        data = userWithdrawData.filter((d) => {
          return d.status === "Completed"
        })
        console.log("Hell:", data)
        data.map((d) => {
          if (d.coinName === "BTC") {
            btcw = d.amount + btcw
            console.log("BTCw", btcw);
            this.setState({btcw: btcw})
          } 
          else if (d.coinName === "ETH") {
            ethw += d.amount
          } else if (d.coinName === "XRP") {
            xrpw += d.amount
          } else if (d.coinName === "TRX") {
            trxw += d.amount
          } else if (d.coinName === "BCH") {
            bchw += d.amount
          } else if (d.coinName === "USDT") {
            usdtw += d.amount
          } else if (d.coinName === "BALLX") {
            ballxw += d.amount
          } else if (d.coinName === "DOGE") {
            dogew += d.amount
          }
        })
        let firstFive = res.data.data
        firstFive = firstFive.splice(0, 5)
        // console.log("First Five: ", firstFive);
        this.setState({
          firstFiveWIthdraw: firstFive
        })
      })
      .catch(error => {
        console.log("Error: ", error);
      })
  }

  // getWithdraws = async () => {
  //   await axios.get(API_URL.WITHDRAW_GET)
  //     .then(res => {
  //       let firstFive = res.data.data
  //       firstFive = firstFive.splice(0, 5)
  //       // console.log("First Five: ", firstFive);
  //       this.setState({
  //         firstFiveWIthdraw: firstFive
  //       })
  //     })
  //     .catch(error => {
  //       console.log("Error: ", error);
  //     })
  // }

  getDepositCompleted = async () => {
    await axios.get(API_URL.DEPOSITE_GET)
      .then(res => {
        userDepositeData = res.data.data
        userDepositeData.map((d) => {
          if (d.status === "Completed") {
            data2.push(d)
          }
        })

        console.log("Heaven: ",data)
        data2.map((d) => {
          if (d.coinName === "BTC") {
            btcd = d.amount + btcd
            console.log("BTCd", btcd);
          } else{
            btcd = 0
          }
          if (d.coinName === "ETH") {
            ethd = d.amount + ethd
          } 
          if (d.coinName === "XRP") {
            xrpd = d.amount + xrpd
          } 
          if (d.coinName === "LTC") {
            ltcd += d.amount
          } 
          if (d.coinName === "DASH") {
            dashd += d.amount
          } 
          if (d.coinName === "XLM") {
            xlmd += d.amount
          } 
          if (d.coinName === "TRX") {
            trxd += d.amount
          } 
          if (d.coinName === "BCH") {
            bchd += d.amount
          } 
          if (d.coinName === "ETC") {
            etcd += d.amount
          } 
          if (d.coinName === "USDT") {
            usdtd += d.amount
          } 
          if (d.coinName === "BALLX") {
            ballxd += d.amount
          }
          if (d.coinName === "DOGE") {
            doged += d.amount
          }
        })

        let firstFive = res.data.data
        firstFive = firstFive.splice(0, 5)
        // console.log("First Five: ", firstFive);
        this.setState({
          firstFiveDeposite: firstFive
        })
      
      })
      .catch(error => {
        console.log("Error: ", error);
      })
  }

  // getDeposites = async () => {
  //   await axios.get(API_URL.DEPOSITE_GET)
  //     .then(res => {
  //       let firstFive = res.data.data
  //       firstFive = firstFive.splice(0, 5)
  //       // console.log("First Five: ", firstFive);
  //       this.setState({
  //         firstFiveDeposite: firstFive
  //       })
  //     })
  //     .catch(error => {
  //       console.log("Error: ", error);
  //     })
  // }

  
  render() {
    // count = 0;
    console.log("inside render: ", btcw);
    
    return (
      <div>
        <Header />
        <Menu  />
        <div className="wrapper">
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Dashboard</h1>
                  </div>
                </div>
              </div>
            </div>
            <section className="content">
              <div className="container-fluid">

                <div className="col-md-12">
                  <Row>
                    <Col >
                      <Card>
                        <CardHeader style={{ fontWeight: 'bold', fontSize: '18px', color: '#777' }}>
                          Total Deposits
                        </CardHeader>
                        <CardBody>
                        <div classname="col-md-12">
                            <div className="row">
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>BTC</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{btcd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>ETH</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{ethd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>XRP</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{xrpd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>TRX</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{trxd}</h3>
                              </div>
                            </div>
                            {/* 
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>LTC</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{ltcd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>DASH</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{dashd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>XLM</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{xlmd}</h3>
                              </div>
                            </div>
                            
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>BCH</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{bchd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>ETC</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{etcd}</h3>
                              </div>
                            </div> */}
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>USDT</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{usdtd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>BALLX</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{ballxd}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: '#DDDDDD', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>DOGE</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{doged}</h3>
                              </div>
                            </div>
                          </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div className="col-md-12">
                  <Row>
                    <Col >
                      <Card>
                        <CardHeader style={{ fontWeight: 'bold', fontSize: '18px', color: '#777' }}>
                          Total Completed Withdrawal
                        </CardHeader>
                        <CardBody>
                          <div classname="col-md-12">
                            <div className="row">
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>BTC</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{this.state.btcw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>ETH</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{ethw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>XRP</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{xrpw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>TRX</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{trxw}</h3>
                              </div>
                            </div>
                            {/* 
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>LTC</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{ltcw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>DASH</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{dashw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>XLM</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{xlmw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>BCH</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{bchw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>ETC</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{etcw}</h3>
                              </div>
                            </div> */}
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>USDT</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{usdtw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>BALLX</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{ballxw}</h3>
                              </div>
                            </div>
                            <div className="col-md-2" style={{ padding: 5 }}>
                              <div style={{ background: 'rgba(255,0,0,0.2)', padding: '10px' }}>
                                <h4 style={{ fontSize: 15, margin: 0 }}>DOGE</h4>
                                <h3 style={{ fontSize: 15, fontWeight: 'bold', margin: 0 }}>{dogew}</h3>
                              </div>
                            </div>
                          </div>
                          </div>

                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Row>
                      <Col >
                        <Card>
                          <CardHeader>
                            <div className="row">
                              <div className="col-md-6" style={{ fontWeight: 'bold', fontSize: '18px', color: '#777' }}>
                                Recent 5 Deposits
                           </div>
                              <div className="col-md-6" style={{ float: "right" }}>
                                <button type="button" class="btn btn-danger btn-xs"
                                  onClick={(e) => {
                                    this.props.history.push('/depositefund')
                                  }}
                                  style={{ float: 'right' }}>View All</button>
                              </div>
                            </div>
                          </CardHeader>
                          <CardBody>
                            {this.state.firstFiveDeposite.map((d) => (
                              <div className="notification-messages info clearfix" style={{ padding: '10px', marginBottom: '10px', borderRadius: 3 }}>
                                <div className="message-wrapper">
                                  <div className="row" style={{ marginBottom: '5px' }}>
                                    <p style={{ fontSize: '18px', marginRight: '10px', marginLeft: '5px' }}>{d.userId}</p>
                                    {d.status === "Completed" ?
                                      <p style={{ backgroundColor: '#0aa699', paddingRight: '5px', paddingLeft: '5px', color: '#fff', fontSize: '11px' }}>{d.status}</p>
                                      : (d.status === "Pending" ?
                                        <p style={{ backgroundColor: '#E0A800', paddingRight: '5px', paddingLeft: '5px', color: '#fff', fontSize: '11px' }}>{d.status}</p> :
                                        <p style={{ backgroundColor: '#C82333', paddingRight: '5px', paddingLeft: '5px', color: '#fff', fontSize: '11px' }}>{d.status}</p>
                                      )}
                                  </div>
                                  <div className="description"><a href="#"> {d.email}</a> </div>
                                </div>
                                <div className="date pull-right" style={{ opacity: 1 }}>
                                  <span className="muted bold text-success" style={{ fontSize: '15px' }}>{d.amount} {d.coinName}</span>
                                  <br />
                                  {d.date}</div>
                              </div>
                            ))}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <div className="col-md-6">
                    <Row>
                      <Col >
                        <Card>
                          <CardHeader>
                            <div className="row">
                              <div className="col-md-6" style={{ fontWeight: 'bold', fontSize: '18px', color: '#777' }}>
                                Recent 5 Withdrawal
                           </div>
                              <div className="col-md-6" style={{ float: "right" }}>
                                <button type="button" class="btn btn-danger btn-xs"
                                  onClick={(e) => {
                                    this.props.history.push('/withdrawlist')
                                  }}
                                  style={{ float: 'right' }}>View All</button>
                              </div>
                            </div>
                          </CardHeader>
                          <CardBody>
                            {this.state.firstFiveWIthdraw.map((d) => (
                              <div className="notification-messages info clearfix" style={{ padding: '10px', marginBottom: '10px', borderRadius: 3 }}>
                                <div className="message-wrapper">
                                  <div className="row" style={{ marginBottom: '5px' }}>
                            <p style={{ fontSize: '18px', marginRight: '10px', marginLeft: '5px' }}>{d.userId}</p>
                                    {d.status === "Completed" ?
                                      <p style={{ backgroundColor: '#0aa699', paddingRight: '5px', paddingLeft: '5px', color: '#fff', fontSize: '11px' }}>{d.status}</p>
                                      : (d.status === "Pending" ?
                                        <p style={{ backgroundColor: '#E0A800', paddingRight: '5px', paddingLeft: '5px', color: '#fff', fontSize: '11px' }}>{d.status}</p> :
                                        <p style={{ backgroundColor: '#C82333', paddingRight: '5px', paddingLeft: '5px', color: '#fff', fontSize: '11px' }}>{d.status}</p>
                                      )}
                                  </div>
                                  <div className="description"><a href="#"> {d.email}</a> </div>
                                </div>
                                <div className="date pull-right" style={{ opacity: 1 }}>
                                  <span className="muted bold text-success" style={{ fontSize: '15px' }}>{d.amount} {d.coinName}</span>
                                  <br />
                                  {d.date}</div>
                              </div>
                            ))}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    );
  }
}
