import React from 'react'
import { API_URL } from '../../config/api';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row, Button, Spinner, Modal, ModalBody,ModalHeader, ModalFooter } from 'reactstrap';
import { useState } from 'react';
import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Chart from 'react-apexcharts'
import { toast, ToastContainer } from 'react-toastify';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const input = {
    fontSize: '13px',
    color: '#333',
    width: 300
}
const colors=['#43b582', '#3364e3', '#E39127','#C13d37','#E30627','#E3a363','#E35627']
function AdminProfitTracker() {
    let history = useHistory();
    const [trackerData, setTrackerData] = useState()
    const [coinName, setCoinName] = useState('ECNA');
    const [walletAddress, setWalletAddress] = useState('')
    const [amount, setAmount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [chartSeries, setChartSeries] = useState([])
    const [modal, setModal] = useState(false);
    const [tokenList, setTokenList] = useState([])
    const [chartOption, setChartOption] = useState({
        chart: {
            width: 360,
            type: 'pie',
        },
        legend: {
            show: false
        },
    labels: [],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
            show: false
        }
      }
    }]
  })

    useEffect(() => {
        // getData();
        getTokenList();
    }, [])
    
    useEffect(() => {
        if (trackerData) {
            getChartData()
        }
    }, [trackerData])
    
    useEffect(() => {
      if(coinName)
        getData()
    }, [coinName])
    
    const getData = () => {
        setLoading(true)
        axios.get(`${API_URL.ADMIN_PROFIT_TRACKER}?coinName=${coinName}`).then((res) => {
            if(res.data.message === 'success'){
            setTrackerData(res.data.data || [])
            setLoading(false)
           }
        }).catch(e =>{setLoading(false)});

      
    }
const getTokenList = ()=>{
    axios.get(API_URL.ERC20_TOKEN_LIST)
    .then(res => {setTokenList(res.data.data) })
    .catch(error => {
        console.log("Error: ", error);
    })
}
    const getChartData = ()=>{
        const data = []
            Object.keys(trackerData).forEach((k,i)=>{
                data.push({title: k, value: trackerData[k], color:colors[i]})
            })

            let co =chartOption;
            chartOption.labels = data.map(e => e.title)
                setChartSeries(data.map(e => e.value))
          setChartOption(co)
        }
        const withdrawProfitPopup=()=>{
            setModal(!modal);      
        }
        const withdrawProfit =()=>{
            setLoading(true)
            const payload={
                coinName,
                amount,
                walletAddress 
            }
            axios.post(`${API_URL.WITHDRAW_PROFIT}`,payload).then((res) => {
                if(res.data.message === 'success'){
                    toast.success('Withdrawal Submitted')
                setLoading(false)
                setModal(false)
               }else{
                setLoading(false)
                toast.error(res.data.error)
               }
            }).catch(e =>{setLoading(false)})
        }
        return (
            <div>
    <Header />
    <Menu />
    <ToastContainer />
    <div className="wrapper">
        <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Admin Profit Tracker</h1>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>
            </div>
            <section className="content relative">
                      {loading && <div className='spinner-div'>
                          <Spinner
                              color="warning"
                              type="grow"
                          >
                              Loading...
                          </Spinner>
                      </div>}
                <div className="container-fluid">
                    <div className="col-md-12">
                        <Row>
                            <Col >
                                <Card>
                                    <CardHeader>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <i className="fa fa-align-justify" style={{
                                                    marginRight: 10
                                                }}></i>
                                        </div>
                                            <div className="col-md-8">
                                                <div className='d-flex justify-content-between'>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            style={input}
                                                            onChange={e => {
                                                                setTrackerData(undefined)
                                                               setCoinName(e.target.value)
                                                            }}>
                                                            {tokenList.map((d) => (
                                                                d.active == true && <>
                                                                    <option selected={d.tokenSymbol === coinName} value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                    <Button color="primary" style={{ float: 'right' }}
                                                    onClick={(e) => {
                                                      withdrawProfitPopup()
                                                    }}>Withdraw Profit</Button></div>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-8">
                                              <div className="row">
                                                <div className="col-6">
                                                    <div className="card py-2 px-3">
                                                        <div className='card-label'>Admin Blockchain Balance </div>
                                                        <div className='card-value'>{trackerData?.adminBlockChainBalance || 0} <small>{coinName}</small></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card  py-2 px-3">
                                                        <div className='card-label'>Admin Balance </div>
                                                        <div className='card-value'>{trackerData?.adminBalance || 0} <small>{coinName}</small></div>
                                                    </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-6">
                                                    <div className="card  py-2 px-3">
                                                        <div className='card-label'>Admin Profit </div>
                                                        <div className='card-value'>{trackerData?.adminProfit || 0} <small>{coinName}</small></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card  py-2 px-3">
                                                        <div className='card-label'>All User's Balance </div>
                                                        <div className='card-value'>{trackerData?.usersBalance || 0} <small>{coinName}</small></div>
                                                    </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-6">
                                                    <div className="card  py-2 px-3">
                                                        <div className='card-label'>Admin Bought Coin </div>
                                                        <div className='card-value'>{trackerData?.adminBoughtCoins || 0} <small>{coinName}</small></div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="card  py-2 px-3">
                                                        <div className='card-label'>Admin Bought Coin <small>(in INR)</small> </div>
                                                        <div className='card-value'>{trackerData?.adminBoughtCoinsInrValue || 0} <small>INR</small></div>
                                                    </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-6">
                                                    <div className="card  py-2 px-3">
                                                        <div className='card-label'>Pending Transfer (To Admin) </div>
                                                        <div className='card-value'>{trackerData?.pendingToAdminTransfeerred || 0} <small>{coinName}</small></div>
                                                    </div>
                                                </div>
                                              
                                              </div>
                                            </div>
                                            <div className="col-4 " >

                                            {chartOption.labels.length ? <Chart  options={chartOption} series={chartSeries} type="pie" width={360} />: null}

                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                </div>
                    <Modal isOpen={modal} toggle={withdrawProfitPopup} >
                        <ModalHeader toggle={withdrawProfitPopup}>Modal title</ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="wAdd">Wallet Address</label>
                                    <input type="test" value={walletAddress} onChange={(e)=> setWalletAddress(e.target.value)} required className="form-control" id="wAdd" placeholder="Wallet Address" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="amountId">Amount</label>
                                    <input type="number" required value={amount} onChange={(e)=> setAmount(e.target.value)} className="form-control" id="amountId" placeholder="Enter Amount" />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={withdrawProfit}>
                                Withdraw
                            </Button>{' '}
                            <Button color="secondary" onClick={withdrawProfitPopup}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
            </section>
        </div>
    </div>
    {/* <Footer /> */}
</div>
  )
}

export default AdminProfitTracker