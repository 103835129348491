import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import { API_URL, BASE_URL, imageURL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class InrDepositTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depositFunds: [],
            loading: false
        }
    }

    UNSAFE_componentWillMount() {
        this.getDepositeHistory();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
    }
    getDepositeHistory = async () => {
        this.setState({ loading: true });
        await axios.post(BASE_URL+"depositINR/get/allByUser", {userId: this.props.userId})
            .then(res => {
                this.setState({
                    depositFunds: "",
                    loading: false
                })

                console.log("all users: ", res.data.data);
                let userDepositeData = res.data.data
                userDepositeData.map((d) => {
                    data.push(d)

                })
                this.setState({
                    depositFunds: data
                })
            })
            .catch(error => {
                console.log("Error: ", error);
                this.setState({loading: false})
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row.status);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        {row.status == "Completed" &&
                        <a href="#" className="btn btn-block btn-success btn-xs">{row.status}</a>
                        }
                        {row.status == "Pending" &&
                        <a href="#" className="btn btn-block btn-primary btn-xs">{row.status}</a>
                        }
                        {row.status == "Rejected" &&
                        <a href="#" className="btn btn-block btn-danger btn-xs">{row.status}</a>
                        }
                        {/* <a href="#" className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            {row.status}
                        </a> */}
                    </div>
                </div>
            </div>
        )
    }

    cellEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                <div class="col-md-12">
                    <a href="#" className="btn btn-block btn-primary btn-xs"
                        onClick={() => {
                            let data = JSON.stringify(row)
                            console.log("cellEdit",row);
                            localStorage.setItem('inrDepositEdit', data)
                            this.props.history.push("/inreditdeposit")
                        }}>
                        <i className="fa fa-edit " />
                    </a>
                </div>
            </div>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                <a href=""
                            onClick={() => {
                                let data = row.userId
                                console.log("emailData", row.userId);
                                localStorage.setItem('emailData', data)
                                this.props.history.push("/edituser")
                            }}>
                                <p style={{color: '#0069D9'}}>{row.userId}</p>
                        </a>
                </div>
            </div>
        )
    }

    details(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR"){
            return (
                <div style={{ flex: 1, justifyContent: 'left', textAlign: 'left', alignItems: 'left', width: '100%' }}>
                    <div class="col-md-12">
                            <p><strong>Bank Name:</strong> {row.bankname}</p>
                            <p><strong>Account Number:</strong> {row.acno}</p>
                            <p><strong>IFSC:</strong> {row.ifsc}</p>
                            <p><strong>Reference ID:</strong> {row.refid}</p>
                            <p><strong>Remark:</strong> {row.remark}</p>
                            <a href={`${imageURL}${row.receipt}`} target='_blank' style={{color:'blue'}}><strong>View Receipt</strong></a>                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p>{row.walletAddress}</p>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                {this.state.loading && (
              <div className="spinner-div">
                <Spinner color="warning" type="grow">
                  Loading...
                </Spinner>
              </div>
            )}
             <div style={{overflow: 'auto'}}>
             <BootstrapTable data={this.state.depositFunds} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center', minWidth: 1000 }}>
                                                            <TableHeaderColumn
                                                            isKey
                                                            dataField='button'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='coinName' width={'8%'} thStyle={headerStyle}>COIN NAME</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.details.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'27%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >BANK DETAILS</TableHeaderColumn>
                                                        {/* <TableHeaderColumn dataField='walletAddress' width={'24%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='amount' width={'11%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                        >STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE</TableHeaderColumn>

                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.cellEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'8%'}
                                                        >ACTION</TableHeaderColumn>


                                                    </BootstrapTable>
             </div>
            </div>
        );
    }
}
