import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaBtc, FaRegMoneyBillAlt, FaRupeeSign } from 'react-icons/fa'

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginData: {}
    }
  }

  componentDidMount() {
    let editData = localStorage.getItem('loginData', '')
    editData = JSON.parse(editData)

    console.log("Menu page: ", editData);
    if (editData.role?.toLowerCase() === "admin") {
      console.log("ifffffffffffff")

      this.setState({
        loginData: editData,
        userName: editData.userName,
        userManagemanet: editData.userManagemanet,
        list: editData.list,
        manage: editData.manage,
        activityLog: editData.activityLog,
        changePwd: editData.changePwd,
        transactions: editData.transactions,
        memberBalance: editData.memberBalance,
        erc20: editData.erc20,
        kycVerification: editData.kycVerification,
        deposit: editData.deposit,
        withdraw: editData.withdraw,
        INRlist: editData.INRlist,
        roleManager: true,
        createAdmin: true,
        adminList: true
      })
    } else if (editData.role === "kyc-admin") {
      this.setState({
        loginData: editData,
        // userName: editData.userName,
        userManagemanet: editData.userManagemanet,
        // list: editData.list,
        // manage: editData.manage,
        // activityLog: editData.activityLog,
        changePwd: editData.changePwd,
        // transactions: editData.transactions,
        // memberBalance: editData.memberBalance,
        // erc20: editData.erc20,
        kycVerification: editData.kycVerification,
        // deposit: editData.deposit,
        // withdraw: editData.withdraw,
        // INRlist : editData.INRlist,
        roleManager: false,
        createAdmin: false,
        adminList: false
      })

    }
    else {
      this.setState({
        loginData: editData,
        // userName: editData.userName,
        userManagemanet: editData.userManagemanet,
        // list: editData.list,
        // manage: editData.manage,
        // activityLog: editData.activityLog,
        changePwd: editData.changePwd,
        // transactions: editData.transactions,
        // memberBalance: editData.memberBalance,
        // erc20: editData.erc20,
        kycVerification: editData.kycVerification,
        // deposit: editData.deposit,
        // withdraw: editData.withdraw,
        // INRlist : editData.INRlist,
        createAdmin: false,
        adminList: false
      })
    }

  }

  render() {
    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <Link to="/dashboard" className="brand-link">
            <img src="images/logo.png" alt="AdminLTE Logo" style={{ width: '70%' }} />
            <span className="brand-text font-weight-light"></span>
          </Link>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img src="images/av.png" className="img-circle elevation-2" alt="User Image" />
              </div>
              <div className="info">
                <h4 style={{ color: '#fff' }}><b>Welcome</b></h4>
                <a href="fake_url" className="d-block">{this.state.userName}</a>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                {/* <li className="nav-item has-treeview menu-open"> */}
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <p>
                      Dashboard
                    </p>
                  </Link>
                </li>
                {this.state.userManagemanet === true ?
                  <li className="nav-item ">
                    <a href="" className="nav-link">
                      <i className="nav-icon fas fa-users" />
                      <p>
                        User Management
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {this.state.loginData.role === "Admin" && <li className="nav-item">
                        <Link to="/users" className="nav-link">
                          {/* <i className="far fa-circle nav-icon" /> */}
                          <p style={{ marginLeft: '30px' }}>Users</p>
                        </Link>
                      </li>}
                      {this.state.kycVerification === true &&
                        <li className="nav-item">
                          <Link to="/kyc" className="nav-link">
                            {/* <i className="far fa-circle nav-icon" /> */}
                            <p style={{ marginLeft: '30px' }}>KYC & Bank Details</p>
                          </Link>
                        </li>}
                      {this.state.createAdmin === true &&
                        <li className="nav-item">
                          <Link to="/createadmin" className="nav-link">
                            {/* <i className="far fa-circle nav-icon" /> */}
                            <p style={{ marginLeft: '30px' }}>Create Admin User</p>
                          </Link>
                        </li>}
                      {this.state.adminList === true &&
                        <li className="nav-item">
                          <Link to="/adminlist" className="nav-link">
                            {/* <i className="far fa-circle nav-icon" /> */}
                            <p style={{ marginLeft: '30px' }}>Admin List</p>
                          </Link>
                        </li>}
                    </ul>
                  </li>
                  : <></>}

                {this.state.list === true ?
                  <li className="nav-item ">
                    <a href="" className="nav-link">
                      <i className="nav-icon fas fa-list" />
                      <p>
                        List
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {this.state.deposit === true &&
                        <li className="nav-item">
                          <Link to="/depositefund" className="nav-link">
                            {/* <i className="far fa-circle nav-icon" /> */}
                            <p style={{ marginLeft: '30px' }}>Active Deposit Fund</p>
                          </Link>
                        </li>
                      }

                      {this.state.withdraw === true &&
                        <>
                          <li className="nav-item">
                            <Link to="/withdrawlist" className="nav-link">
                              {/* <i className="far fa-circle nav-icon" /> */}
                              <p style={{ marginLeft: '30px' }}>Withdraw List</p>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/pendingwithdraw" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Pending Withdraw</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/completedwithdraw" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Completed Withdraw</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/rejectedwithdraw" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Rejected Withdraw</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/tradeorders" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Trade Orders</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/directorders" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Direct Orders</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/ecnaBuyTnx" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Ecanna Buy Transactions</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/adminTnx" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Admin Transactions</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/admin-profit-tracker" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Admin Profit Tracker</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/admin-withdrawal" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Admin Withdrawal List</p>
                            </Link>
                          </li>
                          {/*  <li className="nav-item">
                      <Link to="/adminBuyTnx" className="nav-link">
                          <p style={{ marginLeft: '30px' }}>Admin Buy Transactions</p>
                        </Link>
                      </li> */}

                          <li className="nav-item">
                            <Link to="/pendingInrTrade" className="nav-link">
                              <p style={{ marginLeft: '30px' }}>Pending INR Orders</p>
                            </Link>
                          </li>
                        </>
                      }
                    </ul>
                  </li>
                  : <></>}
                <li className="nav-item ">
                  <a href="" className="nav-link">
                    <i className="nav-icon fas fa-chart-line" />
                    <p>
                      Margin Trade
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/margin-orders" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>Margin Orders</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/margin-feetx" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>Fee Transaction</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/margin-tdstx" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>TDS Transaction</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item ">
                  <a href="" className="nav-link">
                    <i className="nav-icon fas fa-user" />
                    <p>
                      P2P
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/p2pOrders" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>P2P Orders</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* {this.state.INRlist === true && */}
                <li className="nav-item ">
                  <a href="" className="nav-link">
                    <i className="nav-icon fas fa-list" />
                    <p>
                      INR List
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">

                    <li className="nav-item">
                      <Link to="/inrdepositefund" className="nav-link">
                        {/* <i className="far fa-circle nav-icon" /> */}
                        <p style={{ marginLeft: '30px' }}>INR Deposit Fund</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/inrwithdrawlist" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>INR Withdraw List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/inrpendingwithdraw" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>INR Pending Withdraw</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/inrcompletedwithdraw" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>INR Completed Withdraw</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/inrrejectedwithdraw" className="nav-link">
                        <p style={{ marginLeft: '30px' }}>INR Rejected Withdraw</p>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* } */}
                {this.state.list === true ?
                  <li className="nav-item ">
                    <a href="" className="nav-link">
                      <i className="nav-icon fas fa-server" />
                      <p>
                        Liquidity Mangement
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">



                      <>

                        <li className="nav-item">
                          <Link to="/binanceorder" className="nav-link">
                            <p style={{ marginLeft: '30px' }}>Binance order</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/binancetrade" className="nav-link">
                            <p style={{ marginLeft: '30px' }}>Binance Trade</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/binancewallet" className="nav-link">
                            <p style={{ marginLeft: '30px' }}>Binance Wallet</p>
                          </Link>
                        </li>
                      </>
                    </ul>
                  </li>
                  : <></>}
                {this.state.manage === true ?
                  <li className="nav-item ">
                    <a href="" className="nav-link">
                      <i className="nav-icon fas fa-server" />
                      <p>
                        Manage
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/news" className="nav-link">
                          {/* <i className="far fa-circle nav-icon" /> */}
                          <p style={{ marginLeft: '30px' }}>Announcements</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/sliderlist" className="nav-link">
                          {/* <i className="far fa-circle nav-icon" /> */}
                          <p style={{ marginLeft: '30px' }}>Slider</p>
                        </Link>
                      </li>

                      {/* <li className="nav-item">
                        <Link to="/feessubscription" className="nav-link">
                          <p style={{ marginLeft: '30px' }}>Fees Subscription Packs</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/feeshistory" className="nav-link">
                          <p style={{ marginLeft: '30px' }}>Fees History</p>
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link to="/blogs" className="nav-link">
                          <p style={{ marginLeft: '30px' }}>Blogs</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/add-banner" className="nav-link">
                          <p style={{ marginLeft: '30px' }}>Banner</p>
                        </Link>
                      </li>
                      {/* <li className="nav-item">
              <Link to="/faqs" className="nav-link">
                  <p style={{marginLeft: '30px'}}>FAQs</p>
                </Link>
              </li> */}
                    </ul>
                  </li>
                  : <></>}
                {this.state.activityLog === true ?
                  <li className="nav-item">
                    <Link to="/activitylog" className="nav-link">
                      <i className="nav-icon fas fa-eye" />
                      <p>
                        Activity Log
                      </p>
                    </Link>
                  </li>
                  : <></>}
                {this.state.changePwd === true ?
                  <li className="nav-item">
                    <Link to="/changepassword" className="nav-link">
                      <i className="nav-icon fas fa-key" />
                      <p>
                        Change Password
                      </p>
                    </Link>
                  </li>
                  : <></>}
                {this.state.changePwd === true ?
                  <li className="nav-item">
                    {/* <Link to="/bankaccount" className="nav-link">
                      <i className="nav-icon fas fa-credit-card" />
                      <p>
                        INR Bank Account
              </p>
                    </Link> */}
                  </li>
                  : <></>}
                {this.state.transactions === true ?
                  <li className="nav-item">
                    <Link to="/transaction" className="nav-link">
                      <i className="nav-icon fas fa-credit-card" />
                      <p>
                        Transactions
                      </p>
                    </Link>
                  </li>
                  : <></>}

                {this.state.memberBalance === true ?
                  <li className="nav-item">
                    <Link to="/memberbalance" className="nav-link">
                      {/* <i className="nav-icon fas fa-money" /> */}
                      <FaRegMoneyBillAlt width='2em' height='2em' style={{ width: '2em', height: '1.5em', marginRight: '5px' }} />
                      <p>
                        Member Balance
                      </p>
                    </Link>
                  </li>
                  : <></>}
                {this.state.memberBalance === true ?
                  <li className="nav-item">
                    <Link to="/adminWalletBalance" className="nav-link">
                      {/* <i className="nav-icon fas fa-money" /> */}
                      <FaRegMoneyBillAlt width='2em' height='2em' style={{ width: '2em', height: '1.5em', marginRight: '5px' }} />
                      <p>
                        Admin Wallet Balance
                      </p>
                    </Link>
                  </li>
                  : <></>}

                {this.state.erc20 === true ?
                  <li className="nav-item ">
                    <a href=" " className="nav-link">
                      {/* <i className="nav-icon fas fa-btc" /> */}
                      <FaBtc width='2em' height='2em' style={{ width: '2em', height: '1.5em' }} />
                      <p>
                        Coin
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview" >
                      <li className="nav-item" >
                        <Link to="/erctokenlist" className="nav-link">
                          <i className="far" />
                          <p style={{ marginLeft: '30px' }}>All List</p>
                        </Link>
                      </li>
                      <li className="nav-item" >
                        <Link to="/addtoken" className="nav-link">
                          {/* <i className="far fa-circle nav-icon" /> */}
                          <p style={{ marginLeft: '30px' }}>Add Coin</p>
                        </Link>
                      </li>
                      <>
                        <li className="nav-item">
                          <Link to="/addCoinPair" className="nav-link">
                            {/* <i className="nav-icon fas fa-money" /> */}
                            <p style={{ marginLeft: '30px' }}>
                              Spot Coin Pairs
                            </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/addMarginCoinPair" className="nav-link">
                            {/* <i className="nav-icon fas fa-money" /> */}
                            <p style={{ marginLeft: '30px' }}>
                              Margin Coin Pairs
                            </p>
                          </Link>
                        </li>
                      </>


                    </ul>
                  </li>
                  : <>
                  </>}

                {this.state.roleManager ?
                  <li className="nav-item ">
                    <a href="" className="nav-link">
                      <i className="nav-icon fas fa-server" />
                      <p>
                        Admin Roles
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="/roles" className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p style={{ marginLeft: '30px' }}>Roles</p>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/addRole" className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p style={{ marginLeft: '30px' }}>Add Role</p>
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link to="/editRole" className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p style={{ marginLeft: '30px' }}>Update Role</p>
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  : <></>}


                <li className="nav-item ">
                  <a href="" className="nav-link">
                    <FaRegMoneyBillAlt width='2em' height='2em' style={{ width: '2em', height: '1.5em', marginRight: '5px' }} />
                    <p>
                      Ecanna Price
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>




                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/priceHistory" className="nav-link">
                        <i className="fas fa-list nav-icon" />
                        <p style={{ marginLeft: '30px' }}>Price History</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/addPrice" className="nav-link">
                        <i className="fas fa-plus nav-icon" />
                        <p style={{ marginLeft: '30px' }}>Add Price</p>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link to="/binanceSetting" className="nav-link">
                    {/* <i className="nav-icon fas fa-bitcoin" /> */}
                    <FaBtc width='2em' height='2em' style={{ width: '2em', height: '1.5em' }} />
                    <p>
                      Binance Setting
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/inrCommission" className="nav-link">
                    {/* <i className="nav-icon fas fa-bitcoin" /> */}
                    <FaRupeeSign width='2em' height='2em' style={{ width: '2em', height: '1.5em' }} />
                    <p>
                      INR Commission
                    </p>
                  </Link>
                </li>
                <li style={{ marginBottom: 100 }}></li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>
      </div>

    );
  }
}
