import React, { Component } from 'react';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { keys } from '@material-ui/core/styles/createBreakpoints';

import {axiosInstance} from './../utils/interceptor';
const axios = axiosInstance;

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}


var projection = [];

export default class AdminWalletList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberBalance: {},
            allData : [],
            tokenValue: [],
            ercLoading: false
        }
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
        this.getAdminWalletBal();
    }
    


    getAdminWalletBal = async () => {
        await axios.post(API_URL.GET_ADMIN_WALLET_BALANCE   ,   {  "userId" : "6306747aacb97502506077cf"  })
            .then(res => {
                console.log("admin wallet bal", res.data.data);
                let data = res.data.data

                this.setState({
                    allData: res.data.data,
                    memberBalance : res.data.data
                    // ercLoading: true
                })

                console.log(this.state)
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }



    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Admin Wallet Balance</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  Admin Wallet Balance
                                                </CardHeader>
                                                <CardBody >
                                                    <div style={{ overflowX: 'scrollable' }}>
                                                       
                                                    <table>
                                                    <tr>
    <th>Coin Name</th>
    <th>Balance</th>
  </tr>
  <tr>
    <td>BTC</td>
    <td>{this.state.memberBalance.BTC}</td>
  </tr>

  <tr>
    <td>BNB</td>
    <td>{this.state.memberBalance.BNB}</td>
  </tr>
  <tr>
    <td>BCH</td>
    <td>{this.state.memberBalance.BCH}</td>
  </tr>
  <tr>
    <td>BTT</td>
    <td>{this.state.memberBalance.BTT}</td>
  </tr>
  <tr>
    <td>DASH</td>
    <td>{this.state.memberBalance.DASH}</td>
  </tr>
  <tr>
    <td>DOGE</td>
    <td>{this.state.memberBalance.DOGE}</td>
  </tr>
  <tr>
    <td>ECNA</td>
    <td>{this.state.memberBalance.ECNA}</td>
  </tr>
  <tr>
    <td>ENJ</td>
    <td>{this.state.memberBalance.ENJ}</td>
  </tr>
  <tr>
    <td>ETC</td>
    <td>{this.state.memberBalance.ETC}</td>
  </tr>
  <tr>
    <td>ETH</td>
    <td>{this.state.memberBalance.ETH}</td>
  </tr>
  <tr>
    <td>INR</td>
    <td>{this.state.memberBalance.INR}</td>
  </tr>
  <tr>
    <td>LINK</td>
    <td>{this.state.memberBalance.LINK}</td>
  </tr>
  <tr>
    <td>LTC</td>
    <td>{this.state.memberBalance.LTC}</td>
  </tr>
  <tr>
    <td>MATIC</td>
    <td>{this.state.memberBalance.MATIC}</td>
  </tr>
  <tr>
    <td>SHIB</td>
    <td>{this.state.memberBalance.SHIB}</td>
  </tr>
  <tr>
    <td>TRX</td>
    <td>{this.state.memberBalance.TRX}</td>
  </tr>
  <tr>
    <td>USDT</td>
    <td>{this.state.memberBalance.USDT}</td>
  </tr>
  <tr>
    <td>XLM</td>
    <td>{this.state.memberBalance.XLM}</td>
  </tr>
  <tr>
    <td>XRP</td>
    <td>{this.state.memberBalance.XRP}</td>
  </tr>


</table>

                                                       
                                                    </div>

                                                   
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
