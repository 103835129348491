import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap';
import { API_URL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { FaCheck } from 'react-icons/fa';
import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class DepositTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depositFunds: [],
            transferdone: false,
            loading: false
        }
    }

    UNSAFE_componentWillMount() {
        this.getDepositeHistory();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
    }
    getDepositeHistory = async () => {
        this.setState({loading: true})
        await axios.post(API_URL.DEPOSITE_HISTORY,{userId: this.props.userId})
            .then(res => {
                this.setState({
                    depositFunds: [],
                    loading: false
                })
                let userDepositeData = res.data.data
                userDepositeData.map((d) => {
                    if(d.status == "Completed"){
                        data.push(d)
                    }
                })
                console.log(data)
                this.setState({
                    depositFunds: userDepositeData
                })
            })
            .catch(error => {
                console.log("Error: ", error);
                this.setState({loading: false})
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            Completed
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    async transferTrx(id,coinname,walletaddress) {
        console.log("transferTrx",id,coinname,walletaddress);
        await axios.post(API_URL.TRNSFER_DEPOSIT_FUNDS, {
            "id": id
        })

            .then(async res => {
                console.log(res);
                if(res.data.message == "sentSuccessfuly"){
                    swal("CoinSentSuccessfuly", res.data.data, "success");

                }

                else if(res.data.message == "CoinAlreadyTransferred"){
                    swal("CoinAlreadyTransferred", "", "error");

                }

                else if(res.data.message == "CoinTransferMethodNotFound"){
                    swal("CoinTransferMethodNotFound", "", "error");

                }

            })
    }

    async transferGas(id,coinname,walletaddress) {
        console.log("transferTrx",id);
        await axios.get("http://35.154.143.36/ethgas.php?wallet="+walletaddress)
            .then(res => {
                


            })

    }
    transfer(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);

        var tokenTransfer = true;
        if(row.coinName == "BTC") {
            tokenTransfer = false; 
        }
        if(row.coinName == "XLM") {
            tokenTransfer = false; 
        }
        if(row.coinName == "ETH") {
            tokenTransfer = false; 
        }
        if(row.coinName == "TRX") {
            tokenTransfer = false; 
        }
        if(row.coinName == "BCH") {
            tokenTransfer = false; 
        }
        if(row.coinName == "ETC") {
            tokenTransfer = false; 
        }
        if(row.coinName == "XRP") {
            tokenTransfer = false; 
        }
        if(row.coinName == "LTC") {
            tokenTransfer = false; 
        }
        if(row.coinName == "DASH") {
            tokenTransfer = false; 
        }

        if(row.walletAddress == "signupbonus") {
            tokenTransfer = false; 
        }

        if(row.walletAddress == "refBonus") {
            tokenTransfer = false; 
        }

        if(row.walletAddress == "kycbonus") {
            tokenTransfer = false; 
        }
        
        return (
            <>
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '85%'}}>
               {!row.transferedToAdmin ? <div className="row">
                    <div class="col-md-12">
                        {!row.transferred &&
                        <>
                        {row.coinName == "TRX" &&
                        <a href="#" onClick={() => this.transferTrx(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            Transfer {row.coinName}
                        </a>
                        }
                        </>
                        }
                {!row.transferred &&
                        <>
                        {row.coinName == "ETH" &&
                        <a href="#" onClick={() => this.transferTrx(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            Transfer {row.coinName}
                        </a>
                        }
                        </>
    }

                        {tokenTransfer == true &&
                        <><a href="#" onClick={() => this.transferGas(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                        // onClick={() => {
                        //     console.log("Cell value: ", row)
                        //     this.deleteUser(row)
                        // }}
                        >
                        1. Send GAS
                        </a>
                        <a href="#" onClick={() => this.transferTrx(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            2. Transfer {row.coinName}
                        </a></> 
                        }
                    </div>
                </div>: 
                <div>
                    <FaCheck style={{color: 'green'}}/>
                </div>
                }
            </div>
            </>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                <a href=""
                            onClick={() => {
                                let data = row.userId
                                console.log("Withdraw", row.userId);
                                localStorage.setItem('emailData', data)
                                this.props.history.push("/edituser")
                            }}>
                                <p style={{color: '#0069D9'}}>{row.userId}</p>
                        </a>
                </div>
            </div>
        )
    }

    details(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR"){
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p><strong>Bank Name:</strong> {row.bankName}</p>
                            <p><strong>Account Type:</strong> {row.accountType}</p>
                            <p><strong>Account Number:</strong> {row.accountNumber}</p>
                            <p><strong>IFSC:</strong> {row.ifsc}</p>
                            <p><strong>Remark:</strong> {row.remark}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p>{row.walletAddress}</p>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                  {this.state.loading && (
              <div className="spinner-div">
                <Spinner color="warning" type="grow">
                  Loading...
                </Spinner>
              </div>
            )}
            <div style={{overflow:'auto'}}>
               <BootstrapTable data={this.state.depositFunds} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center', minWidth:1200 }}>
                                                            <TableHeaderColumn
                                                            isKey
                                                            dataField='button'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='coinName' width={'8%'} thStyle={headerStyle}>COIN NAME</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.details.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'35%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >WALLET ADDRESS / DETAILS</TableHeaderColumn>
                                                      
                                                        <TableHeaderColumn dataField='amount' width={'8%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'  width={'15%'}
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                        >STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE</TableHeaderColumn>

                                                        
                                                        <TableHeaderColumn
                                                            dataField='button' width={'15%'}
                                                            dataFormat={this.transfer.bind(this)}
                                                            thStyle={headerStyle}
                                                        >TRANSFER</TableHeaderColumn>


                                                    </BootstrapTable>
            </div>
            </div>
        );
    }
}
