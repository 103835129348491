import React, { Component } from 'react';
import { CardBody} from 'reactstrap';
import { API_URL, BASE_URL } from "../../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import Pagination from "react-js-pagination";
import { Label } from "reactstrap";
import './styleTable.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

var phoneNumber = '';
var email = '';
var kyc = ''
var pageAll = 1;
var pageApproved = 1;
var pagePending = 1;
var pageRejected = 1;
const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class HypervergeKYC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            load: 0,
            count: 0,
            searchField: '',
            searchBy: '',
            allKYC : [],
            approvedKYC : [],
            pendingKYC : [],
            rejectedKYC : [],
            activePageAll: 1,
            activePageApproved:1,
            activePagePending : 1,
            activePageRejected : 1,
            selectedTabIndex:0
        }
    }

    async UNSAFE_componentWillMount() {
        await axios.get(API_URL.KYC_COUNT)
            .then(res => {
                this.setState({ count: res.data.data })
                console.log("COunt:", res.data)
            })
            .catch(error => {
                console.log("Error: ", error);
            })
       
    }

    async componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
        this.usersList();
    }

    usersList = async () => {
        // console.log("Active Page: ", page)

         // For All KYC List
         await axios.post(BASE_URL+"kyc/get/allHVbystatus", {
            "pageNumber": pageAll,
            "searchBy": this.state.searchBy,
            "searchField": this.state.searchField,
            "status" : this.state.selectedTabIndex
        })

            .then(async(res) => {
                let userData = res.data.data.filter((d) => {
                    return d !== null
                })
                // console.log("Userdata: ", userData);
                let binddata = []
                await axios.post(BASE_URL+'userInformation/get/allnew').then((resUser)=>{
                    const userList = (resUser?.data?.data) || [];

                        userData.map(async (d, xid) => {
                            let internalbinddata = []
                            const user = userList.find((u)=> u._id === d.userId)
                                    if (!user) {
                                        phoneNumber = ''
                                        email = ''
                                        kyc = ''
                                    } else {
                                        phoneNumber = user.phoneNumber
                                        email = user.emailId
                                        kyc = user.kyc
                                    }
                                    console.log(res)
                                    var time = moment((d.timestamp)).format("DD-MM-YYYY h:mm:ss a");
                                    internalbinddata["email"] = email
                                    internalbinddata["phoneNumber"] = d.mobile?d.mobile: phoneNumber
                                    internalbinddata["kyc"] = kyc
                                    internalbinddata["status"] = d.status
                                    internalbinddata["fullname"] = d.fullName
                                    internalbinddata["country"] = d.country
                                    internalbinddata["dateofbirth"] = d.dateofbirth
                                    internalbinddata["acno"] = d.acno
                                    internalbinddata["ifsc"] = d.ifsc
                                    internalbinddata["address"] = d.address
                                    internalbinddata["zipcode"] = d.zipcode
                                    internalbinddata["doctype"] = d.doctype
                                    internalbinddata["rejectionReason"] = d.rejectionReason
                                    internalbinddata["docidno"] = d.docidno
                                    internalbinddata["frontImage"] = d.frontImage
                                    internalbinddata["backImage"] = d.backImage
                                    internalbinddata["bankname"] = d.bankname
                                    internalbinddata["passbook"] = d.passbook
                                    internalbinddata["selfie"] = d.selfieImage
                                    internalbinddata["ifsc"] = d.ifsc
                                    internalbinddata["userId"] = d.userId
                                    internalbinddata["_id"] = d._id
                                    binddata[xid] = internalbinddata
    
                                    d["email"] = email
                                    d["phoneNumber"] =  d.mobile?d.mobile: phoneNumber
                                    d["kyc"] = kyc

                                    this.setState({
                                        activity: binddata,
                                        load: 1,
                                        allKYC : binddata
                                    })
                                
                        })
                }).catch(error => {
                    console.log("Error: ", error);
                })
                
            })
            .catch(error => {
                console.log("Error: ", error);
            })




    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div class="row">
                    <div class="col-md-6 text-center">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     let data = Object.assign({}, row)
                            //     data = JSON.stringify(data)
                            //     console.log("Row:", data);
                            //     localStorage.setItem('kycEdit', data)
                            //     this.props.history.push("/editKyc")
                            // }}
                            >
                            <i className="fa fa-eye " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    kycButton(cell, row, enumObject, rowIndex) {
        const btnClass = (row.status == "auto_approved" || row.status == "manually_approved") ? "btn-success" : (row.status == "auto_declined" || row.status == "manually_declined") ? "btn-danger" : (row.status == "needs_review") ? "btn-warning" :"btn-primary"
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className={`btn btn-block btn-xs  ${btnClass}` }
                            >
                                {row.status}
                            </p>
                        </div>
                    </div>
                </div>
            )
    }

    handlePageChange_All(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePageAll: pageNumber });
        pageAll = pageNumber
        this.usersList();
    }
    onTabChange =(index)=>{
        this.setState({
            selectedTabIndex:index,
            load: 0
        }, ()=>{
            this.usersList();
        })
    }

    render() {
        console.log("Activity log data:", this.state.activity)
        console.log("Active age render: ", this.state.activePage);

        return (
            <Tabs onSelect={(index, lastIndex)=>this.onTabChange(index)}>
            <TabList style={{
                backgroundColor: '#e1e5e6',
                borderBottom: 0, color: '#777', height: '38px'
            }}>
                <Tab>All</Tab>
                <Tab>Approved</Tab>
                <Tab>Pending</Tab>
                <Tab>Declined</Tab>
            </TabList>

            {/* For ALL KYC  */}
            {/* <TabPanel> */}
            <CardBody>
            {this.state.load === 1 ?
                    <>
                     <form onSubmit={()=>this.usersList()}>
                         <div className="row" >
                        <div className="col-md-6">
                            <div className="row">
                            <div className = "col-md-2.5">
                            <Label>Search By: </Label>
                            </div>
                            <div className="col-md-8">
                            <select
                                className="form-control editor edit-text"
                                value={this.state.searchBy}
                                style={{width: '100%', height: '100%', fontSize:'13px'}}
                                onChange={(e) => {
                                    this.setState({
                                        searchBy: e.target.value
                                    })
                                }}>
                                <option value="">-- select --</option>
                                <option value="email">Email</option>
                                <option value="fullname">Full Name</option>
                                <option value="address">Zip Code</option>
                                <option value="status">Status</option>
                            </select>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <input value={this.state.searchField} onChange={(e) => {
                                this.setState({ searchField: e.target.value })
                            }} placeholder="search"
                                style={{ width: '60%', fontSize: '13px',marginRight: '20px', marginBottom: '10px', height: '70%', marginLeft: '-80px' }} />
                            <button type='button' className="btn btn-primary" onClick={()=>this.usersList()}>Submit</button>
                        </div>
                    </div>
                    </form>

                    <BootstrapTable data={this.state.allKYC}
                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                        <TableHeaderColumn dataField='userId' width={'15%'} thStyle={headerStyle} isKey>HyperVerge Tx-id</TableHeaderColumn>
                        <TableHeaderColumn dataField='fullname' width={'15%'} thStyle={headerStyle} >FULL NAME</TableHeaderColumn>
                        <TableHeaderColumn dataField='email' width={'20%'} thStyle={headerStyle}>EMAIL</TableHeaderColumn>
                        <TableHeaderColumn dataField='phoneNumber' thStyle={headerStyle}>PHONE NUMBER</TableHeaderColumn>
                        <TableHeaderColumn dataField='country' dataFormat={(cell,row)=> (<div>India</div>)} thStyle={headerStyle}>COUNTRY</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='button'
                            width={'15%'}
                            tdStyle={{ whiteSpace: 'normal' }}
                            dataFormat={this.kycButton.bind(this)}
                            thStyle={headerStyle}
                        >KYC</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='button'
                            width={'15%'}
                            tdStyle={{ whiteSpace: 'normal' }}
                            dataFormat={this.buttonFormatter.bind(this)}
                            thStyle={headerStyle}
                        >ACTION</TableHeaderColumn>
                    </BootstrapTable>

                    <div style={{ marginTop: '20px', float: 'right' }}>
                        <Pagination
                            activePage={this.state.activePageAll}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.count}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange_All.bind(this )}
                        />
                    </div>
                </>
                :
                <div className="text-center">
                    <Loader
                        type="Puff"
                        color="#343A40"
                        height={50}
                        width={50}
                    // timeout={10000}
                    />
                </div>
            }
        </CardBody>
            {/* </TabPanel> */}
        </Tabs>
        );
    }
}
