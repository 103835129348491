import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row  , Button , Input} from 'reactstrap'; 
import { API_URL } from "../../config/api";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar'
import { toast, ToastContainer } from 'react-toastify';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

  const input = {
    fontSize: '13px',
    color: '#333'
}
 
 class TradeOrdersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            allChecked:false,
            selectedRows:[],
            primaryCoin : '',
            secondaryCoin : '',
            tokenValue : []
            // showSnackbar:false,
            // snackbarText:''
        }
    }
    // const { openSnackbar, closeSnackbar } = this.props
static getThis (){
    return this;
}

async componentDidMount() {
    let login = localStorage.getItem('adminLogin', '')

        await axios.get(API_URL.ERC20_TOKEN_LIST)
            .then(res => {
                console.log("ERC20 token:", res.data.data);
                let data = res.data.data
                this.setState({ 
                    tokenValue: res.data.data,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }



    UNSAFE_componentWillMount() {
    
    }

   



    Search = async() => {
        if(this.state.primaryCoin != this.state.secondaryCoin ){
        this.setState({ 
            loader: 0
        })
        let data = {
            "coinName" :  this.state.primaryCoin,
            "pair" : this.state.secondaryCoin,
        }
        await axios.post(API_URL.TRADE_ORDERS,data)
        .then(res => {
            console.log("add coin pair response:", res.data);
            if(res.data.statusCode == 200){
                let userData = res.data.data;
                let d = res.data.data
                
                this.setState({
                    orderList: d
                })
            }else{
                toast.error(res.data.message)
            }
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    } else{
            toast.warning("Primary & Secondary Coins are same")
    }
    }



    handleAllCheck = (isChecked)=>{
        
    }
    cancelSingleOrder = async(data) => {
        console.log(data)
        const postData = {
            id: data._id,
            coinName: data.coinName,
            pair: data.pair,
            orderType: data.orderType,
            binanceorderId:data.binanceorderId,
            status: 5,
        }
        if (data.coinName !== 'INR') {
            await axios.post(API_URL.CANCLE_TRADE_ORDERS,postData)
        .then(res => {
            if (res.data.statusCode == 200) {
                this.props.openSnackbar('Order Cancelled')
                this.getTradeOrders();
            }
        })
        }
    }
    // handleSnackbar=()=>{
    //     this.setState({showSnackbar:false})
    // }
    cancelButtonFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12 d-flex align-items-center">
                        <a href="javascript:void" className="btn btn-block btn-warning btn-xs"
                            onClick={() => {
                                // console.log("Cell value: ", row)
                                this.cancelSingleOrder(row)
                            }}
                            >
                            Cancel
                        </a>
                        {/* <input type='checkbox' onChange={(e)=>{}}/> */}
                    </div>
                </div>
            </div>
        )
    }

    cellEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div >
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            onClick={() => {
                                // let data = row._id
                                // console.log(row);
                                // localStorage.setItem('admineditId', data)
                                // this.props.history.push("/editUsers")
                            }}>
                            <i className="fa fa-edit " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                <a href="javascript:void"
                            onClick={() => {
                                // let data = row.userId
                                // console.log("Withdraw", row.userId);
                                // localStorage.setItem('emailData', data)
                                // this.props.history.push("/edituser")
                            }}>
                                <p style={{color: '#0069D9'}}>{row._id}</p>
                        </a>
                </div>
            </div>
        )
    }

    details(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR"){
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p><strong>Bank Name:</strong> {row.bankName}</p>
                            <p><strong>Account Type:</strong> {row.accountType}</p>
                            <p><strong>Account Number:</strong> {row.accountNumber}</p>
                            <p><strong>IFSC:</strong> {row.ifsc}</p>
                            <p><strong>Remark:</strong> {row.remark}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p>{row.walletAddress}</p>
                    </div>
                </div>
            )
        }
    }
  

      handleRowSelect =(row, isSelected) =>{
          console.log(row);
        let preList = this.state.selectedRows;
        console.log(preList);
        if (isSelected ) {
            if (row.coinName !== 'INR') {
                preList.push(
                    {
                        id:row._id,
                        coinName: row.coinName,
                        pair:row.pair,
                        binanceorderId:row.binanceorderId
                    } 
                )
            }
        }else{
            preList = preList.filter(x => x.id !== row._id)
        }
        this.setState({selectedRows:preList})
      }
      handleSelectAll=(isSelected, rows)=> {
          const tempArr=[]
          if (isSelected) {         
              rows.forEach((e)=>{
                  if (e.coinName !== 'INR') {     
                      tempArr.push({
                          id:e._id,
                          coinName: e.coinName,
                          pair:e.pair,
                          binanceorderId:e.binanceorderId
                      })
                  }
              })
              this.setState({selectedRows:tempArr})
          }
       }
       cancelAllHandler= async(list)=>{
           console.log('first',list);
        await axios.post(API_URL.CANCLE_ALL_TRADE_ORDERS,this.state.selectedRows)
        .then(res => {
            if (res.data.statusCode === 200) {
                this.props.openSnackbar('All order cancelled')
            }
        })
       }
       selectRow = {
        mode: 'checkbox', // or checkbox
        columnWidth: '20px',
        clickToSelect: false,
        onSelect: this.handleRowSelect,
        onSelectAll: this.handleSelectAll
      };
    render() {
    
        return (
            <div>
                <Header />
                <ToastContainer />
                <Menu />
                {/* <MySnackbar open={this.state.showSnackbar} message={this.state.snackbarText} onClose={this.handleSnackbar} /> */}
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Trade Orders List</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                                <div className='row'>
                                <div className="col-md-12" style={{ marginLeft: '10px', paddingTop: '10px' }}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p style={{ color: "#777" }}>Primary Coins</p>
                                    </div>
                                    <h1></h1>
                                    <div className="col-md-4" >
                                        <p style={{ color: "#777" }}>Secondary Coin</p>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginLeft: '8px' }}>
                                <div className="row">
                                     
                                <div className="col-md-4">
                                        {/* <Input placeholder="coin" /> */}
                                        <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    primaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Primary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}

                                        </select>
                                    </div>

                                    
                                    <div className="col-md-4">
                                    <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    secondaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Secondary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}
                                        </select>
                                    </div>

                                   
                                    <div className="col-md-2">
                                    <Button color="primary" onClick={this.Search}>Search</Button>
                                    </div>

                                </div>
                            </div>
                                </div>
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i> Trade Orders List
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.orderList} pagination search selectRow={ this.selectRow }
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}  >
                                                        <TableHeaderColumn
                                                             isKey={ true }
                                                            dataField='_id'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'} 
                                                        >ORDER ID</TableHeaderColumn>
                                                        {/* <TableHeaderColumn isKey dataField='email' width={'20%'} thStyle={headerStyle} tdStyle={{color: '#3459ed'}}>USER EMAIL</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN NAME</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='orderMode' width={'10%'} thStyle={headerStyle}>ORDER TYPE</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='orderType'
                                                            thStyle={headerStyle}
                                                            width={'10%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >SIDE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width={'8%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                        {/* <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'13%'}
                                                        >STATUS</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='dateTime' width={'24%'} thStyle={headerStyle}>DATE</TableHeaderColumn>
                                                        <TableHeaderColumn dataField=''  dataFormat={this.cancelButtonFormatter.bind(this)} width={'14%'} thStyle={headerStyle}>
                                                            <div>
                                                                <button className='btn btn-danger' onClick={()=>{this.cancelAllHandler(this.state.selectedRows)}}>Cancel All</button>
                                                                {/* <input type='checkbox' checked={} onChange={(e)=>{}}/> */}
                                                            </div>
                                                        </TableHeaderColumn>
                                                       
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
export default withSnackbar(TradeOrdersList)