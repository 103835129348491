import React from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Row, Button, Spinner} from 'reactstrap';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import WithdrawTransactions from "./withdrawalTransactions";
import {useLocation} from 'react-router-dom';
import DepositTransactions from "./depositTransactions";
import TradeTransactions from "./tradeTransactions";
import InrDepositTransactions from "./inrDepositTransactions";

function Transactions() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const userId = queryParams.get('userId');
  return (
    <div>
      <Header />
      <Menu />
      <ToastContainer />
      <div className="wrapper">
        <div
          className="content-wrapper"
          style={{ marginTop: "0px", backgroundColor: "#E5E9EC" }}
        >
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Transactions</h1>
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
          </div>
          <section className="content relative">
            <div className="container-fluid">
              <div className="col-md-12">
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <div className="row"></div>
                      </CardHeader>
                      <CardBody>
                        <Tabs>
                          <TabList
                            style={{
                              backgroundColor: "#D1DADE",
                              borderBottom: 0,
                              color: "#777",
                              height: "38px",
                            }}
                          >
                            <Tab>Deposit</Tab>
                            <Tab>Withdrawal</Tab>
                            <Tab>Trade Orders</Tab>
                            <Tab>INR Deposit</Tab>
                          </TabList>
                          <TabPanel style={{ padding: "20px" }}>
                            <DepositTransactions userId={userId} />
                          </TabPanel>
                          <TabPanel style={{ padding: "20px" }}>
                            <WithdrawTransactions userId={userId} />
                          </TabPanel>
                          <TabPanel style={{ padding: "20px" }}>
                            <TradeTransactions userId={userId}/>
                          </TabPanel>
                          <TabPanel style={{ padding: "20px" }}>
                            <InrDepositTransactions userId={userId} />
                          </TabPanel>
                        </Tabs>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
