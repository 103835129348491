import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { BASE_URL } from '../../config/api';
import { Link } from 'react-router-dom';

import {axiosInstance} from './../../utils/interceptor';
const axios = axiosInstance;

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class EcannaPriceHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            priceList: []
        }
    }

    componentDidMount() {
        this.getPriceList();
    }

    getPriceList = async () => {
        await axios.get(`${BASE_URL}ecannarate/getall`)
            .then(res => {
                let data = res.data.data
                this.setState({
                    priceList: data
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    deletePrice(row) {
        let userId = row._id
        let status = row.status
        console.log("Status: ", status);

        axios.post(`http://3.7.112.1:2087/slider/update`, {
            "id": userId,
            "status": "Inactive"
        })
            .then(res => {
                this.setState({
                    priceList: res.data.data
                })
                swal("Deleted Successfully", "", "success");
            })
            .catch(error => {
                console.log(error)
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div class="row">
                    <div class="col-md-3">
                        <Link to={'editPrice?id='+row._id}>
                            <i className="fa fa-edit" />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    tokenImage(cell, row, enumObject, rowIndex) {
        console.log("Path: ", row)
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div class="row">
                    <div class="col-md-12">
                    <img src={row.sliderImagePath} width="20px" height="20px"/>
                    </div>
                    {/* <div class="col-md-6">
                        <p>{row.tokenName}</p>
                    </div> */}
                </div>
            </div>
        )
    }

    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }
    statusFormatter(cell, row, enumObject, rowIndex){
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div>{row.isDeleted ? 'Inactive': 'Active'}</div>
            </div>
        )
    }
    render() {
        // console.log("all users: ", this.state.priceList);
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Ecanna Price History</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <i className="fa fa-align-justify" style={{
                                                                marginRight: 10
                                                            }}></i>
                                                    </div>
                                                        <div className="col-md-6">
                                                            <Button color="primary" style={{ float: 'right' }}
                                                                onClick={(e) => {
                                                                    this.props.history.push('/addPrice')
                                                                }}>Add Price</Button>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>

                                                <BootstrapTable data={this.state.priceList} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn dataField="any" dataFormat={this.indexN} isKey thStyle={headerStyle} width={'5%'}>No.</TableHeaderColumn>
                                                        <TableHeaderColumn 
                                                            dataField='price' 
                                                            width={'50%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Price($)</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='startDate'  thStyle={headerStyle}  >Start Date</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='endDate'  thStyle={headerStyle}  >End Date</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable>

                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
