import React, { Component } from 'react';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import Menu from '../Component/Menu';
import { API_URL } from "../config/api";
import swal from 'sweetalert';
import {
    Row,
    Card,
    CardBody,  
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";

import {axiosInstance} from './../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class ChangePassword extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            upivpa: '',
            acname: '',
            acnumber: '',
            ifsc: '',
            bankname: '',
            actype: '',
            getacdetails: [],
            id: ''
        }
    }

    async componentDidMount() {
        let editData = localStorage.getItem('loginData', '')
        editData = JSON.parse(editData)
        console.log("On edit page: ", editData._id);
        this.setState({
            id: editData._id
        })

        await axios.post(API_URL.GETINRBANK)
            .then(res => {
                if(res.data?.data?.length){
                this.setState({getacdetails: res.data})
                console.log("Balance:", this.state.getacdetails.data[0]);
                this.setState({
                    upivpa: this.state.getacdetails.data[0].upivpa,
                    acname: this.state.getacdetails.data[0].acname,
                    acnumber: this.state.getacdetails.data[0].acnumber,
                    ifsc: this.state.getacdetails.data[0].ifsc,
                    bankname: this.state.getacdetails.data[0].bankname,
                    actype: this.state.getacdetails.data[0].actype,
                    _id: this.state.getacdetails.data[0]._id,
                })
            }
            })

    }

    updateStatus = async () => {
        let change = {
            "upivpa": this.state.upivpa,
            "acname": this.state.acname,
            "acnumber": this.state.acnumber,
            "ifsc": this.state.ifsc,
            "bankname": this.state.bankname,
            "actype": this.state.actype,
            "_id" :this.state._id
        }
        console.log(change);

        await axios.post(API_URL.INRBANK, change)
            .then(res => {
                console.log("Balance:", res.data);
                if (res.data.statusCode === 200) {
                    swal("Updated Successfully", "", "success");
                } else {
                    swal("Error", "", "error");
                }
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    cancleChange = () => {
        this.setState({
            upivpa: '',
            acname: '',
            acnumber: '',
            ifsc: '',
            bankname: '',
            actype: '',
        })
    }
    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">  
                                        <h1 className="m-0 text-dark">INR Bank Account</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>UPI VPA</Label>
                                                        <Input
                                                            type="text"
                                                            name="upivpa"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.upivpa}
                                                            onChange={e => {
                                                                this.setState({
                                                                    upivpa: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Account Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="acname"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.acname}
                                                            onChange={e => {
                                                                this.setState({
                                                                    acname: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Account Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="acnumber"
                                                            id="exampleEmail"
                                                            value={this.state.acnumber}
                                                            style={input}
                                                            onChange={e => {
                                                                this.setState({
                                                                    acnumber: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

<div class="col-md-4">
    <FormGroup>
        <Label for="exampleEmail" style={label}>IFSC Code</Label>
        <Input
            type="text"
            name="ifsc"
            id="exampleEmail"
            value={this.state.ifsc}
            style={input}
            onChange={e => {
                this.setState({
                    ifsc: e.target.value
                })
            }}
        />
    </FormGroup>
</div>

<div class="col-md-4">
    <FormGroup>
        <Label for="exampleEmail" style={label}>Bank Name</Label>
        <Input
            type="text"
            name="bankname"
            id="exampleEmail"
            value={this.state.bankname}
            style={input}
            onChange={e => {
                this.setState({
                    bankname: e.target.value
                })
            }}
        />
    </FormGroup>
</div>

<div class="col-md-4">
    <FormGroup>
        <Label for="exampleEmail" style={label}>Account Type</Label>
        <Input
            type="text"
            name="actype"
            id="exampleEmail"
            value={this.state.actype}
            style={input}
            onChange={e => {
                this.setState({
                    actype: e.target.value
                })
            }}
        />
    </FormGroup>
</div>

                                                <Button
                                                    onClick={this.updateStatus}
                                                    style={{
                                                        marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                        marginLeft: '10px'
                                                    }}>Update</Button>
                                                <Button color="secondary"
                                                    onClick={this.cancleChange}>Cancel</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
