import React, { Component } from 'react';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { Card, CardBody, Col, Row, Input, Button } from 'reactstrap';
import { API_URL } from "../config/api";
import 'react-tabs/style/react-tabs.css'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {axiosInstance} from './../utils/interceptor';
const axios = axiosInstance;

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class AddMarginCoinPairs extends Component {
    constructor(props){
        super(props);
        this.state = {
            tokenValue: [],
            allCoinPairsList : [],
            // buyData: [],
            // sellData: [],
            // coinName: 'ETH',
            // pair: 'USDT',
            loader : 1,

            primaryCoin : '',
            secondaryCoin : '',
            price : '',
            editPrice : "",
            editCoinId : "",
            borrowLimit : '',
            interestPerHrs : '',
            addUI : true,
            updateUI : false
        }
    }

   async componentDidMount() {
    let login = localStorage.getItem('adminLogin', '')
    console.log(login);
    if (login !== '1') {
      this.props.history.push('/');
    }
        await axios.get(API_URL.ERC20_TOKEN_LIST)
            .then(res => {
                console.log("ERC20 token:", res.data.data);
                let data = res.data.data
                this.setState({ 
                    tokenValue: res.data.data,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
        this.getAllCoinPairs();
    }

    getAllCoinPairs = async () => {
        await axios.get(API_URL.GETALL_MARGIN_COIN_PAIRS)
        .then(res => {
            console.log("GETALL_COIN_PAIRS:", res.data.data);
            let data = res.data.data
            this.setState({ 
                allCoinPairsList: res.data.data,
            })
            console.log(this.state)
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    }

    addCoinPairs = async() => {
        if(this.state.primaryCoin != this.state.secondaryCoin ){
        this.setState({ 
            loader: 0
        })
        let data = {
            "primaryCoin" :  this.state.primaryCoin,
            "secondaryCoin" : this.state.secondaryCoin,
            "price" : Number(this.state.price),
            // "borrowLimit": Number(this.state.borrowLimit),
            "interestPerHrs" : Number(this.state.interestPerHrs)
        }
        console.log("add coin pair data  ", data)
        await axios.post(API_URL.ADD_MARGIN_COIN_PAIR, data)
        .then(res => {
            console.log("add coin pair response:", res.data);
            if(res.data.statusCode == 200){
                toast.success(res.data.message)
                this.getAllCoinPairs();
            }else{
                toast.error(res.data.message)
            }
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    } else{
            toast.warning("Primary & Secondary Coins are same")
    }
    }

    updateCoinPairs = async()=>{
        let data = {
            "primaryCoin" :  this.state.primaryCoin,
            "secondaryCoin" : this.state.secondaryCoin,
            "price" : Number(this.state.editPrice),
            "id" : this.state.editCoinId,
            "interestPerHrs": this.state.interestPerHrs
        }
        console.log(data)
        axios.post(API_URL.UPDATE_MARGIN_COIN_PAIR, data)
        .then(res => {
            console.log("update coin pair response:", res.data);
            if(res.data.statusCode == 200){
                toast.success(res.data.message)
                this.getAllCoinPairs();
                this.setState({
                    addUI : true,
                    updateUI : false
                })
            }else{
                toast.error(res.data.message)
            }
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    }
    editCoin = async(row)=>{
        console.log(row)
        this.setState({
            addUI : false,
            updateUI : true,
            editPrice : row.price,
            primaryCoin : row.primaryCoin,
            secondaryCoin : row.secondaryCoin,
            editCoinId : row._id,
            interestPerHrs : row.interestPerHrs
        })
    }

    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }
    statusFormatter(cell, row, enumObject, rowIndex){
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div>{row.isDeleted ? 'Inactive': 'Active'}</div>
            </div>
        )
    }
    // coinpair(cell, row, enumObject, rowIndex) {
    //     console.log("row value: ", row);
    //     return (
    //         <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
    //             <div class="row">
    //                 <div class="col-md-12">
    //                     <a href="#" 
    //                         // onClick={() => {
    //                         //     console.log("Cell value: ", row)
    //                         //     this.deleteUser(row)
    //                         // }}
    //                         >
    //                         {row.coinName} / {row.pair}
    //                     </a>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        // if(row.status === 1){
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                <div class="row">
                    <div class="col-md-12">
                    <a className="btn btn-block btn-primary btn-xs"
                            onClick={() => {
                                // localStorage.setItem('addMarginCoinPairsData', JSON.stringify(row))
                                // console.log("To worriers",row)
                                this.editCoin(row)
                            }}>
                            <i className="fa fa-edit " />
                    </a>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Header />
                <ToastContainer />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>

                        {this.state.addUI &&
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Add Margin Coins Pairs</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                            <div className="col-md-12" style={{ marginLeft: '10px', paddingTop: '10px' }}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <p style={{ color: "#777" }}>Primary Coins</p>
                                    </div>
                                    <h1></h1>
                                    <div className="col-md-3" >
                                        <p style={{ color: "#777" }}>Secondary Coin</p>
                                    </div>
                                    <div className="col-md-2" >
                                        <p style={{ color: "#777" }}>Interest per Hrs.</p>
                                    </div>
                                    <div className="col-md-2" >
                                        <p style={{ color: "#777" }}>Price</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginLeft: '8px' }}>
                                <div className="row">
                                    <div className="col-md-3">
                                        {/* <Input placeholder="coin" /> */}
                                        <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    primaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Primary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}

                                        </select>
                                    </div>

                                    
                                    <div className="col-md-3">
                                    <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    secondaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Secondary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                    <Input
                                                            type="text"
                                                            name="margin_limit"
                                                            id="margin_limit_"
                                                            style={input}
                                                            value={this.state.interestPerHrs}
                                                            placeholder="Interest per Hrs"
                                                            onChange={e => {
                                                                this.setState({
                                                                    interestPerHrs: e.target.value
                                                                })
                                                            }} />
                                    </div>
                                    <div className="col-md-2">
                                    <Input
                                                            type="text"
                                                            name="margin_limit"
                                                            id="margin_limit_"
                                                            style={input}
                                                            value={this.state.price}
                                                            placeholder="Price"
                                                            onChange={e => {
                                                                this.setState({
                                                                    price: e.target.value
                                                                })
                                                            }} />
                                    </div>


                                    <Button color="primary" onClick={this.addCoinPairs}>Add</Button>
                                </div>
                            </div>
                        </div>
                        }

{this.state.updateUI &&
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Update Margin Coins Pairs</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                            <div className="col-md-12" style={{ marginLeft: '10px', paddingTop: '10px' }}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <p style={{ color: "#777" }}>Primary Coins</p>
                                    </div>
                                    <h1></h1>
                                    <div className="col-md-3" >
                                        <p style={{ color: "#777" }}>Secondary Coin</p>
                                    </div>
                                    <div className="col-md-2" >
                                        <p style={{ color: "#777" }}>Interest per Hrs.</p>
                                    </div>
                                    <div className="col-md-2" >
                                        <p style={{ color: "#777" }}>Price</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginLeft: '8px' }}>
                                <div className="row">
                                     
                                <div className="col-md-3">
                                    <Input
                                                            type="text"
                                                            name="margin_limit"
                                                            id="margin_limit_"
                                                            style={input}
                                                            value={this.state.primaryCoin}
                                                            placeholder="Primary"
                                                            readOnly={true} />
                                    </div>

                                    <div className="col-md-3">
                                    <Input
                                                            type="text"
                                                            name="margin_limit"
                                                            id="margin_limit_"
                                                            style={input}
                                                            value={this.state.secondaryCoin}
                                                            placeholder="Secondary"
                                                            readOnly={true} />
                                    </div>
                                    <div className="col-md-2">
                                    <Input
                                                            type="text"
                                                            name="margin_limit"
                                                            id="margin_limit_"
                                                            style={input}
                                                            value={this.state.interestPerHrs || 0}
                                                            placeholder="Interest per Hrs"
                                                            onChange={e => {
                                                                this.setState({
                                                                    interestPerHrs: e.target.value
                                                                })
                                                            }} />

                                    </div>
                                   
                                    <div className="col-md-2">
                                    <Input
                                                            type="text"
                                                            name="margin_limit"
                                                            id="margin_limit_"
                                                            style={input}
                                                            value={this.state.editPrice}
                                                            placeholder="Price"
                                                            onChange={e => {
                                                                this.setState({
                                                                    editPrice: e.target.value
                                                                })
                                                            }} />

                                    </div>

                                    <Button color="primary" onClick={this.updateCoinPairs}>Update</Button>
                                </div>
                            </div>
                        </div>
                        }

                        
                <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                {/* <CardHeader>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <i className="fa fa-align-justify" style={{
                                                                marginRight: 10
                                                            }}></i>
                                                    </div>
                                                        <div className="col-md-6">
                                                            <Button color="primary" style={{ float: 'right' }}
                                                                onClick={(e) => {
                                                                    this.props.history.push('/addRole')
                                                                }}>All Coins Pairs</Button>
                                                        </div>
                                                    </div>
                                                </CardHeader> */}
                                                <CardBody>

                                                <BootstrapTable data={this.state.allCoinPairsList} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn dataField="any" dataFormat={this.indexN} thStyle={headerStyle} width={'10%'}>No.</TableHeaderColumn>
                                                        <TableHeaderColumn 
                                                            dataField='primaryCoin' 
                                                            width={'50%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Primary Coin</TableHeaderColumn>
                                                             <TableHeaderColumn 
                                                            dataField='secondaryCoin' 
                                                            width={'50%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Secondary Coin</TableHeaderColumn>
                                                             <TableHeaderColumn 
                                                            dataField='price' 
                                                            width={'50%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Price</TableHeaderColumn>
                                                                    <TableHeaderColumn 
                                                            dataField='interestPerHrs' 
                                                            width={'50%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Interest per Hrs</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='isDeleted'  dataFormat={this.statusFormatter.bind(this)} thStyle={headerStyle} isKey >STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable>

                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}
