import React, { useEffect, useState } from 'react'
import { API_URL, imageURL } from '../../config/api';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row, Button, Spinner, Modal, ModalBody,ModalHeader, ModalFooter } from 'reactstrap';
import {axiosInstance} from './../../utils/interceptor';
import { toast, ToastContainer } from 'react-toastify';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FaBullseye } from 'react-icons/fa';

const axios = axiosInstance;
const input = {
    fontSize: '13px',
    color: '#333',
    width: 300
}
const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

function P2pOrders() {
    const [orderList, setOrderList] = useState()
    const [completedOrderList, setCompletedOrderList] = useState()
    const [disputedOrderList, setDisputedOrderList] = useState()
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState()
const [statusEnum, setStatusEnum] = useState()
const [statusEnumText, setStatusEnumText] = useState()
const [disStatus, setDisStatus] = useState('')

    
useEffect(() => {
//   if(coinName)
    getData()
}, [])

const getData = () => {
    axios.get(`${API_URL.P2P_ORDERS_STATUS_ENUM}`).then((res) => {
        if(res.data.message === 'Success'){
            setStatusEnum(res.data.data)
            setStatusEnumText(res.data.enumToText)
         axios.get(`${API_URL.P2P_ORDERS}`).then((res) => {
            setOrderList(res.data.data || [])
        setLoading(false)
         }).catch(e =>{setLoading(false)});
         getCompletedOrders()
         getDisputedOrders()
       }
    }).catch(e =>{setLoading(false)});
}
const getCompletedOrders = ()=>{
    axios.get(`${API_URL.P2P_COMPLETED_ORDERS}`).then((res) => {
        setCompletedOrderList(res.data.data || [])
    setLoading(false)
     }).catch(e =>{setLoading(false)});
}
const getDisputedOrders = ()=>{
    axios.get(`${API_URL.P2P_DISPUTED_ORDERS}`).then((res) => {
        setDisputedOrderList(res.data.data || [])
    setLoading(false)
     }).catch(e =>{setLoading(false)});
}
  const togglePopup=()=>{
    setModal(!modal);      
} 
const updateStatus =()=>{
    if(disStatus){
        setLoading(true)
        axios.post(`${API_URL.UPDATE_DISPUTED_ORDER}`,{orderId: selectedRow._id, status: disStatus}).then((res) => {
            setModal(false)
            getDisputedOrders()
        setLoading(false)
         }).catch(e =>{setLoading(false)});
    }
}
const onEditClick = (row)=>{
    if(row.status !== 'disputed'){
        setSelectedRow(row);
         togglePopup()
    }
}
    const updateButton = (cell, row, enumObject, rowIndex)=> {
        console.log("row value: ", row);
        return (
            <div>
                <a href={imageURL+row.receipt} target='_blank' className="btn btn-primary btn-sm mr-3"> <i className='fa fa-download'></i> </a>
            {row.status !== "disputed" &&
                <button className="btn btn-info btn-sm" onClick={()=> onEditClick(row)}> Update </button>
            }
            </div>
        )
    }
    const dateFormatter = (cell, row, enumObject, rowIndex)=> {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        {new Date(row.createdOn).toLocaleString()} 
                    </div>
                </div>
            </div>
        )
    }
    const numberRoundOff = (cell, row, enumObject, rowIndex)=> {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        {(+(cell || 0)).toFixed(2)}
                        {}
                    </div>
                </div>
            </div>
        )
    }
  return (
    <div>
            <div>
    <Header />
    <Menu />
    <ToastContainer />
    <div className="wrapper">
        <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">P2P Orders</h1>
                        </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>
            </div>
            <section className="content relative">
                      {loading && <div className='spinner-div'>
                          <Spinner
                              color="warning"
                              type="grow"
                          >
                              Loading...
                          </Spinner>
                      </div>}
                <div className="container-fluid">
                    <div className="col-md-12">
                        <Row>
                            <Col >
                                <Card>
                                    <CardHeader>
                                        <div className="row">
                                           
                                            {/* <div className="col-md-12">
                                                <div className='d-flex justify-content-end'>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            style={input}
                                                            onChange={e => {
                                                               alert(e.target.value+'h11')
                                                            }}>
                                                            {tokenList.map((d) => (
                                                                d.active == true && <>
                                                                    <option selected={d.tokenSymbol === coinName} value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                        </div>
                                            </div> */}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                    <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>All Orders</Tab>
                                                        <Tab>Completed Orders</Tab>
                                                        <Tab>Disputed Orders</Tab>
                                                    </TabList>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                    <div style={{overflow:'auto'}}>
                                                    <BootstrapTable data={orderList} pagination multiColumnSearch
                                                        tableStyle={{ fontSize: 12, textAlign: 'center', minWidth: 1200 }}>
                                                        <TableHeaderColumn  dataField='adPostedBy' dataFormat={(cell, row)=>(
                                                        <div>
                                                            <div>{row.adPostedBy}</div>
                                                            <div>(<small>{row.emailId}</small>)</div>
                                                        </div>
                                                        )} thStyle={headerStyle}>User Name</TableHeaderColumn>
                                                        <TableHeaderColumn  dataField='assetCoin' thStyle={headerStyle}>Asset</TableHeaderColumn>
                                                        <TableHeaderColumn  dataField='fiatCoin' width="8%" thStyle={headerStyle}>Fiat</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='price' dataFormat={numberRoundOff.bind(this)} thStyle={headerStyle}>Price</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width="8%" dataFormat={(cell,row)=> (<>{cell} <small>{row.assetCoin}</small></>)} thStyle={headerStyle}>Amount</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='total' width="8%" dataFormat={(cell,row)=> (<>{row.amount * row.price}</> )} thStyle={headerStyle}>Total</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='type'  thStyle={headerStyle} width="8%">Type</TableHeaderColumn>
                                                        <TableHeaderColumn isKey dataField='createdOn'dataFormat={dateFormatter.bind(this)} thStyle={headerStyle}>DATE </TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status' dataFormat={(cell, row)=> (<>{statusEnumText[cell]}</>)} thStyle={headerStyle}>Status</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='Action'dataFormat={(cell, row)=>  <a href={imageURL+row.receipt} target='_blank' className="btn btn-primary btn-sm mr-3"> <i className='fa fa-download'></i> </a>} thStyle={headerStyle}>Action </TableHeaderColumn>
                                                    </BootstrapTable>
                                                    </div>
                                                        </TabPanel>
                                                        <TabPanel style={{ padding: '20px' }}>
                                                 <div style={{overflow:'auto'}}>
                                                 <BootstrapTable data={completedOrderList} pagination multiColumnSearch
                                                        tableStyle={{ fontSize: 12, textAlign: 'center',minWidth: 1200 }}>
                                                        <TableHeaderColumn  dataField='adPostedBy' dataFormat={(cell, row)=>(
                                                        <div>
                                                            <div>{row.adPostedBy}</div>
                                                            <div>(<small>{row.emailId}</small>)</div>
                                                        </div>
                                                        )}
                                                         thStyle={headerStyle}>User Name</TableHeaderColumn>
                                                        <TableHeaderColumn  dataField='assetCoin' thStyle={headerStyle}>Asset</TableHeaderColumn>
                                                        <TableHeaderColumn  dataField='fiatCoin' width="8%" thStyle={headerStyle}>Fiat</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='price' dataFormat={numberRoundOff.bind(this)} thStyle={headerStyle}>Price</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width="8%" dataFormat={(cell,row)=> (<>{cell} <small>{row.assetCoin}</small></>)} thStyle={headerStyle}>Amount</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='total' width="8%" dataFormat={(cell,row)=> (<>{row.amount * row.price}</> )} thStyle={headerStyle}>Total</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='type'  thStyle={headerStyle} width="8%">Type</TableHeaderColumn>
                                                        <TableHeaderColumn isKey dataField='createdOn'dataFormat={dateFormatter.bind(this)} thStyle={headerStyle}>DATE </TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status' dataFormat={(cell, row)=> (<>{statusEnumText[cell]}</>)} thStyle={headerStyle}>Status</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='Action'dataFormat={(cell, row)=>  <a href={imageURL+row.receipt} target='_blank' className="btn btn-primary btn-sm mr-3"> <i className='fa fa-download'></i> </a>} thStyle={headerStyle}>Action </TableHeaderColumn>
                                                    </BootstrapTable>
                                                 </div>
                                                        </TabPanel>
                                                        <TabPanel style={{ padding: '20px' }}>
                                                    <div style={{overflow:'auto'}}>
                                                    <BootstrapTable data={disputedOrderList} pagination multiColumnSearch
                                                        tableStyle={{ fontSize: 12, textAlign: 'center',minWidth: 1200 }}>
                                                        <TableHeaderColumn  dataField='adPostedBy' dataFormat={(cell, row)=>(
                                                        <div>
                                                            <div>{row.adPostedBy}</div>
                                                            <div>(<small>{row.emailId}</small>)</div>
                                                        </div>
                                                        )}
                                                        thStyle={headerStyle}>User Name</TableHeaderColumn>
                                                        <TableHeaderColumn  dataField='assetCoin' thStyle={headerStyle}>Asset</TableHeaderColumn>
                                                        <TableHeaderColumn  dataField='fiatCoin' width="8%" thStyle={headerStyle}>Fiat</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='price' dataFormat={numberRoundOff.bind(this)} thStyle={headerStyle}>Price</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width="8%" dataFormat={(cell,row)=> (<>{cell} <small>{row.assetCoin}</small></>)} thStyle={headerStyle}>Amount</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='total' width="8%" dataFormat={(cell,row)=> (<>{row.amount * row.price}</> )} thStyle={headerStyle}>Total</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='type'  thStyle={headerStyle} width="8%">Type</TableHeaderColumn>
                                                        <TableHeaderColumn isKey dataField='createdOn'dataFormat={dateFormatter.bind(this)} thStyle={headerStyle}>DATE </TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status' dataFormat={(cell, row)=> (<>{statusEnumText[cell]}</>)} thStyle={headerStyle}>Status</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='Action'dataFormat={updateButton.bind(this)} thStyle={headerStyle}>Action </TableHeaderColumn>
                                                    </BootstrapTable>
                                                    </div>
                                                        </TabPanel>
                                                        </Tabs>
                                    
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                </div>
                    <Modal isOpen={modal} toggle={togglePopup} >
                        <ModalHeader toggle={togglePopup}>Update Disputed Order</ModalHeader>
                        <ModalBody>
                            <div>
                            
                                <div className="form-group">
                                    <label htmlFor="amountId">Status</label>
                                    <select name="status" id="" onChange={(e)=> setDisStatus(e.target.value)}>
                                        <option value="">Select Status</option>
                                        <option value="approved">Approved</option>
                                        <option value="rejected">Rejected</option>
                                        {/* <option value="Canceled">Canceled</option> */}
                                    </select>
                                    {/* <input type="number" required value={amount} onChange={(e)=> setAmount(e.target.value)} className="form-control" id="amountId" placeholder="Enter Amount" /> */}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" disabled={!disStatus} onClick={updateStatus}>
                                Update
                            </Button>{' '}
                            <Button color="secondary" onClick={togglePopup}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
            </section>
        </div>
    </div>
    {/* <Footer /> */}
</div>
    </div>
  )
}

export default P2pOrders