import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL } from "../../../config/api";
import swal from 'sweetalert';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {axiosInstance} from './../../../utils/interceptor';
const axios = axiosInstance;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class AddBlogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            SEOtitle: '',
            URL: '',
            SEOdescription: '',
            status: '',
            type : '',
            story: '',
            imageurl : ''
        }
    }

    saveNews = async () => {
        let sendKey = {
            "title": this.state.title,
            "seoTitle": this.state.SEOtitle,
            "url": this.state.URL,
            "seoDescription": this.state.SEOdescription,
            "story": this.state.story,
            "status": this.state.status,
            "type" : this.state.type,
            "imageurl": this.state.imageurl
        }
        console.log(sendKey)
        axios.post(API_URL.ADD_BLOGS_NEWS, sendKey)
            .then(res => {
                console.log("response:", res);
                if (res.data.statusCode === 200) {
                    swal("Added Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    resetStates = () => {
        this.setState({
            title: '',
            SEOtitle: '',
            URL: '',
            SEOdescription: '',
            status: '',
            type : '',
            story: '',
            imageurl : ''
        })
    }

    fileUpload = async (e) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        console.log("image: ", e.target.files[0].name)
        var uploadfile = e.target.files[0]
        const data = new FormData()
        data.append('file', uploadfile)
        data.append('name', this.state.tokenName)
        const formDataFront = new FormData();
        formDataFront.append('file', e.target.files[0]);
        axios.post(API_URL.FILE_UPLOAD, formDataFront, config).then(res => { // then print response status
                    console.log("File upload: ", res.data.data.path)
                    let filepath = '';
                    filepath = res.data.data.path
                    filepath = filepath.replace('../kycImages/',"/");
                    console.log("File upload: ", filepath)
                    this.setState({
                        imageurl : filepath
                    })
            })
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Add Blog & News</h1>
                                    </div>{/* /.col */}
                                    <div className="col-sm-6">
                                        <Button color="primary" style={{float: 'right'}}
                                            onClick={() => {
                                                this.props.history.push('/blogs')
                                            }}>Back</Button>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody style={{ marginBottom: '20px' }}>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Title</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.title}
                                                            placeholder="Enter title"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    title: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>URL</Label>
                                                        <Input
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.URL}
                                                            placeholder="Enter URL"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    URL: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>SEO Title</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.SEOtitle}
                                                            placeholder="Enter SEO title"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    SEOtitle: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>SEO Description</Label>
                                                        <Input
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.SEOdescription}
                                                            placeholder="Enter SEO description"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    SEOdescription: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-12">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Story</Label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            placeholder="Hello"
                                                            // data="<p>Hello from CKEditor 5!</p>"
                                                            value={this.state.story}
                                                            onInit={editor => {
                                                                console.log('Editor is ready to use!', editor);
                                                            }}

                                                            config={{
                                                                ckfinder: { uploadUrl: 'http://3.7.112.1:5000/uploads' }
                                                            }}

                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                console.log("Data: ", data);
                                                                this.setState({
                                                                    story: data
                                                                })
                                                                // console.log("Change", {event, editor, data });
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                console.log('Blur.', editor);
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                console.log('Focus.', editor);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Status</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            style={input}
                                                            value={this.state.status}
                                                            onChange={e => {
                                                                console.log(e.target.value)
                                                                this.setState({
                                                                    status: e.target.value,
                                                                })
                                                            }}>
                                                            <option value="" >Select Status</option>    
                                                            <option value="Active" >Active</option>
                                                            <option value="Inactive" selected>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Type</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            style={input}
                                                            value={this.state.type}
                                                            onChange={e => {
                                                                console.log(e.target.value)
                                                                this.setState({
                                                                    type: e.target.value,
                                                                })
                                                            }}>
                                                                <option value="">Select Type</option>
                                                            <option value="Blog" >Blog</option>
                                                            <option value="News" selected>News</option>
                                                        </select>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="col-md-6">
                                                    <FormGroup>
                                                            <Label for="exampleFile" style={label}>File</Label>
                                                            <Input type="file" name="file" id="exampleFile" 
                                                                 onChange={(e) => {
                                                                    this.fileUpload(e)
                                                                    //    console.log(window.location.href+'image.png');
                                                                }}/>
                                                        </FormGroup>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <Button
                                                        onClick={this.saveNews}
                                                        style={{
                                                            marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                            marginLeft: '15px'
                                                        }}>Save</Button>
                                                    <Button color="secondary"
                                                        onClick={this.resetStates}>Reset</Button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
