import React, { useState,useEffect } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useLocation } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { API_URL } from "../../config/api";
import { axiosInstance } from "../../utils/interceptor";

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }
  const tabEnum={
    "0": "All",
    "1": "close",
    "2": "open",
    "3": "cancelled"
  }
function MarginOrderList() {
  const [status, setStatus] = useState("All");
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const userId = queryParams.get("userId");
  useEffect(() => {
    if (status) {
      getOrderList();
    }
  }, [status]);

  const getOrderList = () => {
    setLoading(true)
    axiosInstance
      .get(API_URL.GET_MARGIN_ORDERS + "?status=" + status)
      .then((res) => {
        setOrderList(res.data.data || []);
        setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        console.log("Error: ", error);
      });
  };
  return (
    <div>
      <Header />
      <Menu />
      <ToastContainer />
      <div className="wrapper">
        <div
          className="content-wrapper"
          style={{ marginTop: "0px", backgroundColor: "#E5E9EC" }}
        >
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Margin Orders</h1>
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
          </div>
          <section className="content relative">
            <div className="container-fluid">
              <div className="col-md-12">
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <div className="row"></div>
                      </CardHeader>
                      <CardBody>
                        <Tabs defaultIndex={0} onSelect={(index) => setStatus(tabEnum[index])}>
                          <TabList
                            style={{
                              backgroundColor: "#D1DADE",
                              borderBottom: 0,
                              color: "#777",
                              height: "38px",
                            }}
                          >
                            <Tab>All</Tab>
                            <Tab>Filled Orders</Tab>
                            <Tab>Open Orders</Tab>
                            <Tab>Cancelled Orders</Tab>
                          </TabList>
                          <TabPanel style={{ padding: "16px" }}>
                                 <TableData loading={loading} orderList={orderList}/>
                          </TabPanel>
                          <TabPanel style={{ padding: "16px" }}>
                                 <TableData loading={loading} orderList={orderList}/>
                          </TabPanel>
                          <TabPanel style={{ padding: "16px" }}>
                                 <TableData loading={loading} orderList={orderList}/>
                          </TabPanel>
                          <TabPanel style={{ padding: "16px" }}>
                                 <TableData loading={loading} orderList={orderList}/>
                          </TabPanel>
                        </Tabs>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

const TableData = ({loading, orderList}) => (
  <div>
    {loading && (
      <div className="spinner-div">
        <Spinner color="warning" type="grow">
          Loading...
        </Spinner>
      </div>
    )}
    <div style={{ overflow: "auto" }}>
      <BootstrapTable
        data={orderList}
        pagination
        search
        tableStyle={{
          fontSize: 12,
          textAlign: "center",
          minWidth: 1200,
        }}
      >
        <TableHeaderColumn
          dataField="coinName"
          thStyle={headerStyle}
          width={"10%"}
        >
          Coin
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="entryPrice"
          width={"8%"}
          thStyle={headerStyle}
        >
          Price($)
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="amount"
          width={"8%"}
          thStyle={headerStyle}
        >
          Amount
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="orderType"
          thStyle={headerStyle}
          width={"8%"}
          // tdStyle={ { whiteSpace: 'normal' } }
        >
          Order Type
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="button"
          width={"15%"}
          dataFormat={(cell,row) => (
            <>
              {(row.tradingFee *
                (row.status !== "close" ? row.entryPrice : row.exitPrice) *
                row.amount) /
                100}{" "}
              USDT
            </>
          )}
          thStyle={headerStyle}
        >
          Fees
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="button"
          dataFormat={(cell,row) => <>{new Date(row.createdAt).toDateString()}</>}
          width={"15%"}
          thStyle={headerStyle}
          isKey
        >
          Date
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="button"
          width={"15%"}
          dataFormat={(cell,row) => <>{row.status.toUpperCase()}</>}
          thStyle={headerStyle}
        >
          Status
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  </div>
);

export default MarginOrderList;
