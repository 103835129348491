import React, { Component } from 'react';
import { AiOutlineLogout } from 'react-icons/ai'
import { FaSlidersH } from 'react-icons/fa'
import { Link } from 'react-router-dom';
// import './Header.scss';

export default class Header extends Component {
    render() {
        return (
            <div>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                        {/* <li className="nav-item d-none d-sm-inline-block">
                            <a href="index3.html" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="#" className="nav-link">Contact</a>
                        </li> */}
                    </ul>
                    {/* SEARCH FORM */}
                    {/* <form className="form-inline ml-3">
                        <div className="input-group input-group-sm">
                            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-navbar" type="submit">
                                    <i className="fas fa-search" />
                                </button>
                            </div>
                        </div>
                    </form> */}
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        <div className="pull-right row" style={{float: 'right'}}>
                            <div className="chat-toggler sm" style={{marginRight: '10px'}}>
                                <div className="profile-pic">
                                    <img src="images/av.png" alt 
                                        data-src="images/av.png" 
                                        data-src-retina="images/av.png" 
                                        width={35} height={35} 
                                        style={{borderRadius: 20}}/>
                                    <div className="availability-bubble online" />
                                </div>
                            </div>
                        </div>

                        {/* Messages Dropdown Menu */}
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#">
                                {/* <i className="far fa-bell" width={40} height={40}/> */}
                                <FaSlidersH width={80} height='2em' style={{width: '30px', height: '2em'}}/>
                                {/* <span className="badge badge-warning navbar-badge">15</span> */}
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" >
                                {/* <span className="dropdown-item dropdown-header">15 Notifications</span> */}
                                <div className="dropdown-divider" />
                                <Link to="/" className="dropdown-item"  onClick={() =>{
                                    localStorage.setItem("adminLogin", 0)
                                }}>
                                <AiOutlineLogout /> Logout
                                </Link>
                                {/* <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a> */}
                            </div>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button"><i className="fas fa-th-large" /></a>
                        </li>  */}
                    </ul>
                </nav>
            </div>

        );
    }
}
